import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LinearProgress from '@mui/material/LinearProgress';
import { PostData } from '../../services/PostData';
import moment from 'moment';

const Empleados = () => {
    const numeral = "#";
    let DatosListaUsuario = []
    const [IdUsuario, setIdUsuario] = useState(null);
    const [TipoEmpleado, setTipoEmpleado] = useState([]);
    const [ListaEmpleados, setListaEmleados] = useState([]);
    
    const [cedula, setCedula] = useState("");
    const [nombres, setNombres] = useState("");
    const [telefono, setTelefono] = useState("");
    const [direccion, setDireccion] = useState("");
    const [correo, setCorreo] = useState("");
    const [contacto, setContacto] = useState("");
    const [contacto_tel, setContacto_tel] = useState("");
    const [licencia, setLicencia] = useState("");
    const [fechalicencia, setFechaLicencia] = useState("");
    const [tipo, setTipo] = useState("");
    const [LoadingTbl, setLoadingTbl] = useState(false);  
    const [Loading, setLoading] = useState(false);  
    const MySwal = withReactContent(Swal)

    const dias_faltantes = (fecha) => {
      if (fecha === "0000-00-00")
          return "";

      let now = moment();
      var fechav = moment(fecha);
      var diasfaltantes = now.diff(fechav, 'days');
      
      if (diasfaltantes < 0)
          return <span className="badge text-bg-success">{diasfaltantes}</span>
      else
          return <span className="badge text-bg-danger">{diasfaltantes}</span>

}

const formato_fecha = (fecha) => {
    
    if (fecha === "0000-00-00")
        return ""
    return moment(fecha).format("DD-MMM-YYYY");
}
  
  const cargar_tipos = () => {

    GetData('empleados/tiposempleados').then ((result) =>{
      let responseJSON = result;
      

      if (responseJSON.error === false){
          const listatipos = responseJSON.tipos;
      
          let listaselect = listatipos.map(item => <option value={item.id_tipo_empleado}>{item.nombre_tipo_emp}</option>)
          setTipoEmpleado(listaselect)
      }else{
    
    }
  })
  }
  
  const listado_usuarios = async () => {
        setLoadingTbl(true);

        const filtro = "null";

        GetData('empleados/listar/'+filtro).then ((result) =>{
              
              if (result.error === false){
                const listausuarios = result.empleados;
                DatosListaUsuario = listausuarios;
                let tablausuarios = listausuarios.map((item, index) => <tr key={index} onClick={() => llenarDatos(index)}>
                  <td className="py-1">
                      {item.cedula}
                  </td>
                  <td className="py-1">
                      {item.nombre_empleado}
                  </td>
                  <td  className="py-1">
                      {item.telefono}
                  </td>
                  <td  className="py-1">
                      {item.direccion}
                  </td>
                  <td  className="py-1">
                      {item.nombre_tipo_emp}
                  </td>
                  <td  className="py-1">
                      {formato_fecha(item.fecha_vec_licencia)} {dias_faltantes(item.fecha_vec_licencia)}
                  </td>
                  <td>{item.fecha_creado}</td>
                  <td>
              
                      <a href={numeral} onClick={() => delete_usuario(item.id_empleado, item.nombre_empleado)}><i className="mdi mdi-delete-forever" aria-hidden="true"></i></a>
                  </td>
                </tr>)
                setListaEmleados(tablausuarios);
                setLoadingTbl(false);
            }

        });
       
  }

  const llenarDatos = (index) => {
         const datousuario =  DatosListaUsuario[index];
         setIdUsuario(datousuario.id_empleado);
         setCedula(datousuario.cedula)
         setNombres(datousuario.nombre_empleado);
         setTelefono(datousuario.telefono)
         setDireccion(datousuario.direccion)
         setCorreo(datousuario.correo_e)
         setContacto(datousuario.contacto_emergencia)
         setContacto_tel(datousuario.telefono_emergencia)
         setLicencia(datousuario.id_licencia)
         setFechaLicencia(datousuario.fecha_vec_licencia)
         setTipo(datousuario.id_tipo_empleado) 
  }

  const limpiar = () => {
        setIdUsuario(null);
        setCedula("")
        setContacto("")
        setDireccion("")
        setFechaLicencia("")
        setLicencia("")
        setTelefono("")
        setNombres("");
        setCorreo("");
        setContacto_tel("");
        setTipo("");
  }
  const guardarUsuario = () => {
          let guardar = false
          
          if (tipo === 1){
              if (cedula && nombres && telefono && direccion  && tipo && licencia && fechalicencia && contacto )
                  guardar = true;
          }else{
              if (cedula && nombres && telefono && direccion  && tipo && contacto)
                  guardar = true;
          }
          
          if (guardar === false){
              MySwal.fire({
                title: 'Guardar Persona',
                text: "Debe llenar todos los campos en el formulario",
                icon: 'error'
                })
                return 0;
          }

          let data = {
            id_empleado : IdUsuario,
            cedula,
            nombres,
            telefono,
            direccion,
            correo,
            contacto,
            contacto_tel,
            tipo
          }

          if (tipo === "1"){ //si es un conductos lleva estos datos adicionales
              data = {...data,  licencia, fechalicencia }
          }

          setLoading(true)       

        PostData('/empleados/guardar', data).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){                
                setLoading(false) 
                MySwal.fire({
                  title: 'Guardar Persona',
                  text: responseJSON.mensaje,
                  icon: 'success'
                  }
                )
                listado_usuarios();
                limpiar();
            }else{
             // setError(true);
              setLoading(false) 
              MySwal.fire({
                title: 'Guardar Persona',
                text: responseJSON.mensaje,
                icon: 'error'
                });
          }
        })
        
  }

  const borrar_usuario = (id_usuario) => {
    setLoadingTbl(true);

   
    GetData('empleados/delete/'+id_usuario).then ((result) =>{
          if (result.error === false){
              listado_usuarios();
          }
    });
  }

  const delete_usuario = (id_usuario, nombre) => {
    MySwal.fire({
      title: 'Borrar usuario ',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
      text: "Esta segúro de borrar el usuario: " + nombre,
      }).then((result) => {
        if (result.isConfirmed) {
             borrar_usuario(id_usuario);
        }
      })

  }

 
  useEffect(() => {
      cargar_tipos();
      listado_usuarios();
  }, [])
  

  return (
    <>
     { Loading && <div className="loading">Loading&#8230;</div> }
    <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">{IdUsuario === null ? 'CREAR EMPLEADO' : 'ACTUALIZAR EMPLEADO'}</h4>
                    <form className="forms-sample" autocomplete="off">
                    <div className='form-row'>
                      <div className="form-group  col-md-6">
                        <label for="exampleInputEmail1">Tipo de empleado</label>
                        {/* <Select options={TipoUsuario} id="id_tipo_usuario" onChange={event => setTipo(event.target.value)}  /> */}
                         <select name="tipo" 
                        onChange={event => setTipo(event.target.value)} 
                        value={tipo}
                        className="form-control form-control-sm" id="exampleFormControlSelect3">
                          <option>Seleccione</option>
                          {TipoEmpleado}
                        </select> 
                      </div>

                      <div className="form-group col-md-6">
                        <label for="cedula">Cédula</label>
                        <input name="cedula"
                        onChange={event => setCedula(event.target.value)} 
                        value={cedula}
                        autocomplete="off"
                        type="text" className="form-control form-control-sm" id="cedula" placeholder="Cédula" required />
                      </div>
                      <div className="form-group col-md-6">
                        <label for="nombre_apellidos">Nombres y Apellidos</label>
                        <input name="nombre_apellidos"
                        onChange={event => setNombres(event.target.value)} 
                        value={nombres}
                        autocomplete="off"
                        type="text" className="form-control form-control-sm" id="nombre_apellidos" placeholder="Escribe el nombre del usuarios" required />
                      </div>
                      <div className="form-group col-md-6">
                        <label for="telefono">Teléfono</label>
                        <input name="telefono"
                        onChange={event => setTelefono(event.target.value)} 
                        value={telefono}
                        autocomplete="off"
                        type="text" className="form-control form-control-sm" id="telefono" placeholder="Teléfono" required />
                      </div>
                      <div className="form-group col-md-6">
                        <label for="direccion">Dirección</label>
                        <input name="direccion"
                        onChange={event => setDireccion(event.target.value)} 
                        value={direccion}
                        autocomplete="off"
                        type="text" className="form-control form-control-sm" id="direccion" placeholder="Dirección" required />
                      </div>
                      <div className="form-group  col-md-6">
                        <label for="exampleInputEmail1">Correo Electrónico</label>
                        <input name="email_empleado"
                        onChange={event => setCorreo(event.target.value)} 
                        value={correo}
                        autocomplete="off"
                         type="email" className="form-control form-control-sm" id="email_empleado" placeholder="Correo electrónico" required />
                      </div>
                      <div className="form-group  col-md-5">
                        <label for="contacto_empleado">Cotacto de Emergencia</label>
                        <input name="contacto_empleado"
                        onChange={event => setContacto(event.target.value)} 
                        value={contacto}
                        autocomplete="off"
                         type="text" className="form-control form-control-sm" id="contacto_empleado" placeholder="Contácto de emergencia" required />
                      </div>
                      <div className="form-group  col-md-4">
                        <label for="tele_emergencia">Teléfono de Emergencia</label>
                        <input name="tele_emergencia"
                        onChange={event => setContacto_tel(event.target.value)} 
                        value={contacto_tel}
                        autocomplete="off"
                         type="text" className="form-control form-control-sm" id="tele_emergencia" placeholder="Telefono Contácto" required />
                      </div>
                      { tipo === "1" ? <>
                      <div className="form-group  col-md-4">
                        <label for="exampleInputEmail1">ID Licencia</label>
                        <input name="licencia"
                        onChange={event => setLicencia(event.target.value)} 
                        value={licencia}
                        autocomplete="off"
                         type="text" className="form-control form-control-sm" id="licencia" placeholder="ID Licencia" required />
                      </div>
                      <div className="form-group  col-md-3">
                        <label for="exampleInputEmail1">Fecha Vencimiento Licencia</label>
                        <input name="fvlicencia"
                        onChange={event => setFechaLicencia(event.target.value)} 
                        value={fechalicencia}
                        autocomplete="off"
                         type="date" className="form-control form-control-sm" id="fvlicencia" placeholder="Fecha Vencimiento Licencia" required />
                      </div>
                      </>
                      : <></>
                      }
                      </div>
                      <button type="button" className="btn btn-primary mr-2" 
                         disabled={cedula === "" || nombres === ""  || telefono === ""  || direccion === "" || tipo === "" ? "true" : ""}  
                      onClick={guardarUsuario}> Guardar </button>
                      <button className="btn btn-light" onClick={limpiar}>Nuevo</button>
                    </form>

                  </div>
                </div>
              </div>
      </div>
      <div className='row'>
           <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                      
                                       
                    <div className="table-responsive">
                      <table className="table100 table-hover">
                        <thead className='table100-head'>
                          <tr>
                            <th>Cédula</th>
                            <th>Nombres</th>
                            <th>Teléfono</th>
                            <th>Dirección</th>
                            <th>Tipo</th>
                            <th>Fecha Ven. Lic</th>
                            <th>Fecha Registro</th>
                            <th>Opciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ListaEmpleados}
                        </tbody>
                      </table>
                    </div>
                    <div className='mt-2'>
                    { LoadingTbl && <LinearProgress /> }
                    </div>
                  </div>
                </div>
              </div>
      </div>
    </>
  )
}

export default Empleados;