
import { LinearProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetData } from '../../services/GetData'

const RutasHoy = () => {

    const [ListadoDespachos, setListadoDespachos] = useState([])
    const [LoadingTbl, setLoadingTbl] = useState(false) 
    const [Loading, setLoading] = useState(false) 
    const navigate = useNavigate();

    const IrA = (id_despacho) => {
      navigate('/home/verdepachos/'+id_despacho)
    }

    const listado_despachos = async () => {
        setLoadingTbl(true);

        
        setLoading(true);
        await GetData('despachos/rutashoy').then ((result) =>{
              
              if (result.error === false){
                const lista = result.datos;
                
                
                if (!lista){                  
                  setLoadingTbl(false);
                  return 0;
                }

                let tabla = lista.map((item, index) => <tr key={index} onClick={()=>IrA(item.id_despacho)}>
                  <td className="py-1">
                      {item.id_despacho}
                  </td>
                  <td className="py-1">
                      {item.fecha_despacho}
                  </td>
                  <td  className="py-1">
                      {item.placa}
                  </td>
                  <td  className="py-1">
                      {item.cond}
                  </td>
                  <td  className="py-1">
                      {item.aux1}
                  </td>
                  <td  className="py-1">
                      {item.poblacion.toUpperCase()}
                  </td>
                  <td>{item.reex}</td>
                  <td  className="py-1">
                      {item.usu}
                  </td>
                  
                
                </tr>)
                setListadoDespachos(tabla);
                setLoadingTbl(false);
                setLoading(false)
            }

        });
       
  }

    useEffect(() => {
        listado_despachos();    
    }, [])


  return (
    <>
      { Loading && <div className="loading">Loading&#8230;</div> }
    <div className='row'>
           <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className='card-title'>Rutas Hoy</div>  
                  <div className="card-body">
                      
                                       
                    <div className="table-responsive">
                      <table className="table100 table-hover">
                        <thead className='table100-head'>
                          <tr>
                            <th>Nº Desp</th>
                            <th>Fecha</th>
                            <th>Vehículo</th>
                            <th>Conductor</th>
                            <th>Auxiliar 1</th>
                            <th>Población</th>
                            <th>Reexpedidor</th>                            
                            <th>Usuario</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          {ListadoDespachos}
                        </tbody>
                      </table>
                    </div>
                    <div className='mt-2'>
                    { LoadingTbl && <LinearProgress /> }
                    </div>
                  </div>
                </div>
              </div>
      </div>
    </>
  )
}

export default RutasHoy