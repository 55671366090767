import http from "../http-common";

class UploadFilesService {
  upload(file, tipoarchivo, idusuario, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("tipoarchivo", tipoarchivo);
    formData.append("idusuario", idusuario);
    console.log("idusuario", idusuario)
    return http.post("/upload.php", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return  //http.get("/files.php");
  }
}

export default new UploadFilesService();