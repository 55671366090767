import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LinearProgress from '@mui/material/LinearProgress';
import { PostData } from '../../services/PostData';
import { Chip, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ChipPermisosUsuarios from '../../components/ChipPermisosUsuarios';




const TiposUsuario = () => {
    const numeral = "#";
    let DatosListaEmpresas = []
    const [IdMenu, setIdMenu] = useState(null);
    const [ListaEmpresas, setListaEmpresas] = useState([]);
    const [ListaTiposUS, setListaTiposUS] = useState([]);
    const [ListaMenus, setListaMenus] = useState([]);
    
    const [NombreTipo, setNombreTipo] = useState("");    
    const [Menus, setMenus] = useState([1,2,3]);    
    const [LoadingTbl, setLoadingTbl] = useState(false);  
    const [Loading, setLoading] = useState(false);  
    const [personName, setPersonName] = useState([]);
    const MySwal = withReactContent(Swal)
    const theme = useTheme();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    function getStyles(name, personName, theme) {
      return {
        fontWeight:
          personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    }

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setMenus(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const delete_empresa = () => {

    }

  const listado_tipos = async () => {
        setLoadingTbl(true);

        const filtro = "null";

        GetData('usuario/tipousuario/').then ((result) =>{
              
              if (result.error === false){

                const lista = result.tipos;
                DatosListaEmpresas = lista;
                
                if (!lista){                  
                  setLoadingTbl(false);
                  return 0;
                }
                setListaTiposUS(lista);
                
            /*     const tiposUsuariosProcesados = lista.map((tipo) => {
                  // Dividir el acceso por comas y convertirlos a números
                  const accesoArray = tipo.acceso.split(',').map(Number);
                
                  // Crear un nuevo objeto con la estructura deseada
                  return {
                    ...tipo
                  };
                }); */

              //  console.log(tiposUsuariosProcesados)
                

              /*   let tabla = lista.map((item, index) => <tr key={index} onClick={() => llenarDatos(index)}>
                  <td className="py-1">
                      {item.nombre_tipo_us}
                  </td>
                  <td className="py-1">
                      {item.acceso.split(',').map((value) => {
                        const menu = ListaMenus.find((item) => parseInt(item.id_menu) === parseInt(value));
                        console.log(menu)
                        return (
                          <Chip key={menu?.id_menu} label={menu?.nombre} />
                        );
                      })}
                    </td>
                 
                  <td>
     
                      <a href={numeral} onClick={() => delete_empresa(item.id_tipo_usuario, item.nombre_tipo_us)}><i class="mdi mdi-delete-forever" aria-hidden="true"></i>Editar</a>
                  </td>
                </tr>) */
                
                setLoadingTbl(false);
            }

        });
       
  }

  const llenarDatos = (index) => {
    const dato = ListaTiposUS[index];
  
    setIdMenu(dato.id_tipo_usuario);
    setNombreTipo(dato.nombre_tipo_us);
  
    const menusNombres = dato.acceso.map((indice) => {
      const menu = ListaMenus.find((item) => parseInt(item.id_menu) === parseInt(indice));
      return menu ? menu.nombre : ''; // Devuelve el nombre si se encuentra, de lo contrario una cadena vacía
    });
  
    setMenus(menusNombres);
  };
  

  const limpiar = () => {
        setIdMenu(null);
        setNombreTipo("")
        setMenus([]);
  }
  const guardarUsuario = () => {
          let guardar = false
          
          
          if (NombreTipo  )
              guardar = true;
          
          
          if (guardar === false){
              MySwal.fire({
                title: 'Guardar Persona',
                text: "Debe llenar todos los campos en el formulario",
                icon: 'error'
                })
                return 0;
          }

          const idsSeleccionados = Menus.map((menuSel) => {
            const menu = ListaMenus.find((item) => item.nombre === menuSel);
            return menu ? menu.id_menu : null; // Retorna el ID si se encuentra, de lo contrario, null
          });
      
          // Filtra los IDs no nulos (por si acaso)
          const ListaAccesos = idsSeleccionados.filter((id) => id !== null);

          let data = {
            id_tipo_usuario  : IdMenu,
            nombre_tipo_us : NombreTipo,
            acceso : ListaAccesos,
          }


          setLoading(true)       

        PostData('/usuario/guardartipos', data).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){                
                setLoading(false) 
                MySwal.fire({
                  title: 'Guardar',
                  text: responseJSON.mensaje,
                  icon: 'success'
                  }
                )
                listado_tipos();
                limpiar();
            }else{
             // setError(true);
              setLoading(false) 
              MySwal.fire({
                title: 'Guardar',
                text: responseJSON.mensaje,
                icon: 'error'
                });
          }
        })
        
  }

  const borrar_empresa = (id_usuario) => {
    setLoadingTbl(true);

   
    GetData('empresas/delete/').then ((result) =>{
          if (result.error === false){
            listado_tipos();
          }
    });
  }

  
 
  useEffect(() => {
    GetData('usuario/listamenus').then ((result) =>{
      if (result.error === false){
           setListaMenus(result.menus)
        }
    });
      listado_tipos();
  }, [])
  

  return (
    <>
     { Loading && <div className="loading">Loading&#8230;</div> }
    <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">{IdMenu === null ? 'CREAR TIPO DE USUARIO' : 'ACTUALIZAR TIPO DE USUARIO'}</h4>
                    <form className="forms-sample" autocomplete="off">
                    <div className='form-row'>

                      <div className="form-group col-3">
                        <label for="exampleInputUsername1">Tipo Usuario</label>
                        <input name="nit"
                        onChange={event => setNombreTipo(event.target.value)} 
                        value={NombreTipo}
                        autocomplete="off"
                        type="text" className="form-control form-control-sm" id="nit" placeholder="Tipo de usuario" required />
                      </div>
                     
                     
                      <div className='form-group col-6'>
                      <FormControl sx={{ m: 1, width: '100%' }}>
                        <InputLabel id="demo-multiple-chip-label">Permisos Menus</InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={Menus}
                          onChange={handleChange}
                          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => {
                                  const menu = ListaMenus.find((item) => item.nombre === value);
                                  if (menu) {
                                    return (
                                      <Chip key={menu.id_menu} label={`${menu.nombre}`} />
                                    );
                                  }
                                  return null;
                                })}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {ListaMenus.map((item) => (
                            <MenuItem
                              key={item.id_menu }
                              value={item.nombre}
                              style={getStyles(item.nombre, personName, theme)}
                            >
                              {item.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      </div> 

                    </div>
                      <button type="button" className="btn btn-primary mr-2" 
                         disabled={NombreTipo === "" ? true : false}  
                      onClick={guardarUsuario}> Guardar </button>
                      <button className="btn btn-light" onClick={limpiar}>Nuevo</button>
                    </form>

                  
                  </div>
                </div>
              </div>
      </div>
      <div className='row'>
           <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                      
                                       
                    <div className="table-responsive">
                      <table className="table100 table-hover">
                        <thead className='table100-head'>
                          <tr>
                            <th>Tipo Usuario</th>
                            <th>Permisos</th>
                            <th>Opciones</th>
                          </tr>
                        </thead>
                        <tbody>
                        {ListaTiposUS.map((item, index) => (
                          <tr key={index} onClick={() => llenarDatos(index)}>
                            <td className="py-1">
                              {item.nombre_tipo_us}
                            </td>
                            <td className="py-1">
                              <ChipPermisosUsuarios acceso={item.acceso} ListaMenus={ListaMenus}  />
                            </td>
                            <td>            
                              <a href={numeral} onClick={() => delete_empresa(item.id_tipo_usuario, item.nombre_tipo_us)}>
                                <i className="mdi mdi-delete-forever" aria-hidden="true"></i>Editar
                              </a>
                            </td>
                          </tr>
                        ))}
                        
                          
                        </tbody>
                      </table>
                    </div>
                    <div className='mt-2'>
                    { LoadingTbl && <LinearProgress /> }
                    </div>
                  </div>
                </div>
              </div>
      </div>
    </>
  )
}

export default TiposUsuario;