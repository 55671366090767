import { AppBar, Button, Checkbox, Chip, Dialog, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { URL_SERVICIOS } from '../../services/UrlWebServis';
import CloseIcon from '@mui/icons-material/Close';
import { Usuario } from '../../services/Usuario';

const VerDespacho = () => {
    
    const { idDespacho } = useParams();
    const [numDespacho, setnumDespacho] = useState(0)
    const [fechaDepacho, setfechaDepacho] = useState("")
    const [vehiculo, setVehiculo] = useState("")
    const [Listavehiculo, setListaVehiculo] = useState([])
    const [ListaConductores, setConductores] = useState([])
    const [ListaAuxiliares, setListaAuxiliares] = useState([])
    const [ListaReexpedidor, setListaReexpedidor] = useState([])
    const [ListaPoblaciones, setListaPoblaciones] = useState([])
    const [ListaTablaGuias, setListaTablaGuias] = useState([])
    const [conductor, setConductor] = useState("")
    const [auxiliar1, setAuxiliar1] = useState("")
    const [auxiliar2, setAuxiliar2] = useState(0)
    const [reexpedidor, setReexpedidor] = useState("")
    const [sumaflete, setSumaFlete] = useState(0)
    const [sumafleteEntre, setSumaFleteEntre] = useState(0)
    const [sumaUnidades, setSumaUnidades] = useState(0)
    const [LoadingTbl, setLoadingTbl] = useState(false) 
    const [Loading, setLoading] = useState(false) 
    const [ListaGuias, setListaGuias] = useState([]) 
    const [ListaGuiasTabla, setListaGuiasTabla] = useState([]) 
    const [NumTemp, setNumTemp] = useState("");
    const [openImpresion, setopenImpresion] = useState(false)
    const [UrlImpresion, setUrlImpresion] = useState(0);
    const [CheckEntregadas, setCheckEntregadas] = useState(false)
    const {	id_tipos_usuario } = Usuario();
    const navigate = useNavigate();

    const generateUUID = () => {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    const consultar_despacho = () => {
        setLoading(true);
        GetData('/despachos/consultardespacho/'+idDespacho).then ((result) =>{
            let responseJSON = result;
            
      
            
            if (responseJSON.error === false){
                if (responseJSON.haydatos === true){
                    const datosdespacho = responseJSON.datos[0];
                    
                    setfechaDepacho(datosdespacho.fecha_despacho)

                    setConductor(datosdespacho.id_conductor)
                    setVehiculo(datosdespacho.id_vehiculo)
                    setAuxiliar1(datosdespacho.id_auxiliar1)
                    setAuxiliar2(datosdespacho.id_auxiliar2)
                    setReexpedidor(datosdespacho.id_reexpedidor)
                    consultar_detalle_despacho(idDespacho);
                    setUrlImpresion(`https://unirexsas.com/api_unirex/index.php/impresiones/despacho/`+idDespacho);
                }
                else{
                    setLoading(false)
                    const id_desp = generateUUID();
                    setNumTemp(id_desp);
                    
                }   
            }else{
            }
        })    
    }

    useEffect(() => {
        if (idDespacho){
            setnumDespacho(idDespacho);
            consultar_despacho()
        }
    }, [idDespacho])
    
 
    const consultar_detalle_despacho = (id_despacho) => {
        setLoadingTbl(true);
        GetData('/despachos/detalledespachosg/'+id_despacho).then ((result) =>{
            let responseJSON = result;
            
      
            if (responseJSON.error === false){

                const datos = responseJSON.guias;
                
                setListaGuias(datos);

              
                
                let sumafleteg = 0;
              //  let poblacion = ListaPoblaciones;
                let tunidades = 0;
                /*
                datos.forEach((dguia) => {       
                    if (dguia){             
                        sumafleteg += parseFloat(dguia.flete)
                        if (ListaPoblaciones.indexOf(dguia.mun_nombre) === -1){
                            poblacion = [...poblacion, dguia.mun_nombre]
                            
                            setListaPoblaciones(poblacion)
                        }
                        
                        tunidades += parseInt(dguia.unidades)
                        
                        setSumaUnidades(tunidades)
                    }
                });
*/
                mostrar_poblaciones(datos)
                
                
           //     setSumaFlete(sumafleteg);
                setLoadingTbl(false) 
                setLoading(false)
            }else{
            }
        })    
    }


    
    const mostrar_poblaciones = (datos) => {

        let sumafleteg = 0;
        let sumafleteEnt = 0;
        let tunidades = 0;
        let listapoblaciones = [];
        let municipio = "";
        //console.log("...................................mostrar poblaciones");
        datos.forEach((dguia, index) => {       
            if (dguia){             
                sumafleteg += parseFloat(dguia.flete)
                municipio = dguia.mun_nombre.toUpperCase();
              
                if (listapoblaciones.indexOf(municipio) === -1){
                    listapoblaciones = [...listapoblaciones, municipio]
                    
                }
             
                if (parseInt(dguia.id_estado_guia) === 3 && parseFloat(dguia.flete) > 0) //si esta entregada y si el flete es mayor a cero
                    sumafleteEnt += parseFloat(dguia.flete);
                
                tunidades += parseInt(dguia.unidades)
                
                setSumaUnidades(tunidades)
            }
        });
        setListaPoblaciones(listapoblaciones)
        setSumaFlete(sumafleteg);
        setSumaFleteEntre(sumafleteEnt)
}


    

    const cargar_vehiculos = () => {

        GetData('vehiculos/listar').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.vehiculos;
          
              let listaselect = listatipos.map(item => <MenuItem value={item.id_vehiculo}>{item.placa}</MenuItem>)
              setListaVehiculo(listaselect)
          }else{
        
        }
      })
    }

    const cargar_conductores = () => {
        
        GetData('empleados/listar/1').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.empleados;

              let listaselect = listatipos.map(item => <MenuItem value={item.id_empleado}>{item.nombre_empleado}</MenuItem>)
              setConductores(listaselect)
          }else{
        
        }
      })
    }

    const cargar_auxiliares = () => {
        
        GetData('empleados/listar/2').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.empleados;
              let listaselect;              
              listaselect = listatipos.map(item => <MenuItem value={item.id_empleado}>{item.nombre_empleado}</MenuItem>)
              setListaAuxiliares(listaselect)
          }else{
        
        }
      })
    }

    const cargar_reexpedidores = () => {
        
        GetData('empleados/listar/3').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.empleados;
          
              let listaselect = listatipos.map(item => <MenuItem value={item.id_empleado}>{item.nombre_empleado}</MenuItem>)
              setListaReexpedidor(listaselect)
          }else{
        
        }
      })
    }

    useEffect(() => {
        const today = new Date();
        let day = today.getDate();
        let month = today.getMonth() + 1;
        const year = today.getFullYear();

        if(day<10)
           day='0'+day; //agrega cero si el menor de 10
        if(month<10)
           month='0'+month //agrega cero si el menor de 10

        let fecha = `${year}-${month}-${day}`;
     //   console.log(fecha)
       // setfechaDepacho(fecha)
        cargar_vehiculos();            
        cargar_conductores();
        cargar_auxiliares();
        cargar_reexpedidores();
    }, [])

    

   
    const handleCloseImpresion = () => {
        setopenImpresion(false);
    };
    
    const ExportarExcel = () => {
        
        window.open(URL_SERVICIOS + '/impresiones/exportarexceldespacho/' + idDespacho, '_blank').focus();

    }

  

    const format_number = (number) => {        
        const nf = new Intl.NumberFormat();
       return nf.format(number);  
      
     }

     useEffect(() => {        
        if (CheckEntregadas === true){
            const guiasFiltradas = ListaGuias.filter(guia => parseInt(guia.id_estado_guia) === 3 && guia.flete > 0);
            setListaGuiasTabla(guiasFiltradas);
            setUrlImpresion(`https://unirexsas.com/api_unirex/index.php/impresiones/liquidardespacho/`+idDespacho);
        }else{
            setListaGuiasTabla(ListaGuias)
            setUrlImpresion(`https://unirexsas.com/api_unirex/index.php/impresiones/despacho/`+idDespacho);
        }

    }, [CheckEntregadas, ListaGuias])

    
    const IrA2 = (id_despacho) => {        
        navigate('/home/nuevodespachos3/'+id_despacho)  
    }
    const IrA = (id_despacho) => {        
        navigate('/home/nuevodespachos/'+id_despacho)  
    }

    return (
        <>
        { Loading && <div className="loading">Loading&#8230;</div> }
       
         <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                                <div className='row mb-3'>
                                    <div className='col-6'>
                                        <h4 className="card-title">DESPACHO Nº {numDespacho > 0 ? numDespacho : NumTemp}</h4>
                                    </div>
                                    <div className='col-6 text-right'>
                                        <button type="button" class="ml-1 btn btn-primary" onClick={ () => IrA(numDespacho)} > 
                                         Modificar 
                                        </button>
                                        <button type="button" class="ml-1 btn btn-info" onClick={ () => IrA2(numDespacho)} > 
                                         Modificar FS
                                        </button>
                                        <button type="button" class="ml-1 btn btn-warning" disabled={ListaGuias.length === 0 || numDespacho === 0 ? "true" : ""} onClick={ () => setopenImpresion(true)} > 
                                         Imprimir 
                                        </button>
                                        <button type="button" class="btn btn-dark ml-2"
                                            onClick={() => ExportarExcel()}
                                            disabled={numDespacho === 0 ? "true" : ""}  
                                        >Exportar</button>
                                    </div>
                                </div>  
                                
                                <div className='form-row'>
                                
                                
                                    <div className="form-group col-md-2">
                                        <FormControl fullWidth>
                                        <TextField type="date"
                                            InputLabelProps={{ shrink: true, required: true }}
                                            id="fecha" label="Fecha" variant="outlined" size="small" value={fechaDepacho}  defaultValue={fechaDepacho} 
                                            disabled
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="form-group col-md-2">
                                    <FormControl fullWidth>
                                        <InputLabel id="select_vehiculos">Vehículo</InputLabel>
                                        <Select
                                                labelId="select_vehiculos"
                                                id="select_vehiculos"
                                                value={vehiculo}
                                                label="Vehículo"
                                                onChange={event => setVehiculo(event.target.value)} 
                                                size="small"
                                                disabled
                                            >
                                                {Listavehiculo}
                                            </Select>
                                            </FormControl>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <FormControl fullWidth>
                                            <InputLabel id="select_conductor">Conductor</InputLabel>
                                            <Select
                                                    labelId="select_conductor"
                                                    id="select_conductor"
                                                    value={conductor}
                                                    label="Conductor"
                                                    onChange={event => setConductor(event.target.value)} 
                                                    size="small"
                                                    disabled
                                                >
                                                    {ListaConductores}
                                                </Select>
                                          </FormControl>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <FormControl fullWidth>
                                        <InputLabel id="select_auxiliar1">Auxiliar 1</InputLabel>
                                        <Select
                                                labelId="select_auxiliar1"
                                                id="select_auxiliar1"
                                                value={auxiliar1}
                                                label="Auxiliar 1"
                                                onChange={event => setAuxiliar1(event.target.value)} 
                                                size="small"
                                                disabled
                                            >
                                                {ListaAuxiliares}
                                            </Select>
                                            </FormControl>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <FormControl fullWidth>
                                        <InputLabel id="select_auxiliar2">Auxiliar 2</InputLabel>
                                        <Select
                                                labelId="select_auxiliar2"
                                                id="select_auxiliar2"
                                                value={auxiliar2}
                                                label="Auxiliar 2"
                                                onChange={event => setAuxiliar2(event.target.value)} 
                                                size="small"
                                                disabled
                                            >
                                                <MenuItem value={0}>Seleccion...</MenuItem> 
                                                {ListaAuxiliares}
                                            </Select>
                                            </FormControl>
                                    </div>        
                                    <div className="form-group col-md-2">
                                        <FormControl fullWidth>
                                        <InputLabel id="select_reexpedidor">Reexpedidor</InputLabel>
                                        <Select
                                                labelId="select_reexpedidor"
                                                id="select_reexpedidor"
                                                value={reexpedidor}
                                                label="Reexpedidor"
                                                onChange={event => setReexpedidor(event.target.value)} 
                                                size="small"
                                                disabled
                                            >
                                                {ListaReexpedidor}
                                            </Select>
                                            </FormControl>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <FormControl fullWidth className='formato_poblaciones'>
                                            <Typography gutterBottom variant="overline" className='texto_poblaciones'>
                                                Poblaciones
                                            </Typography>
                                           <Stack direction="row" spacing={1}>
                                                {ListaPoblaciones.map((row) => (<Chip label={row} /> ))}                                                                                       
                                            </Stack> 
                                        </FormControl>
                                    </div>   
                                </div>
                                <div className='form-row' >
                                     <div className='col-2'><strong>Total Guías:</strong> {ListaGuias.length}</div>   
                                     <div className='col-2'><strong>Total Flete:</strong> $ {format_number(sumaflete)}</div>   
                                     <div className='col-2'><strong>Fletes Entr:</strong> $ {format_number(sumafleteEntre)}</div>   
                                     <div className='col-2'><strong>Total Unidades:</strong> {sumaUnidades}</div>   
                                     <div className='col-2'><strong>Liquidar</strong> <Checkbox onChange={()=> setCheckEntregadas(!CheckEntregadas)} style={{ padding: 0, margin: 0 }} /> </div>
                                </div>
                          </div>
    
                          <div className='row'>
                                <div className="col-lg-12 grid-margin stretch-card">
                                        <div className="card">
                                        <div className="card-body">
                                            
                                                            
                                            <div className="table-responsive">
                                            <div className='mt-2'>
                                            { LoadingTbl && <LinearProgress /> }
                                            </div>
                                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                            <TableContainer sx={{ maxHeight: 340 }}>
                                                <Table size="small" aria-label="tabla de guías" >
                                                    <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nº Guía</TableCell>
                                                        <TableCell align="right">Fechas Asig.</TableCell>
                                                        <TableCell align="center">Población</TableCell>
                                                        <TableCell align="center">Unidades</TableCell>
                                                        <TableCell align="left">Remitente</TableCell>
                                                        <TableCell align="left">Destinatario</TableCell>
                                                        <TableCell align="right">Flete</TableCell>
                                                        <TableCell align="center">Estado</TableCell>
                                                        <TableCell align="left">Empresa</TableCell>
                                                        
                                                    </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    
                                                   { ListaGuiasTabla.map((row, index) => (
                                                        <TableRow
                                                        key={row.num_guia}
                                                        hover role="checkbox" tabIndex={-1} 
                                                        >
                                                        <TableCell component="th" scope="row" align="left">
                                                        <Link to={`/home/detalleguia/${row.num_guia}`} >{row.num_guia}</Link>
                                                        </TableCell>
                                                        <TableCell align="right">{row.fecha_asignacion}</TableCell>
                                                        <TableCell align="center">{row.mun_nombre}</TableCell>
                                                        <TableCell align="center">{row.unidades}</TableCell>
                                                        <TableCell align="left">{row.remitente}</TableCell>
                                                        <TableCell align="left">{row.destinatario}</TableCell>
                                                        <TableCell align="right">$ {format_number(row.flete)}</TableCell>
                                                        <TableCell align="center">{row.nombre_estado_guia}</TableCell>
                                                        <TableCell align="left">{row.nombre_empresa}</TableCell>
                                                        </TableRow>
                                                    ))   } 
                                                        
                                                    </TableBody>
                                                </Table>
                                                </TableContainer>
                                            </Paper>


                                            </div>
                                           
                                        </div>
                                        </div>
                                    </div>
                            </div>  
                                
    
                        
                    </div> {/* card */}
                  </div> {/* col-md-12 */}
                </div>  {/* row */}

               
                <Dialog
                    fullScreen
                    open={openImpresion}
                    onClose={handleCloseImpresion}
                    
                >
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseImpresion}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Button autoFocus color="inherit" onClick={handleCloseImpresion}>
                            Cerrar
                        </Button>
                    </Toolbar>
                    </AppBar>
                    <div>
                        <iframe src={UrlImpresion} className="inframemax">
                        </iframe> 
                    </div>
                </Dialog>
        </>
      )
}

export default VerDespacho