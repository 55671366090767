import {URL_SERVICIOS}  from './UrlWebServis';
import { Usuario } from './Usuario';


export function PostData(type, userData){    
    
    const usuario = Usuario();
    let token = '';
    if (usuario !== null)
        token = usuario.token;
    
    userData = { ...userData, token}

    return new Promise((resolve, reject) => {
        fetch(URL_SERVICIOS+type,{            
            method: 'POST',
            body: JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            console.log("Error " + error);
           userData.error_login =  true;
           reject(error);
           
        })
    })    



}