export const AG_GRID_LOCALE_ES = {
    // Set Filter
    DAY: "día",
    DATE: "fecha",
    MEALTIME: "hora de comer",
    FOOD: "¿que se comió?",
    PRICE: "precio",
    BREAKFAST: "Desayuno",
    LUNCH: "Almuerzo",
    DINNER: "Cena",
    PORRIDGE: "gachas de avena",
    OMLETTE: "tortilla frita",
    SANDWICH: "sándwich",
    SOUP: "sopa",
    PROTEINSHAKE: "batido de proteínas",
    CHOCOLATEBAR: "barra de chocolate",
    SAUSAGES: "salchichas",
    STEAK: "bistec",
    LAMBCHOPS: "chuletas de cordero",
    // Start of ag-Grid locale translations
    selectAll: "(seleccionar todo)",
    searchOoo: "buscar...",
    blanks: "(espacios en blanco)",
    noMatches: "no hay coincidencias",

    // Number Filter & Text Filter
    filterOoo: "filtrar...",
    equals: "igual",
    notEqual: "no es igual",
    empty: "elige uno",
    
    blank: 'Vacío',
    notBlank: 'No vacío',

    // Number Filter
    lessThan: "menos que",
    greaterThan: "mas grande que",

    // Text Filter
    contains: "contiene",
    notContains: 'No contiene',
    startsWith: 'Comienza con',
    endsWith: 'Finaliza con',


    // Date Filter
    dateFormatOoo: "yyyy-mm-dd",

    // Filter Buttons
    applyFilter: "aplicar",
    resetFilter: "Reiniciar",

    // Filter Titles
    textFilter: "filtro de texto",
    numberFilter: "filtro de número",
    dateFilter: "filtro de fecha",
    setFilter: "filtro de valores",

    // Side Bar
    columns: "columnas",
    filters: "filtros",

    // Other
    loadingOoo: "cargando...",
    noRowsToShow: "no hay filas para mostrar",
    enabled: "habilitado",

    // Menu
    pinColumn: "alfiler columna",
    pinLeft: "pin a la izquierda",
    pinRight: "pin a la derecha",
    noPin: "no Pin",

    autosizeThiscolumn: "tamaño automático de esta columna",
    autosizeAllColumns: "tamaño automático de todas las columnas",
    resetColumns: "restablecer columnas",
    copy: "Copiar",
    ctrlC: "Ctrl+C",
    copyWithHeaders: "copiar con encabezados",
    paste: "pegar",
    ctrlV: "Ctrl+V",
    export: "exportar",
    csvExport: "CSV exportar",
    excelExport: "Excel exportar (.xlsx)",
    excelXmlExport: "Excel exportar (.xml)"
  };