import { Navigate, Outlet, Route, Routes, useNavigate, useLocation  } from "react-router-dom";
import Login from "./pages/login/login";

import { RequireAuth } from "./services/RequiereAuth";
import Layout from "./pages/home/layout";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { rutasUsuario } from "./listadoRutas"
import { GetData } from "./services/GetData";

export const MisRutas = () =>{
    
    const usuario = useSelector(state => state.auth.user);
    const [IdUsuario, setIdUsuario] = useState(5)
    const [IndexRutas, setIndexRutas] = useState([0])    
    const navigate = useNavigate();
    const location = useLocation();
    const rutaActual = location.pathname;
    
    
    

    useEffect(() => {
      //  console.log(usuario)
      /* let menus = [];

      GetData('usuario/menus').then ((result) =>{
          menus = result.menus
          console.log(menus.path)
      }) */
      
      if (usuario){

        setIdUsuario(usuario.id_tipo_usuario)
        const acceso = usuario.acceso;
        const indices = acceso.split(',').map((indice) => parseInt(indice.trim(), 10));

        const rutasFiltradas = rutasUsuario.filter((ruta) => {
          
          return indices.includes(ruta.indice);
        });
        
        setIndexRutas(rutasFiltradas);
      }
      //  const rutastore = localStorage.getItem('rutaActual');
//        if (rutaActual)
            
    }, [usuario])

    
    useEffect(() => {
        navigate(rutaActual, { replace: true })
    }, [])
    
    
    
    
    // Función recursiva para generar las rutas anidadas
    const generarRutasAnidadas = (rutas) => {
        return rutas.map((ruta, index) => {
        if (ruta.children) {
            return (
            <Route
                key={index}
                path={ruta.path}
                element={
                <Outlet>
                    {generarRutasAnidadas(ruta.children)}
                </Outlet>
                }
            />
            );
        } else {   
            return (
            <Route
                key={index}
                path={ruta.path}
                element={ruta.element}
            />
            );
        }
        });

        
    };

    
    return (

        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Navigate to="/login" />} />

            <Route path="/home" element={<RequireAuth><Layout /></RequireAuth>}>
            {generarRutasAnidadas(IndexRutas)}
            </Route>
  

            <Route path="*" element={<Login />} />
        </Routes>

        )
}


         {/* <Routes >
    <Route path="/" element={<Login />} />
    <Route path="/login" element={<Navigate to="/login" />} />

    <Route path="/home" element={
    <RequireAuth>
      <Layout />
    </RequireAuth>
  } >
     

        <Route index path="/home" element={<Home />} />

        <Route
            element={
              <RutasPrivadas tipouser={IdUsuario}>
                <Route path="vehiculos" element={<Vehiculos />} />
                <Route path="usuarios" element={<Usuarios />} />
                <Route path="empleados" element={<Empleados />} />
                <Route path="empresas" element={<Empresas />} />
              </RutasPrivadas>
            }
          />

        <Route path="rutas" element={<Rutas />} />
        <Route path="guias" element={<Guias />} />

        <Route path="coleccion" element={<Coleccion />} />
        <Route path="nuevodespachos" element={<NuevoDespachos />} />
        <Route path="nuevodespachos/:idDespacho" element={<NuevoDespachos />} />
        <Route path="importarguias" element={<ImportarGuias />} />
        <Route path="verdepachos/:idDespacho" element={<VerDespacho />} />
        <Route path="rutashoy" element={<RutasHoy />} />
        <Route path="listadodespachos" element={<ListarDespachos />} />
        <Route path="consultarguias" element={<ConsultarGuia2 />} />
        <Route path="consultarguias/:idEstado" element={<ConsultarGuia2 />} />
        <Route path="detalleguia/:numguia" element={<DetalleGuia />} /> 
    </Route>
    <Route path="/" element={<Login />} />
    <Route path="*" element={<Login />} />
  </Routes> */}