import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select, Snackbar } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { PostData } from '../services/PostData';
import { useState } from 'react'
import { Usuario } from '../services/Usuario';
import { GetData } from '../services/GetData'

export const NuevaGuia = ({ open, onClose }) => {
    const [Loading, setLoading] = useState(false) 
    const [openMSGuia, setOpenMSGuia] = useState(false) 
    const MySwal = withReactContent(Swal)

     /********** nueva guia****/

     const [numGuia, setnumGuia] = useState("")
     const [fechaAsignacion, setfechaAsignacion] = useState("")
     const [Remitente, setRemitente] = useState("")
     const [Destinatario, setDestinatario] = useState("")
     const [Unidades, setUnidades] = useState("")
     const [Flete, setFlete] = useState("")
     const [Poblacion, setPoblacion] = useState("")
     const [Empresa, setEmpresa] = useState("")
     const [SelListaPoblaciones, setSelListaPoblaciones] = useState([])
     const [ListaEmpresas, setListaEmpresas] = useState([])
     const usuario = Usuario();

     useEffect(() => {
        setnumGuia("")
        setfechaAsignacion("")
        setRemitente("")
        setDestinatario("")
        setUnidades("")
        setFlete("")
        setPoblacion("")
        setEmpresa("")
     }, [])

     useEffect(() => {
        cargar_empresas();
        cargar_poblaciones();
    }, [])

     const cargar_poblaciones = () => {

        GetData('despachos/listarpoblaciones/').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.poblaciones;
              
              let listaselect = listatipos.map(item => <option key={item.id_municipio} value={item.id_municipio}>{item.municipio}</option>)

             
              setSelListaPoblaciones(listaselect)
              
          }else{
        
        }
      })
    }

    const cargar_empresas = () => {

        GetData('empresas/listar/').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.empresas;
          
              let listaselect = listatipos.map(item => <MenuItem key={item.id_empresa} value={item.id_empresa}>{item.label}</MenuItem>)
              setListaEmpresas(listaselect)
          }else{
        
        }
      })
    }

     const GuardarNuevaGuia = () => {
        let data = {
            id_usuario : usuario.id_usuario,
            num_desp_temp: null,
            num_despacho : null,
            num_guia: numGuia, 
            fecha: fechaAsignacion, 
            remitente: Remitente, 
            destinatario: Destinatario, 
            unidades: Unidades, 
            flete: Flete, 
            poblacion: Poblacion, 
            empresa: Empresa
        }

        setLoading(true);       
            
        PostData('/guias/guardarguia', data).then ((result) =>{
            let responseJSON = result;            
            if (responseJSON.error === false){                                
                
                setLoading(false);
                if (responseJSON.insertado === 1){
                                   
                    setOpenMSGuia(true)
                    setnumGuia("")
                    setfechaAsignacion("")
                    setRemitente("")
                    setDestinatario("")
                    setUnidades("")
                    setFlete("")
                    setPoblacion("")
                    setEmpresa("")
                }

            }else{
                setLoading(false);
                MySwal.fire({
                    title: 'Guardar',
                    text: "Error al guardar la Guía",
                    icon: 'error'
                    });
          }
        })


    }
     
    const handleClose = () => {   
         setOpenMSGuia(false);
    };

  return (
    <>
             <Snackbar open={openMSGuia} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert severity="success">Gía Guardada con exito !</Alert>
            </Snackbar> 
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Nueva Guía</DialogTitle>
                <DialogContent>
                
                <div className='form-row'>
                    
                    <div className="form-group col-md-6 col-sm-6">
                        <label htmlFor="exampleInputUsername1">Nº de Guía</label>
                        <input name="num_guia" value={numGuia} onChange={event => setnumGuia(event.target.value)}  type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Fecha Asignación</label>
                        <input name="fecha" value={fechaAsignacion} onChange={event => setfechaAsignacion(event.target.value)} type="date" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Remitente</label>
                        <input name="remitente" value={Remitente} onChange={event => setRemitente(event.target.value)} type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Destinatario</label>
                        <input name="destinatario" value={Destinatario} onChange={event => setDestinatario(event.target.value)} type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Unidades</label>
                        <input name="unidades" value={Unidades} onChange={event => setUnidades(event.target.value)} type="number" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6 col-sm-6 ">
                        <label htmlFor="exampleInputUsername1">Flete</label>
                        <input name="flete" value={Flete}  onChange={event => setFlete(event.target.value)} type="number" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                    <label htmlFor="selepoblaciones">Poblacion</label>
                         <select  className='form-control form-control-sm' data-live-search="true"  id="selepoblaciones" value={Poblacion}  onChange={event => setPoblacion(event.target.value)} >
                            {SelListaPoblaciones}
                        </select> 

                        

                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                        <label htmlFor="exampleInputUsername1">Empresa</label>
                        <FormControl fullWidth>
                                        <Select
                                                labelId="select_reexpedidor"
                                                id="select_reexpedidor"
                                                value={Empresa}
                                                label="Empresa"
                                                onChange={event => setEmpresa(event.target.value)}
                                                size="small"
                                            >
                                                {ListaEmpresas}
                                            </Select>
                                            </FormControl>
                    </div>

                </div>

                </DialogContent>
                <DialogActions>
                <Button onClick={onClose} color="secondary">Cerrar</Button>
                <Button onClick={()=> GuardarNuevaGuia() }
                        disabled={numGuia === "" ||
                                 fechaAsignacion === "" ||
                                 Remitente === "" || 
                                 Destinatario === "" || 
                                 Unidades === "" || 
                                 Flete === "" || 
                                 Poblacion === "" || 
                                 Empresa === "" 
                                    ? true : false}
                          
                >Guardar</Button>
                </DialogActions>
            </Dialog>
    </>
  )
}
