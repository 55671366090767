import { useLocation, Navigate } from "react-router-dom";

import { Usuario } from "./Usuario";

export function RequireAuth({ children }) {
  let usuario = Usuario();
  let location = useLocation();

  if (!usuario) {
        return <Navigate to="/" state={{ from: location }} replace />;
  } else {
        return children;
  }
}
