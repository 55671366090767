import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react'
import UploadFiles from "../../components/upload-files.component";
import {Usuario} from "../../services/Usuario"

const ImportarGuias = () => {

   const {id_usuario} = Usuario()
   const [tipoArchivo, setTipoArchivo] = useState(0) 
   
    return (
    <>
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                            <h4 className="card-title">IMPORTAR GUIAS</h4>

                            <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Tipo de Archivo</FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue={0}
                              name="radio-buttons-group"
                            >
                              <FormControlLabel value={0} control={<Radio />} label="Coordinadora" onChange={event => setTipoArchivo(event.target.value)}  />
                              <FormControlLabel value={1} control={<Radio />} label="Unirex"  onChange={event => setTipoArchivo(event.target.value)} />
                            </RadioGroup>
                          </FormControl>
                            
                            <UploadFiles tipoArchivo={tipoArchivo} id_usuario={id_usuario} />

                    </div>
                </div> {/* card */}
              </div> {/* col-md-12 */}
            </div>  {/* row */}
    </>
  )
}
export default ImportarGuias
