import { Button, Dialog, DialogActions, FormControl, DialogContent, DialogTitle, MenuItem, Select, Tooltip, Checkbox, FormGroup, FormLabel } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { GetData } from '../services/GetData'
import { Usuario } from '../services/Usuario'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Modificaguia = ({num_guia, actualizarGuia}) => {

     /********** nueva guia****/
     const MySwal = withReactContent(Swal)
     const [openNuevaGuia, setopenNuevaGuia] = useState(false)
     const [numGuia, setnumGuia] = useState("")
     const [fechaAsignacion, setfechaAsignacion] = useState("")
     const [Remitente, setMRemitente] = useState("")
     const [Destinatario, setMDestinatario] = useState("")
     const [Unidades, setMUnidades] = useState("")
     const [Flete, setMFlete] = useState("")
     const [FletePSE, setMFletePSE] = useState(false)
     const [Fisica, setFisica] = useState("")
     const [Poblacion, setMPoblacion] = useState("")
     const [Empresa, setMEmpresa] = useState("")
     const [Direccion, setMDireccion] = useState("")
     const [Producto, setMProducto] = useState("")
     const [SelListaPoblaciones, setSelListaPoblaciones] = useState([])
     const [ListaEmpresas, setListaEmpresas] = useState([])
     const [fechaUpdate, setfechaUpdate] = useState("");
     const [Loading, setLoading] = useState(false)
     const [Update, setUpdate] = useState(false);  
     const [estado, setEstado] = useState("");
     const usuario = Usuario();
     const numeral = "#";
     
     useEffect(() => {
        GetData('empresas/listar/').then ((result) =>{
            let responseJSON = result;
            
      
            if (responseJSON.error === false){
                const listatipos = responseJSON.empresas;
            
                let listaselect = listatipos.map(item => <MenuItem value={item.id_empresa}>{item.label}</MenuItem>)
                setListaEmpresas(listaselect)
            }else{
          
          }
        })
     }, [])
        
        
    

     useEffect(() => {
        GetData('despachos/listarpoblaciones/').then ((result) =>{
          let responseJSON = result;
            
        
            if (responseJSON.error === false){
                const listatipos = responseJSON.poblaciones;                
                let listaselect = listatipos.map(item => <option value={item.id_municipio}>{item.municipio}</option>)                
                setSelListaPoblaciones(listaselect)
                
            }else{
            
            }
        })
     }, [])
  
     const GuardarGuia = () => {
        let data = {
            id_usuario : usuario.id_usuario,
            num_guia: numGuia, 
            fecha: fechaAsignacion, 
            remitente: Remitente, 
            destinatario: Destinatario, 
            unidades: Unidades, 
            flete: Flete, 
            flete_pse: FletePSE ? '1' : '0', 
            poblacion: Poblacion, 
            empresa: Empresa,
            direccion: Direccion,
            tproducto: Producto
        }
    
        setopenNuevaGuia(false)
        setLoading(true);       
            
        PostData('/guias/updateguia', data).then ((result) =>{
            let responseJSON = result;            
            
  
            if (responseJSON.error === false){                                
                
                setLoading(false);
                if (responseJSON.insertado === 1){
                                   
                    setnumGuia("")
                    setfechaAsignacion("")
                    setMRemitente("")
                    setMDestinatario("")
                    setMUnidades("")
                    setMFlete("")
                    setMFletePSE(0)
                    setMPoblacion("")
                    setMEmpresa("")
                    setMDireccion("")
                    setMProducto("")
                    setUpdate(!Update)
  
                    MySwal.fire({
                      title: 'Actualizar Guía',
                      text: "Guía actualizada con exito",
                      icon: 'success'
                      });
                      const fpse = FletePSE ? '1' : '0';
                      actualizarGuia(numGuia, fpse, Flete)
                }
    
            }else{
                setLoading(false);
                MySwal.fire({
                    title: 'Guardar',
                    text: "Error al guardar la Guía",
                    icon: 'error'
                    });
          }
        })
    
    
    }
  
        
    const handleCloseNuevaGuia = () => {
              setopenNuevaGuia(false)
   };

   const editar_guia = (numguia) => {
        setopenNuevaGuia(true);
        GetData('guias/buscarguia/'+numguia).then ((result) =>{
        
        if (result.error === false){
            
            const datoguia = result.guia[0];
            if (datoguia){
                setnumGuia(datoguia.num_guia);
                setfechaAsignacion(datoguia.fecha_asignacion);
                setMRemitente(datoguia.remitente);
                setMDestinatario(datoguia.destinatario);
                setMUnidades(datoguia.unidades);
                setMFlete(datoguia.flete)
                setMFletePSE(datoguia.flete_pse === '1' ? true : false )
                setMPoblacion(datoguia.id_municipio)
                setMEmpresa(datoguia.id_empresa)
                setMDireccion(datoguia.direccion)
                setMProducto(datoguia.tproducto)
                setEstado(datoguia.nombre_estado_guia)
                setfechaUpdate(datoguia.fecha_update)
                setFisica(datoguia.verificada)
            }
            setLoading(false);
            
        }
    });
   }

  return (
    <>      
            <Tooltip title="Editar Guía">
                <a href={numeral} onClick={() => editar_guia(num_guia)}><i class="mdi mdi-pencil" aria-hidden="true"></i></a>
            </Tooltip>

             <Dialog open={openNuevaGuia} onClose={handleCloseNuevaGuia}>
                <DialogTitle>Modificar Guía</DialogTitle>
                <DialogContent>
                
                <div className='form-row'>
                    
                    <div className="form-group col-md-6 col-sm-6">
                        <label htmlFor="exampleInputUsername1">Nº de Guía</label>
                        <input name="num_guia" value={numGuia} type="text" className="form-control form-control-sm" disabled />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Fecha Asignación</label>
                        <input name="fecha" value={fechaAsignacion} onChange={event => setfechaAsignacion(event.target.value)} type="date" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Remitente</label>
                        <input name="remitente" value={Remitente} onChange={event => setMRemitente(event.target.value)} type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Destinatario</label>
                        <input name="destinatario" value={Destinatario} onChange={event => setMDestinatario(event.target.value)} type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Unidades</label>
                        <input name="unidades" value={Unidades} onChange={event => setMUnidades(event.target.value)} type="number" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6 col-sm-3 ">
                        <label htmlFor="exampleInputUsername1">Flete</label>
                        <input name="flete" value={Flete}  onChange={event => setMFlete(event.target.value)} type="number" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6 col-sm-3 ">
                        <label htmlFor="exampleInputUsername1">Direccion</label>
                        <input name="flete" value={Direccion}  onChange={event => setMDireccion(event.target.value)} type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6 col-sm-3 ">
                        <label htmlFor="exampleInputUsername1">Producto</label>
                        <input name="flete" value={Producto}  onChange={event => setMProducto(event.target.value)} type="text" className="form-control form-control-sm"  />
                    </div>
                    {/* <div className="form-group col-md-6 col-sm-2 ">
                        <label htmlFor="exampleInputUsername1">Flete PSE</label>
                        <input type='checkbox' name="flete" value={FletePSE}  onChange={event => setMFletePSE(event.target.value)} className="form-control form-control-sm"  />
                    </div> */}
                   <div className="form-group col-md-6 col-sm-2">
                        <FormLabel component="legend">Flete PSE</FormLabel>
                        <FormGroup>
                        <Checkbox
                            checked={FletePSE}
                            onChange={(event) => setMFletePSE(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            label="Flete PSE"
                            />
                           
                           </FormGroup>
                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                    <label htmlFor="selepoblaciones">Poblacion</label>
                         <select  className='form-control form-control-sm' data-live-search="true"  id="selepoblaciones" value={Poblacion}  onChange={event => setMPoblacion(event.target.value)} >
                            {SelListaPoblaciones}
                        </select> 

                        

                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                        <label htmlFor="exampleInputUsername1">Empresa</label>
                        <FormControl fullWidth>
                                        <Select
                                                labelId="select_reexpedidor"
                                                id="select_reexpedidor"
                                                value={Empresa}
                                                label="Empresa"
                                                onChange={event => setMEmpresa(event.target.value)}
                                                size="small"
                                            >
                                                {ListaEmpresas}
                                            </Select>
                                            </FormControl>
                    </div>

                </div>

                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseNuevaGuia} color="secondary">Cancelar</Button>
                <Button onClick={()=> GuardarGuia() }
                        disabled={numGuia === "" ||
                                 fechaAsignacion === "" ||
                                 Remitente === "" || 
                                 Destinatario === "" || 
                                 Unidades === "" || 
                                 Flete === "" || 
                                 Poblacion === "" || 
                                 Empresa === "" 
                                    ? "true" : ""}
                          
                >Guardar</Button>
                </DialogActions>
            </Dialog>
    </>
  )
}

export default Modificaguia