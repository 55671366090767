import React from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Usuario} from '../../../services/Usuario'
import { useState } from 'react';
import { useEffect } from 'react';
const Navbar = ({cerrar_sesion, handleOpenNuevaGuia}) => {
    const numeral = "#";
    const usuario = Usuario()

    const [OpenSideBard, setOpenSideBard] = useState(true)

    useEffect(() => {
        if (OpenSideBard===true)
           document.body.classList.remove('sidebar-icon-only');
        else
           document.body.classList.add('sidebar-icon-only');
    }, [OpenSideBard]);

  return (
    <>
        {/*  partial:partials/_navbar.html  */}
        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div className="navbar-menu-wrapper d-flex align-items-stretch">
            <button className="navbar-toggler navbar-toggler align-self-center" onClick={()=>setOpenSideBard(!OpenSideBard)} type="button" data-toggle="minimize">
              <span className="mdi mdi-chevron-double-left"></span>
            </button>
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
              <a className="navbar-brand brand-logo-mini" href="index.html"><img src="/img/logo_unirex_sm.png" alt="logo" className='logo_sm' /></a>
            </div>
           
            <ul className="navbar-nav navbar-nav-left">
                <li className="nav-item nav-logout d-none d-md-block mr-3" key={888888}>
                  <button type="button" className="btn btn-danger"
                                            onClick={handleOpenNuevaGuia}                                            
                                        >Nueva Guía</button>
                 </li>
            </ul>      

             <ul className="navbar-nav navbar-nav-right">
           
              <li className="nav-item nav-logout d-none d-md-block mr-3">
                  <strong>{usuario.nombre_usuario}</strong>
              </li>
              <li className="nav-item nav-profile dropdown show">
                <a className="nav-link" id="profileDropdown" href={numeral} data-toggle="dropdown" aria-expanded="true">
                    <div className="nav-profile-img">
                      {/* <img src="/img/face1.jpg" alt="image"  /> */}
                      <AccountCircleIcon />
                    </div>
                    <div className="nav-profile-text">
                      <span className="font-13 online-color"><i className="mdi mdi-chevron-down"></i></span>
                    </div>
                  </a>
                  
                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                    <a className="dropdown-item" href={numeral}>
                      <i className="mdi mdi-cached mr-2 text-success"></i> Registro Actividad </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href={numeral}  onClick={cerrar_sesion}>
                      <i className="mdi mdi-logout mr-2 text-primary"></i> Cerrar Sesion </a>
                  </div>


              </li>
            </ul> 

            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
              <span className="mdi mdi-menu"></span>
            </button>
          </div>
        </nav>
    </>
  )
}

export default Navbar