import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { MisRutas } from './routes';
import 'alertifyjs/build/css/alertify.css';


function App() {

  
  return (
    <>
      
      <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>      
      <BrowserRouter>
          <MisRutas />
      </BrowserRouter>
      </PersistGate>        
      </Provider>
    </>
  );
}

export default App;
