import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import QrCodeIcon from '@mui/icons-material/QrCode';
import { GetData } from '../../services/GetData';
import { PostData } from '../../services/PostData';
import { Usuario } from '../../services/Usuario';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Coleccion = () => {

    const [open, setOpen] = useState(false)
    const [openMultiple, setopenMultiple] = useState(false)
    const [openM, setopenM] = useState(false)
    const [errorGuia, setErrorGuia] = useState(false)
    const [msgError, setMsgError] = useState("")
    const [LoadingTbl, setLoadingTbl] = useState(false) 
    const [Loading, setLoading] = useState(false) 
    const [ListaTablaGuias, setListaTablaGuias] = useState([]) 
    const [ListaesEstados, setListaesEstados] = useState([]) 
    const [ListaSubNovedades, setListaSubNovedades] = useState([]) 
    const [ListaNovedades, setListaNovedades] = useState([]) 
    const [guia, setGuia] = useState("")
    const [guias, setGuias] = useState("")
    const [Estado, setEstado] = useState(1)
    const [Novedad, setidNovedad] = useState("")
    const [SubNovedad, setSubNovedad] = useState("")
    const [ListaGuias, setListaGuias] = useState([]) 
    const [TextListaEstados, setTextListaEstados] = useState([]) 
    const [TextListaNovedades, setTextListaNovedades] = useState([]) 
    const [TextListaSubNovedades, setTextListaSubNovedades] = useState([]) 
    const [sumaflete, setSumaFlete] = useState(0)
    const MySwal = withReactContent(Swal)
    const numeral = "#";
    const usuario = Usuario();
    


    const handleClose = () => {
        setOpen(false);
      };
    const handleCloseMultiple = () => {
        setopenMultiple(false);
      };
    const handleCloseM = () => {
        setopenM(false);
      };

    const agregaralista = () => {

    }  

    const cargar_listaestados = () => {
        
        GetData('guias/listaestados').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.estados;
              setTextListaEstados(listatipos);
              let listaselect = listatipos.map(item => <MenuItem value={item.id_estado_guia}>{item.nombre_estado_guia}</MenuItem>)
              setListaesEstados(listaselect)
          }else{
        
        }
      })
    }

    const cargar_novedades = () => {


        
        GetData('guias/listanovedades/').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.estados;
              setTextListaNovedades(listatipos);
              let listaselect = listatipos.map(item => <MenuItem value={item.id_novedad}>{item.nombre_novedad}</MenuItem>)
              setListaNovedades(listaselect)
          }else{
        
        }
      })
    }

    const cargar_subnovedades = () => {

        console.log("Novedad", Novedad)
        
        GetData('guias/listasubnovedades/' + Novedad).then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.estados;
              setTextListaSubNovedades(listatipos);
              let listaselect = listatipos.map(item => <MenuItem value={item.id_subnovedad}>{item.nombre_subnovedad}</MenuItem>)
              setListaSubNovedades(listaselect)
          }else{
        
        }
      })
    }

    

    const GuardarTodo = () => {

        
        if (Estado === "4"){
            if (parseInt(Novedad) === 0){
                MySwal.fire({
                    title: 'Guardar',
                    text: "Error Debe seleccionar la Novedad",
                    icon: 'error'
                    });
                return 0;
            }
            if (parseInt(SubNovedad) === 0){
                MySwal.fire({
                    title: 'Guardar',
                    text: "Error Debe seleccionar la SubNovedad",
                    icon: 'error'
                    });
                return 0;
            }            
            if (ListaGuias.length === 0){
                MySwal.fire({
                    title: 'Guardar',
                    text: "Error Debe agregar guias",
                    icon: 'error'
                    });
                return 0;
            }
        }


        let estado_ = "";
        
        TextListaEstados.forEach(est => {
            //console.log(est);
            if (est.id_estado_guia == Estado){
                estado_ = est.nombre_estado_guia;
            }
        });

        
        let Mensaje = "Esta segúro de guardar las guías con estado " + estado_;

        if (Estado === "4"){
            TextListaNovedades.forEach(est => {
                console.log(est);
                if (est.id_novedad === Novedad){
                    estado_ = est.nombre_novedad;
                }
            });

            Mensaje = "Esta segúro de guardar las guías con Novedad: " + estado_;
        }

        MySwal.fire({
            title: 'Guardar Estados ',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Si',
            denyButtonText: `No`,
            text: Mensaje
            }).then((result) => {
              if (result.isConfirmed) {
                GuardarEstadosGuias();
              }
            })
    }

    const GuardarEstadosGuias = () => {

        setLoadingTbl(true);


        if (ListaGuias.length > 0){

            /* ListaGuias.forEach((dato, index) => {
                
                let data = {
                    id_usuario : usuario.id_usuario,
                    num_guia: dato.num_guia,
                    id_estado: Estado,
                    id_novedad: Novedad,
                    id_subnovedad: SubNovedad
                }

                 PostData('/guias/guardarestadocoleccion', data).then ((result) =>{
                    let responseJSON = result;
                    if (responseJSON.error === false){
                        let datoguia = ListaGuias;
                        datoguia[index].nombre_estado_guia = responseJSON.estado;
                        setListaGuias(datoguia);
                        Llenartabla();
                    }

                });

            }); */

            let data = {
                id_usuario : usuario.id_usuario,
                id_estado: Estado,
                id_novedad: Novedad,
                id_subnovedad: SubNovedad,
                guias : ListaGuias
            }
            //setListaGuias([]);
           // setListaTablaGuias([])
            PostData('/guias/guardarestadocolecmult', data).then ((result) =>{
                let responseJSON = result;
                if (responseJSON.error === false){
                    let datoguia = responseJSON.listado;
                    const ListaGuiasActualizado = ListaGuias.map((guia) => {
                        const guiaEncontrada = datoguia.find((item) => item.num_guia === guia.num_guia);
                        if (guiaEncontrada) {
                            return {
                            ...guia,
                            nombre_estado_guia: guiaEncontrada.nombre_estado_guia,
                            };
                        }
                        return guia;
                    });

                    
                    MySwal.fire({
                        title: 'Guardar',
                        text: "Guías actualizadas con exito",
                        icon: 'success'
                        });

                    setListaGuias(ListaGuiasActualizado);
                    setLoadingTbl(false)
                    //Llenartabla();
                }else{
                    MySwal.fire({
                        title: 'Error al Guardar',
                        text: responseJSON.mensaje,
                        icon: 'error'
                        });
                }

            });

            

        }else{
          
        }


    }
    const delete_detalle_guia = (indice) => {
        
        if (indice >= 0 && indice < ListaGuias.length) {
            let ListaGuiasTemp = [...ListaGuias]; // Crear una copia del array original
            ListaGuiasTemp.splice(indice, 1); // Eliminar la guía del array temporal
            setListaGuias(ListaGuiasTemp); // Actualizar el estado con el nuevo array
          //  Llenartabla(); // Volver a llenar la tabla con los datos actualizados
            calculartotalflete(); // Calcular el total del flete con los nuevos datos
          } else {
            console.error("El índice es inválido");
          }
        
    }

    const Llenartabla = () => {
        let datos = ListaGuias;
        
        setLoadingTbl(true)
        let tabla = datos.map((row, index) => (
            <TableRow
            key={row.num_guia}
            hover role="checkbox" tabIndex={-1} 
            >
            <TableCell component="th" scope="row" align="left">
                {row.num_guia}
            </TableCell>
            <TableCell align="right">{row.fecha_asignacion}</TableCell>
            <TableCell align="center">{row.mun_nombre}</TableCell>
            <TableCell align="center">{row.unidades}</TableCell>
            <TableCell align="left">{row.remitente}</TableCell>
            <TableCell align="left">{row.destinatario}</TableCell>
            <TableCell align="right">$ {format_number(row.flete)}</TableCell>
            <TableCell align="center">{row.nombre_estado_guia}</TableCell>
            <TableCell align="left">{row.nombre_empresa}</TableCell>
            <TableCell align="center">
                <Tooltip title="Borrar Guía">
                    <a href={numeral} onClick={() => delete_detalle_guia(index)}><i class="mdi mdi-delete-forever" aria-hidden="true"></i></a>
                </Tooltip>    
            </TableCell>
            </TableRow>
        ))    

        let sumaf = 0;
        ListaGuias.forEach(dguia => {
            sumaf += parseFloat(dguia.flete);
        });

        setSumaFlete(sumaf);

        setListaTablaGuias(tabla);
        setLoadingTbl(false)

    }

    
    const format_number = (number) => {        
        const nf = new Intl.NumberFormat();
       return nf.format(number);  
      
     }

    const buscarguia = () =>{
        
        let index = ListaGuias.findIndex(function(item, i){
            if (item)
                return item.num_guia === guia.trim()   
        });
        if (index >= 0){
            setErrorGuia(true);
            setMsgError("La guía ya fue agregada")
            return 0;
        }


        GetData('guias/buscarguia/'+guia.trim()).then ((result) =>{
            let responseJSON = result;
            
            let datos = ListaGuias;
            const numeral = "#";

            if (responseJSON.error === false){
                
                const dato_guia = responseJSON.guia[0];
                //console.log(dato_guia);
                if (!dato_guia){
                    console.log("Guia no valida")
                    setErrorGuia(true);
                    setMsgError("La guía no existe")
                    return 0;
                }
                datos = [...datos, {
                    num_guia: dato_guia.num_guia,
                    fecha_asignacion: dato_guia.fecha_asignacion,
                    remitente: dato_guia.remitente,
                    unidades: dato_guia.unidades,
                    destinatario: dato_guia.destinatario,
                    mun_nombre: dato_guia.mun_nombre,
                    nombre_empresa: dato_guia.nombre_empresa,
                    nombre_estado_guia: dato_guia.nombre_estado_guia,                  
                    flete: dato_guia.flete                  
                }]
                
                setListaGuias(datos);
                
               // console.log(ListaPoblaciones.indexOf(dato_guia.mun_nombre))
                /* 
               let tabla = datos.map((row, index) => (
                <TableRow
                key={row.num_guia}
                hover role="checkbox" tabIndex={-1} 
                >
                <TableCell component="th" scope="row" align="left">
                    {row.num_guia}
                </TableCell>
                <TableCell align="right">{row.fecha_asignacion}</TableCell>
                <TableCell align="center">{row.mun_nombre}</TableCell>
                <TableCell align="center">{row.unidades}</TableCell>
                <TableCell align="left">{row.remitente}</TableCell>
                <TableCell align="left">{row.destinatario}</TableCell>
                <TableCell align="right">$ {format_number(row.flete)}</TableCell>
                <TableCell align="center">{row.nombre_estado_guia}</TableCell>
                <TableCell align="left">{row.nombre_empresa}</TableCell>
                <TableCell align="center">
                    <Tooltip title="Borrar Guía">
                        <a href={numeral} onClick={() => delete_detalle_guia(index)}><i class="mdi mdi-delete-forever" aria-hidden="true"></i></a>
                    </Tooltip>    
                </TableCell>
                </TableRow> 
            ))    
            */
           // setListaTablaGuias(tabla);
            
                
             //   setListaGuias(datos);
                setGuia("")
                
            }else{
               
          }
        })
    }

    const buscarmultiplesguias = () => {
        const data = {
            listado: guias,
        };
    
        setopenMultiple(false);
        setLoading(true);
    
        PostData('guias/buscarmultipleguia/', data).then((result) => {
            let responseJSON = result;
    
            if (responseJSON.error === false) {
                const nuevasGuias = responseJSON.guias;
                const nuevasGuiasFiltradas = nuevasGuias.filter(nuevaGuia => {
                    return !ListaGuias.some(datoExistente => datoExistente.num_guia === nuevaGuia.num_guia);
                });
    
                if (nuevasGuiasFiltradas.length > 0) {
                    const nuevasGuiasFormateadas = nuevasGuiasFiltradas.map(dato_guia => ({
                        num_guia: dato_guia.num_guia,
                        fecha_asignacion: dato_guia.fecha_asignacion,
                        remitente: dato_guia.remitente,
                        unidades: dato_guia.unidades,
                        destinatario: dato_guia.destinatario,
                        mun_nombre: dato_guia.mun_nombre,
                        nombre_empresa: dato_guia.nombre_empresa,
                        nombre_estado_guia: dato_guia.nombre_estado_guia,
                        flete: dato_guia.flete
                    }));
    
                    const nuevasDatos = [...ListaGuias, ...nuevasGuiasFormateadas];
                    setListaGuias(nuevasDatos);
                }
    
                setGuias("");
            }
            setLoading(false);
        });
    };

    const NuevoColeccion = () => {
           setListaGuias([]);
           setSumaFlete(0)
           setListaTablaGuias([]); 
    }

    const calculartotalflete = () => {
        let sumaf = 0;
        ListaGuias.forEach(dguia => {
            sumaf += parseFloat(dguia.flete);
        });
        setSumaFlete(sumaf);
    }

    useEffect(() => {
        if (errorGuia === true){
            setErrorGuia(false)
            setMsgError("")
        }else{
            
            calculartotalflete();
        }

    }, [guia])

    useEffect(() => {
        cargar_listaestados();

    }, [])

    useEffect(() => {
        console.log(Estado)
        if (parseInt(Estado) === 4)
            cargar_novedades();
        else{
            setSubNovedad(0)
            setidNovedad(0);

        }    

    }, [Estado])

    useEffect(() => {
        if (Novedad)
            cargar_subnovedades();

    }, [Novedad])
    

  return (
    <>
        { Loading && <div className="loading">Loading&#8230;</div> }
        <Box sx={{ '& > :not(style)': { m: 1 } }} className="btnflotante" >
                    <Fab color="primary" aria-label="add" onClick={()=>setOpen(true)} >
                        <QrCodeIcon />
                    </Fab>
                </Box>
     <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                            <h4 className="card-title">COLECCION v.2.5</h4>

                            <div className='form-row'>
                    
                            <div className="form-group col-md-2 col-sm-12">
                                    <FormControl fullWidth>
                                        <InputLabel id="select_estado">Estado guías</InputLabel>
                                        <Select
                                                labelId="select_estado"
                                                id="select_estado"
                                                value={Estado}
                                                label="Estados guías"
                                                onChange={event => {setEstado(event.target.value); console.log(event.target);}} 
                                                size="small"
                                            >
                                                {ListaesEstados}
                                            </Select>
                                            </FormControl>
                            </div>

                            <div className="form-group col-md-3 col-sm-12">
                                    <FormControl style={{display: Estado === "4" ? 'block' : 'none' }}>
                                        <InputLabel id="select_subestado">Novedades</InputLabel>
                                        <Select
                                                labelId="select_subestado"
                                                id="select_subestado"
                                                value={Novedad}
                                                label="Novedades"
                                                onChange={event => setidNovedad(event.target.value)} 
                                                size="small"
                                                sx={{ width: '100%' }} 
                                                
                                            >
                                                {ListaNovedades}
                                            </Select>
                                            </FormControl>
                            </div>

                            <div className="form-group col-md-3 col-sm-12">
                                    <FormControl fullWidth  style={{display: Estado === "4" && parseInt(Novedad) > 0 ? 'block' : 'none' }}>
                                        <InputLabel id="select_subnovedad">SubNovedades</InputLabel>
                                        <Select
                                                labelId="select_subnovedad"
                                                id="select_subnovedad"
                                                value={SubNovedad}
                                                label="SubNovedades"
                                                onChange={event => setSubNovedad(event.target.value)} 
                                                size="small"
                                                sx={{ width: '100%' }} 
                                            >
                                                {ListaSubNovedades}
                                            </Select>
                                            </FormControl>
                            </div>
                            <div className="form-group col-md-4 col-sm-12">
                                       
                                        <button type="button" class="btn btn-info ml-2"
                                            onClick={() => NuevoColeccion()}
                                            disabled={ListaGuias.length === 0  ? "true" : ""}  
                                        >Nuevo</button>
                                         <button type="button" class="btn btn-primary ml-2"
                                            onClick={() => GuardarTodo()}
                                            disabled={parseInt(Estado) === 0 ? "true" : ""}  
                                        >Guardar</button>
                                        
                            </div>
                            <div className="form-group col-md-2 col-sm-2">
                                    Total Flete:  <h3 className='font-weight-bold mb-0'>$ {format_number(sumaflete)}</h3>
                            </div>

                            

                            </div>
                            <div className='row'>
                                    <div className="col-md-2 col-sm-2">
                                        <button type="button" class="btn btn-success"
                                            onClick={() => setopenMultiple(true)}                                             
                                        >Agregar Multiple</button>
                                    </div>    
                            </div>
                      </div>

                      <div className='row'>
                            <div className="col-lg-12 grid-margin stretch-card">
                                    <div className="card">
                                    <div className="card-body">
                                        
                                        <div><span className="font-12 font-weight-semibold text-muted">Total Guias: <strong>{ListaGuias.length}</strong>
                                         </span></div>
                                                        
                                        <div className="table-responsive">
                                        
                                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                            <TableContainer sx={{ maxHeight: 340 }}>
                                                <Table size="small" aria-label="tabla de guías" >
                                                    <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nº Guía</TableCell>
                                                        <TableCell align="right">Fechas Asig.</TableCell>
                                                        <TableCell align="center">Población</TableCell>
                                                        <TableCell align="center">Unidades</TableCell>
                                                        <TableCell align="left">Remitente</TableCell>
                                                        <TableCell align="left">Destinatario</TableCell>
                                                        <TableCell align="right">Flete</TableCell>
                                                        <TableCell align="center">Estado</TableCell>
                                                        <TableCell align="left">Empresa</TableCell>
                                                        <TableCell align="center">Op</TableCell>
                                                    </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                   {ListaGuias.map((row, index) => (     
                                                     <TableRow
                                                        key={row.num_guia}
                                                        hover role="checkbox" tabIndex={-1} 
                                                        >
                                                        <TableCell component="th" scope="row" align="left">
                                                            {row.num_guia}
                                                        </TableCell>
                                                        <TableCell align="right">{row.fecha_asignacion}</TableCell>
                                                        <TableCell align="center">{row.mun_nombre}</TableCell>
                                                        <TableCell align="center">{row.unidades}</TableCell>
                                                        <TableCell align="left">{row.remitente}</TableCell>
                                                        <TableCell align="left">{row.destinatario}</TableCell>
                                                        <TableCell align="right">$ {format_number(row.flete)}</TableCell>
                                                        <TableCell align="center">{row.nombre_estado_guia}</TableCell>
                                                        <TableCell align="left">{row.nombre_empresa}</TableCell>
                                                        <TableCell align="center">
                                                            <Tooltip title="Borrar Guía">
                                                                <a href={numeral} onClick={() => delete_detalle_guia(index)}><i class="mdi mdi-delete-forever" aria-hidden="true"></i></a>
                                                            </Tooltip>    
                                                        </TableCell>
                                                        </TableRow>
                                                        ) )}
                                                    </TableBody>
                                                </Table>
                                                </TableContainer>
                                            </Paper>

                                        </div>
                                        <div className='mt-2'>
                                        { LoadingTbl && <LinearProgress /> }
                                        </div>
                                    </div>
                                    </div>
                                </div>
                        </div>  
                            

                    
                </div> {/* card */}
              </div> {/* col-md-12 */}
            </div>  {/* row */}
    

            <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Escanear Guía</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                   
                    </DialogContentText>
        

                    <TextField  id="guia" label="Guía" autoFocus fullWidth autoComplete='off'
                                                    error = {errorGuia}
                                                    helperText={msgError}
                                                    variant="standard" 
                                                    defaultValue={guia}   
                                                    value={guia}   
                                                    onChange={event => setGuia(event.target.value)} 
                                                    onKeyPress={(event) => {
                                                        
                                                        if (event.key === 'Enter')
                                                            buscarguia();
                                                    }}
                                                    />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={openMultiple} onClose={handleCloseMultiple}>
            <DialogTitle>Escanear Multiples Guías</DialogTitle>
            <DialogContent>              
             <TextField
                id="outlined-multiline-flexible"
                label="Listado de Guías"
                className='mt-2'
                multiline
                maxRows={5}
                value={guias}
                onChange={event => setGuias(event.target.value)} 
                
                fullWidth
              />

            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseMultiple() }>Cancelar</Button>
              <Button onClick={() => buscarmultiplesguias() } disabled={ guias !== "" ? false : true } >Agregar</Button>
            </DialogActions>
          </Dialog>
          
    </>
  )
}

export default Coleccion