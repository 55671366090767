import React, { Component } from "react";
import Dropzone from "react-dropzone";

import UploadService from "../services/upload-files.service";
import { PostData } from "../services/PostData";

export default class UploadFiles extends Component {
  constructor(props) {
    super(props);
   // console.log(props)
    this.upload = this.upload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.crearMunicipio = this.crearMunicipio.bind(this);

    this.state = {
      selectedFiles: undefined,
      currentFile: undefined,
      tipo_archivo: 0,
      id_usuario: 0,
      progress: 0,
      message: "",
      fileInfos: [],
      municipios: [],
      total_add: 0,
      total_no: 0
    };
  }

  componentDidMount() {
/*
    UploadService.getFiles().then((response) => {
      this.setState({
        fileInfos: response.data,
      });
    });
*/
  }

  crearMunicipio(munic, index) {
      let nombre = prompt("Verifique si el municipio esta bien escrito y de clic en aceptar para crear:", munic);
      if (nombre){
        const data = {
           nombre: nombre
        }
        PostData('/municipios/guardar', data).then ((result) =>{
          let responseJSON = result;
          if (responseJSON.error === false){                
              let municipios = [...this.state.municipios];
              municipios.splice(index, 1);
              this.setState({ municipios });
              alert('Creado con exito')
          }else{
              alert(responseJSON.mensaje)
          }
        })

      }
  }

  upload() {
    let currentFile = this.state.selectedFiles[0];
    let TipoArchivo = this.props.tipoArchivo;
    let IDusuario = this.props.id_usuario;
    console.log(IDusuario)
    this.setState({
      progress: 0,
      currentFile: currentFile,
      tipo_archivo : TipoArchivo,
      id_usuario : IDusuario
    });

    UploadService.upload(currentFile, TipoArchivo, IDusuario,  (event) => {
      this.setState({
        progress: Math.round((100 * event.loaded) / event.total),
      });
    })
      .then((response) => {
        console.log(response)
        this.setState({
          message: 'Proceso terminado',
          fileInfos: response.data.lista_guias,
          total_no: response.data.total_no,
          total_add: response.data.total_add,
          municipios: response.data.municipios
        });
        console.log("municipios ", response.data.municipios)

       // return UploadService.getFiles();
      })      
      .catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
        });
      });

    this.setState({
      selectedFiles: undefined,
    });
  }

  onDrop(files) {
    if (files.length > 0) {
      this.setState({ selectedFiles: files, tipo_archivo: this.props.tipoArchivo, id_usuario: this.props.IDusuario, progress: 0 });
    }
  }

  render() {
    const { selectedFiles, currentFile, progress, message, fileInfos,  municipios} = this.state;

    return (
      <div>
        {currentFile && (
          <div className="progress mb-3">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}

        <Dropzone onDrop={this.onDrop} multiple={false} 
            accept={{
              'Excel/xls': ['.xls', '.xlsx']
            }}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {selectedFiles && selectedFiles[0].name ? (
                  <div className="selected-file">
                    {selectedFiles && selectedFiles[0].name}
                  </div>
                ) : (
                  "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo"
                )}
              </div>

              <aside className="selected-file-wrapper mt-3 justify-content-center">
                <button
                  className="btn btn-primary"
                  disabled={!selectedFiles}
                  onClick={this.upload}
                >
                  Cargar archivo
                </button>
              </aside>
            </section>
          )}
        </Dropzone>

        <div className="alert alert-light" role="alert">
           Guias Procesadas: <strong>Agregadas:</strong> {this.state.total_add}, <strong>No Agregadas:</strong> {this.state.total_no} 
        </div>
        <div className="alert alert-light" role="alert">
           Municipios no encontrados:
           <div className="card">
           <ul className="list-group list-group-flush">
            {municipios.map((mun, index) => (
                <li key={index} className="list-group-item" >{mun} <a href="#" onClick={()=> this.crearMunicipio(mun, index)}>Crear</a> </li>
                ))}
            </ul>
            </div>
        </div>

        {fileInfos.length > 0 && (
          <div className="card">
            <div className="card-header">Listado de Guias</div>
            <ul className="list-group list-group-flush">
              {fileInfos.map((file, index) => (
                <li className="list-group-item" key={index}>
                  <span>{file.num_guia}</span> | <span>{file.estado}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}