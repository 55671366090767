import { Autocomplete, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography, useTheme } from '@mui/material'
import Box from '@mui/material/Box';
import QrCodeIcon from '@mui/icons-material/QrCode';
import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData'
import { PostData } from '../../services/PostData';
import { Usuario } from '../../services/Usuario';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link, useNavigate, useParams } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import { CheckBox, WindowSharp } from '@mui/icons-material';
import { URL_SERVICIOS } from '../../services/UrlWebServis';
import moment from 'moment';
import alertify from 'alertifyjs';

const NuevoDespachos3 = () => {
    
    const { idDespacho } = useParams();

    const [open, setOpen] = useState(false)
    const [openDetalle, setopenDetalle] = useState(false)
    const [openImpresion, setopenImpresion] = useState(false)
    const [openNuevaGuia, setopenNuevaGuia] = useState(false)
    const [HabilitarNuevaGuia, setHabilitarNuevaGuia] = useState(false)
    const [errorGuia, setErrorGuia] = useState(false)
    const [msgError, setMsgError] = useState("")
    const [numDespacho, setnumDespacho] = useState(0)
    const [fechaDepacho, setfechaDepacho] = useState("")
    const [HoraDepacho, setHoraDepacho] = useState("")
    const [CheckEntregadas, setCheckEntregadas] = useState(false)
    
    const [vehiculo, setVehiculo] = useState("")

    /*********  detalleguia  ***  */

    const [Dnum_guia, setDnum_guia] = useState("");
    const [Dfecha_asignacion, setDfecha_asignacion] = useState("");
    const [Dremitente, setDremitente] = useState("");
    const [Ddestinatario, setDdestinatario] = useState("");
    const [Dunidades, setDunidades] = useState("");
    const [Dflete, setDflete] = useState("");
    const [Dpoblacion, setDpoblacion] = useState("");
    const [Dempresa, setDempresa] = useState("");
    const [DDireccion, setDDireccion] = useState("");
    const [DProducto, setPProducto] = useState("");    
    const [Destadoguia, setDestadoguia] = useState("");
    const [ListaHistorialGuia, setListaHistorialGuia] = useState("");

    /********** nueva guia****/

    const [numGuia, setnumGuia] = useState("")
    const [fechaAsignacion, setfechaAsignacion] = useState("")
    const [Remitente, setRemitente] = useState("")
    const [Destinatario, setDestinatario] = useState("")
    const [Unidades, setUnidades] = useState("")
    const [Flete, setFlete] = useState("")
    const [Direccion, setDireccion] = useState("")
    const [Producto, setProducto] = useState("")
    const [Poblacion, setPoblacion] = useState("")
    const [Empresa, setEmpresa] = useState("")

    /********** nueva guia****/

    const [Listavehiculo, setListaVehiculo] = useState([])
    const [ListaEmpresas, setListaEmpresas] = useState([])
    const [SelListaPoblaciones, setSelListaPoblaciones] = useState([])
    const [ListaGenPoblaciones, setListaGenPoblaciones] = useState([])
    const [DespMunicipio, setDespMunicipio] = useState([])
    const [ListaConductores, setConductores] = useState([])
    const [ListaAuxiliares, setListaAuxiliares] = useState([])
    const [ListaReexpedidor, setListaReexpedidor] = useState([])
    const [ListaPoblaciones, setListaPoblaciones] = useState([])
    const [ListaTablaGuias, setListaTablaGuias] = useState([])
    const [conductor, setConductor] = useState("")
    const [auxiliar1, setAuxiliar1] = useState("")
    const [auxiliar2, setAuxiliar2] = useState(0)
    const [reexpedidor, setReexpedidor] = useState("")
    const [guia, setGuia] = useState("")
    const [sumaflete, setSumaFlete] = useState(0)
    const [sumafleteEnt, setsumafleteEnt] = useState(0)
    const [sumaUnidades, setSumaUnidades] = useState(0)
    const [LoadingTbl, setLoadingTbl] = useState(false) 
    const [Loading, setLoading] = useState(false) 
    const [PreLoading, setPreLoading] = useState(false) 
    const [ListaGuias, setListaGuias] = useState([]) 
    const [ListaGuiasDt, setListaGuiasDt] = useState([]) 
    const [ListaGuiasTabla, setListaGuiasTabla] = useState([]) 
    const [TotalEnBodega, setTotalEnBodega] = useState(0) 
    const [UnidadesBodega, setUnidadesBodega] = useState(0) 
    const [UnidadesCargadas, setUnidadesCargadas] = useState(0) 
    const [Despachador, setDespachador] = useState("") 
    const [NumTemp, setNumTemp] = useState("");
    const [UrlImpresion, setUrlImpresion] = useState(0);
    const numeral = "#";
    const usuario = Usuario();
    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();
    const theme = useTheme();

    const [personName, setPersonName] = useState([]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    function getStyles(name, personName, theme) {
      return {
        fontWeight:
          personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    }

    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        


         setDespMunicipio(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value
        ); 
      };

    useEffect(() => {
        const fecha = new Date();
        const ffor = moment(fecha).format('YYYY-MM-DD');
        setfechaDepacho(ffor)      
    }, [])

    useEffect(() => {
         if (idDespacho){            
            setnumDespacho(idDespacho)
            consultar_despacho()
            setPreLoading(true)
        } 
    }, [ListaGenPoblaciones])
    
    
    useEffect(() => {
        calcular_total();

        const contenedorTabla = document.getElementById('tablaguias');
        if (open){ //solo se mueve al final si se esta leyendo cógios
            contenedorTabla.scrollTop = contenedorTabla.scrollHeight;
        }

    }, [ListaGuias])

    useEffect(() => {
        //if (PreLoading === true){
            buscarGuiasMunicipios()
            guiasEnBodega();
            
        //}
    }, [DespMunicipio])
         

    const consultar_despacho = () => {
        setLoading(true);                
        GetData('/despachos/consultardespacho/'+idDespacho).then ((result) =>{
            let responseJSON = result;
                        
            if (responseJSON.error === false){
                if (responseJSON.haydatos === true){
                    const datosdespacho = responseJSON.datos[0];

                                 
                    setfechaDepacho(datosdespacho.fecha_despacho)
                    setHoraDepacho(datosdespacho.hora_despacho)
                    setConductor(datosdespacho.id_conductor)
                    setVehiculo(datosdespacho.id_vehiculo)
                    setAuxiliar1(datosdespacho.id_auxiliar1)
                    setAuxiliar2(datosdespacho.id_auxiliar2)
                    setReexpedidor(datosdespacho.id_reexpedidor)
                    setDespachador(datosdespacho.despachador)

                    consultar_detalle_despacho(idDespacho);

                    setUrlImpresion(`https://unirexsas.com/api_unirex/index.php/impresiones/despachohorizontal/`+idDespacho);
                }
                else{
                    setLoading(false)              
                }   
            }else{
            }
        })    
    }
    
    const consultar_detalle_despacho = (id_despacho) => {
        setLoadingTbl(true);
        GetData('/despachos/detalledespacho3/'+id_despacho).then ((result) =>{
            let responseJSON = result;
            
      
            if (responseJSON.error === false){

                const datos = responseJSON.guias;
                

                mostrar_poblaciones(datos);                

                setListaGuiasDt(datos);                                                  
                
                setGuia("");
                setLoadingTbl(false) 
                setLoading(false)
                
            }else{
            }
        })    
    }

    const mostrar_poblaciones = (datos) => {

        let listapoblaciones = [];
        let id_municipio = 0;
        
        datos.forEach((dguia, index) => {       
            if (dguia){             
                id_municipio = dguia.id_municipio;
                if (listapoblaciones.indexOf(id_municipio) === -1){
                    
                    listapoblaciones = [...listapoblaciones, id_municipio]                    
                }
            }
        });        

        const menusNombres = listapoblaciones.map((indice) => {
            const menu = ListaGenPoblaciones.find((item) => parseInt(item.id_municipio) === parseInt(indice));
            return menu ? menu.municipio : ''; // Devuelve el nombre si se encuentra, de lo contrario una cadena vacía
          });        
        
          setDespMunicipio(menusNombres);
          
    }

    const buscarGuiasMunicipios = () => {
        
        const idsSeleccionados = DespMunicipio.map((muniSel) => {
            const munici = ListaGenPoblaciones.find((item) => item.municipio === muniSel);
            return munici ? munici.id_municipio : null; // Retorna el ID si se encuentra, de lo contrario, null
        });
      
          // Filtra los IDs no nulos (por si acaso)
        const ListaMunic = idsSeleccionados.filter((id) => id !== null);
        
        const data = {
            municipios: ListaMunic
        }
        if (ListaMunic.length > 0){
            setLoadingTbl(true)
            PostData('guias/guiasmunicipiobodega/', { municipios: ListaMunic }).then((result) => {
                setLoadingTbl(false);
                
                let nuevasGuias = result.guias;
               /*  if (ListaGuiasDt.length > 0 && numDespacho > 0)
                    nuevasGuias = ListaGuiasDt.concat(nuevasGuias); */
    
                    // Filtrar guías existentes asociadas a los municipios seleccionados
                const guiasFiltradas = ListaGuias.filter((guia) => {
                    return nuevasGuias.some((nuevaGuia) => nuevaGuia.id_guia === guia.id_guia && ListaMunic.includes(nuevaGuia.id_municipio));
                });
    
                // Agregar guías nuevas que no estén ya en ListaGuias
                const guiasNuevas = nuevasGuias.filter((nuevaGuia) => {
                    return !guiasFiltradas.some((guiaFiltrada) => guiaFiltrada.id_guia === nuevaGuia.id_guia);
                });
    
                // Actualizar estado con las guías filtradas y las nuevas guías
               // setListaGuias([...guiasFiltradas, ...guiasNuevas]);
               const guiasCompletas = [...guiasFiltradas, ...guiasNuevas, ...ListaGuiasDt];
                                      
                // Ordenar las guías por el id_municipio
            //    guiasCompletas.sort((a, b) => a.id_municipio - b.id_municipio);

                const listaGuiasOrdenadas = [...guiasCompletas].sort((guiaA, guiaB) => {
                    if (guiaA.nombre_estado_guia < guiaB.nombre_estado_guia) {
                        return -1;
                    }
                    if (guiaA.nombre_estado_guia > guiaB.nombre_estado_guia) {
                        return 1;
                    }
                    return 0;
                });


                setListaGuias(listaGuiasOrdenadas);

            });
        }else{
            setListaGuias([])
        }
    }

    const BuscarDetalleGuia = (NumGuia) => {
        setopenDetalle(true)
        GetData('guias/buscarguia/'+NumGuia).then ((result) =>{
        
            if (result.error === false){
                
                const datoguia = result.guia[0];
                
                if (datoguia){
                    setDnum_guia(datoguia.num_guia);
                    setDfecha_asignacion(datoguia.fecha_asignacion);
                    setDremitente(datoguia.remitente);
                    setDdestinatario(datoguia.destinatario);
                    setDunidades(datoguia.unidades);
                    setDflete(datoguia.flete)
                    setDpoblacion(datoguia.mun_nombre)
                    setDempresa(datoguia.nombre_empresa)
                    setDDireccion(datoguia.direccion)
                    setPProducto(datoguia.tproducto)                    
                    setDestadoguia(datoguia.nombre_estado_guia)
                }else{
                    setDnum_guia("");
                    setDfecha_asignacion("");
                    setDremitente("");
                    setDdestinatario("");
                    setDunidades("");
                    setDflete("")
                    MySwal.fire({
                        title: 'Buscar guia',
                        text: "La guia " + NumGuia + " no se encontro en el sistema",
                        icon: 'error'
                        });
                }
                setLoading(false);
                listar_historial(NumGuia);
            }else{
                setLoading(false);
                setErrorGuia(true);
                setHabilitarNuevaGuia(true)
            }
        });
    }

    const formatofecha = (fecha) => {
        return moment(fecha).format('DD/MM/YYYY hh:mm A');
      }
    const colorestado = (estado) => {
                        if (estado === 'EN BODEGA')
                           return <span className='color_bodega'>{estado}</span> 
                        
                        if (estado === 'EN RUTA')
                            return <span className='color_enruta'>{estado}</span> 
                        
                        if (estado === 'ENTREGADA')
                           return <span  className='color_solucion'>{estado}</span> 
                        
                        if (estado === 'EN NOVEDAD')
                            return <span className='color_nvedadcli'>{estado}</span> 
                        
                        if (estado === 'EN REEXPEDICION')
                            return <span className='color_reexpeicion'>{estado}</span> 
                        
                        if (estado === 'CON SOLUCION')
                           return <span className='color_solcion'>{estado}</span> 
                        
                        if (estado === 'EN DEVOLUCION')
                           return <span className='color_devoucion'>{estado}</span>
                        
    }
    
    const listar_historial = async (NumGuia) => {
        setLoadingTbl(true)
        GetData('guias/historialguia/'+NumGuia).then ((result) =>{
                if (result.error === false){
                    let tabla = result.datos.map((item, index) => <tr key={index} className='cursor-auto'>
                      <td >                  
                            {formatofecha(item.fecha_hora)}                    
                      </td>
                      <td >
                          {item.descripcion}
                      </td>
                      <td >
                          {colorestado(item.estado)}
                      </td>
                      <td >
                          {item.novedad}
                      </td>
                      <td >
                          {item.subnovedad}
                      </td>
                      <td  align='center'>
                         {item.id_despacho}
                      </td>
                      <td  className="py-1">
                          {item.nombre_usuario}
                      </td>
                    </tr>)
                    setListaHistorialGuia(tabla)
                }   
                setLoadingTbl(false)
        });
        }


    const handleClose = () => {
        setOpen(false);
      };
    const handleCloseDetalle = () => {
         setopenDetalle(false);
      };
    const handleCloseImpresion = () => {
        setopenImpresion(false);
    };
    const handleCloseNuevaGuia = () => {
        setnumGuia("")
        setfechaAsignacion("")
        setRemitente("")
        setDestinatario("")
        setUnidades("")
        setFlete("")
        setDireccion("")
        setProducto("")
        setPoblacion("")
        setEmpresa("")
            setopenNuevaGuia(false)
    };

    const format_number = (number) => {        
        const nf = new Intl.NumberFormat();
       return nf.format(number);  
      
     }

   /*  useEffect(() => {        
        if (CheckEntregadas === true){
            const guiasFiltradas = ListaGuias.filter(guia => parseInt(guia.id_estado_guia) === 3 && parseFloat(guia.flete) > 0);
            setListaGuiasTabla(guiasFiltradas);
            setUrlImpresion(`https://unirexsas.com/api_unirex/index.php/impresiones/liquidardespacho/`+idDespacho);
        }else{
            setListaGuiasTabla(ListaGuias)
            setUrlImpresion(`https://unirexsas.com/api_unirex/index.php/impresiones/despacho/`+idDespacho);
        }

    }, [CheckEntregadas, ListaGuias]) */
    

    //---guardar ---

    const GuardarNuevaGuia = () => {
        let data = {
            id_usuario : usuario.id_usuario,
            num_desp_temp: NumTemp,
            num_despacho : numDespacho,
            num_guia: numGuia, 
            fecha: fechaAsignacion, 
            remitente: Remitente, 
            destinatario: Destinatario, 
            unidades: Unidades, 
            flete: Flete, 
            poblacion: Poblacion, 
            empresa: Empresa
        }

        setLoading(true);       
            
        PostData('/guias/guardarguia', data).then ((result) =>{
            let responseJSON = result;            
            if (responseJSON.error === false){                                
                
                setLoading(false);
                if (responseJSON.insertado === 1){
                    setGuia(numGuia)
                    agregarGuiaTabla()
//                    insertarDetalle();
                    setopenNuevaGuia(false);
                    setHabilitarNuevaGuia(false)
                    setnumGuia("")
                    setfechaAsignacion("")
                    setRemitente("")
                    setDestinatario("")
                    setUnidades("")
                    setFlete("")
                    setPoblacion("")
                    setEmpresa("")
                }

            }else{
                setLoading(false);
                MySwal.fire({
                    title: 'Guardar',
                    text: "Error al guardar la Guía",
                    icon: 'error'
                    });
          }
        })


    }

    const VerificarGuardar = () => {

        const todasEnRuta = ListaGuias.every((guia) => guia.nombre_estado_guia === "EN RUTA" && parseInt(guia.unidadesfis) === parseInt(guia.unidades));
    
        if (!todasEnRuta) {
            // Si alguna guía no cumple con las condiciones, mostrar mensaje de error
                Swal.fire({
                    title: 'Confirmar',
                    text: '¿Esta seguro de guardar el Despacho? No todas las guías están en ruta o faltan unidades físicas completas.',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Sí',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        GuardarDespacho();
                    }
                });  


                return;
        }

        Swal.fire({
            title: 'Confirmar',
            text: '¿Esta seguro de guardar el Despacho?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                GuardarDespacho();
            }
        });  
    }

    const GuardarDespacho = () => {

        //const lisguias = ListaGuias.map(guia => guia.num_guia).join(',');
        //filtra las guias para tomar solo las que no tenga esta en bodega y luego la separa por comas tomando solo numero de guia
        const lisguias = ListaGuias.filter(guia => parseInt(guia.id_estado_guia) !== 1)
                           .map(guia => guia.num_guia)
                           .join(',');

        if (lisguias.length === 0){ //si no se encontraron guias no se puede guardar el despacho
            MySwal.fire({
                title: 'Guardar',
                text: "Error - Debe haber cómo mínimo una guia escaneada, verifique  ",
                icon: 'error'
                });
            return 
        }   

        let data = {
            id_usuario : usuario.id_usuario,
            num_despacho : numDespacho,
            fecha: fechaDepacho,
            id_vehiculo: vehiculo,
            id_conductor: conductor,
            id_auxiliar1: auxiliar1,
            id_auxiliar2: auxiliar2,
            id_reexpedidor: reexpedidor,
            despachador: Despachador,
            guias: lisguias
        }        

        let urlGuardar = '/despachos/guardardespacho3';
        if (numDespacho > 0){ //si el despacho existe
            urlGuardar = '/despachos/actualizardespacho3'
        }

        setLoading(true);
        PostData(urlGuardar, data).then ((result) =>{
            let responseJSON = result;               
            if (responseJSON.error === false){                                
                setnumDespacho(responseJSON.num_despacho)
                //setUrlImpresion(`https://unirexsas.com/api_unirex/index.php/impresiones/despacho/`+responseJSON.num_despacho);
                setLoading(false);

                MySwal.fire({
                    title: 'Guardar',
                    text: "Despacho Guardado",
                    icon: 'success'
                    }
                  ).then(() => {
                        // Redirigir a otro sitio
                        navigate('/home/nuevodespachos3/' + responseJSON.num_despacho)
                    });

            }else{
                setLoading(false);
                MySwal.fire({
                    title: 'Guardar',
                    text: "Error al guardar el despacho",
                    icon: 'error'
                    });
          }
        }) 

    }


    ///--- guardar --

    const NuevoDespacho = () => {
          setnumDespacho(0)          
          setListaGuias([])
          setConductor("")
          setAuxiliar1("")
          setAuxiliar2(0)
          setDespachador("")
          setReexpedidor("")
          setSumaUnidades(0)
          setSumaFlete(0)
          setUnidadesCargadas(0)
          setUnidadesBodega(0)
          setVehiculo("")
          setDespMunicipio([])
       //   setListaGenPoblaciones([])
          navigate('/home/nuevodespachos3/')
          
    }

    /// -- calcular total

    const calcular_total = (datos) => {

        let sumafleteg = 0;
        let sumafletegentre = 0;
        let tunidades = 0;
        let listapoblaciones = [];
        let municipio = "";
        //console.log("...................................mostrar poblaciones");
        ListaGuias.forEach((dguia, index) => {       
            if (dguia){             
                sumafleteg += parseFloat(dguia.flete)
                municipio = dguia.mun_nombre.toUpperCase();
              //  console.log(index, "("+dguia.mun_nombre+')', ListaPoblaciones.indexOf(dguia.mun_nombre))
                if (listapoblaciones.indexOf(municipio) === -1){
                    listapoblaciones = [...listapoblaciones, municipio]
                    
                }
                
                tunidades += parseInt(dguia.unidades)
                
                setSumaUnidades(tunidades)
                if (parseInt(dguia.id_estado_guia) === 3 && parseFloat(dguia.flete) > 0)
                    sumafletegentre += parseFloat(dguia.flete)
            }
        });
        setListaPoblaciones(listapoblaciones)
        setSumaFlete(sumafleteg);
        setsumafleteEnt(sumafletegentre)
    }



    ///--- cargar Select--

    
    const cargar_vehiculos = () => {

        GetData('vehiculos/listar').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.vehiculos;
          
              let listaselect = listatipos.map(item => <MenuItem value={item.id_vehiculo}>{item.placa}</MenuItem>)
              setListaVehiculo(listaselect)
          }else{
        
        }
      })
    }
    
    const cargar_empresas = () => {

        GetData('empresas/listar/').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.empresas;
          
              let listaselect = listatipos.map(item => <MenuItem value={item.id_empresa}>{item.label}</MenuItem>)
              setListaEmpresas(listaselect)
          }else{
        
        }
      })
    }

    const cargar_poblaciones = () => {

        GetData('municipios/listarmunicipios/').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.poblaciones;
              setListaGenPoblaciones(listatipos);
              let listaselect = listatipos.map(item => <option value={item.id_municipio}>{item.municipio}</option>)

             
              setSelListaPoblaciones(listaselect)
              
          }else{
        
        }
      })
    }

    const cargar_conductores = () => {
        
        GetData('empleados/listar/1').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.empleados;

              let listaselect = listatipos.map(item => <MenuItem value={item.id_empleado}>{item.nombre_empleado}</MenuItem>)
              setConductores(listaselect)
          }else{
        
        }
      })
    }

    const cargar_auxiliares = () => {
        
        GetData('empleados/listar/2').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.empleados;
              let listaselect;              
              listaselect = listatipos.map(item => <MenuItem value={item.id_empleado}>{item.nombre_empleado}</MenuItem>)
              setListaAuxiliares(listaselect)
          }else{
        
        }
      })
    }

    const cargar_reexpedidores = () => {
        
        GetData('empleados/listar/3').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.empleados;
          
              let listaselect = listatipos.map(item => <MenuItem value={item.id_empleado}>{item.nombre_empleado}</MenuItem>)
              setListaReexpedidor(listaselect)
          }else{
        
        }
      })
    }

    const guiasEnBodega = () => {
        const guiasEnBodega = ListaGuias.filter(guiaItem => guiaItem.nombre_estado_guia === "EN BODEGA");

        let sumaunid = 0;
        
        guiasEnBodega.forEach(guia => {
            sumaunid += parseInt(guia.unidades);
        });
        
        setUnidadesBodega(sumaunid)

        let sumaunidcarg = 0;
        const guiasEnCargadas = ListaGuias.filter(guiaItem => guiaItem.nombre_estado_guia !== "EN BODEGA");
        guiasEnCargadas.forEach(guia => {
            sumaunidcarg += parseInt(guia.unidades);
        });
        setUnidadesCargadas(sumaunidcarg);

        setTotalEnBodega(guiasEnBodega.length);
    }

    const agregarGuiaTabla = () => {
        
        let numeroGuia = guia;

        if (guia.length > 12) {  //si tiene mas de 12 caracteres es una guia de coordinadora            
            numeroGuia = guia.substring(1, guia.length - 3);//se extrae el numero de guia del codigo de barras
        }
        
        const guiaLista = ListaGuias.find(guiaItem => guiaItem.num_guia === numeroGuia);
        
        if (guiaLista) {
            if (reexpedidor){               
                guiaLista.nombre_estado_guia = "EN REEXPEDICION";
                guiaLista.id_estado_guia = 5;
            }else{
                guiaLista.nombre_estado_guia = "EN RUTA";
                guiaLista.id_estado_guia = 2;
            }
            
            if (guiaLista.unidadesfis < guiaLista.unidades)
                guiaLista.unidadesfis = parseInt(guiaLista.unidadesfis) +  1;
            else {
                setMsgError("La unidades están completas");
                setErrorGuia(true);
                return;                
            }
    
            // Actualiza la lista de guías con la guía modificada
            setListaGuias([...ListaGuias]);
    
            // Ordena la lista de guías después de modificarla
            const listaGuiasOrdenadas = [...ListaGuias].sort((guiaA, guiaB) => {
                if (guiaA.nombre_estado_guia < guiaB.nombre_estado_guia) {
                    return -1;
                }
                if (guiaA.nombre_estado_guia > guiaB.nombre_estado_guia) {
                    return 1;
                }
                return 0;
            });
            
            // Actualiza el estado con la lista de guías ordenadas
            setListaGuias(listaGuiasOrdenadas);
            alertify.success('Guía '  + numeroGuia + ' agregada');
            setGuia("");
            guiasEnBodega();
            return;
        }else{
            setMsgError("Error la guía no se encontrada");
            setErrorGuia(true);
        }

        
            
    }        
    
    const delete_guia = (numguia) => {
        const dguia = ListaGuias.find(guiab => guiab.num_guia === numguia.trim());
        
        if (dguia) {
            setListaGuias(prevGuias => prevGuias.filter(item => item.num_guia !== numguia.trim()));
            guiasEnBodega();
        }   
    }

    const ExportarExcel = () => {
        
        window.open(URL_SERVICIOS + '/impresiones/exportarexceldespacho/' + numDespacho, '_blank').focus();

    }

    useEffect(() => {

        cargar_vehiculos();            
        cargar_conductores();
        cargar_auxiliares();
        cargar_reexpedidores();
        cargar_empresas();
        cargar_poblaciones();
    }, [])

    // --- fin cargar Select --
    
    return (
        <>
        { Loading && <div className="loading">Loading&#8230;</div> }
        <Box sx={{ '& > :not(style)': { m: 1 } }} className="btnflotante" style={{visibility: vehiculo !== "" && conductor !== "" || auxiliar1 ? 'visible' : 'hidden' }}>
                    <Fab color="primary" aria-label="add" onClick={()=>setOpen(true)} >
                        <QrCodeIcon />
                    </Fab>
                </Box>
         <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                                <div className='row mb-3'>
                                    <div className='col-6'>
                                        <h4 className="card-title">DESPACHO FISICO V1 Nº {numDespacho > 0 ? numDespacho : NumTemp}</h4>
                                    </div>
                                    <div className='col-6 text-right'>
                                        <button type="button" class="btn btn-info"
                                            onClick={() => NuevoDespacho()}
                                            disabled={numDespacho === 0 ? "true" : ""}  
                                        >Nuevo</button>
                                        <button type="button" class="ml-1 btn btn-primary" 
                                         disabled={vehiculo === "" || conductor === ""  || auxiliar1 === "" || ListaGuias.length === 0 ? "true" : "" || Despachador === ""}  
                                         onClick={() => VerificarGuardar()}> Guardar</button>
                                        <button type="button" class="ml-1 btn btn-warning" disabled={ListaGuias.length === 0 || numDespacho === 0 ? "true" : ""} onClick={ () => setopenImpresion(true)} > 
                                         Imprimir 
                                        </button>
                                        <button type="button" class="btn btn-dark ml-2"
                                            onClick={() => ExportarExcel()}
                                            disabled={numDespacho === 0 ? "true" : ""}  
                                        >Exportar</button>
                                        <button type="button" class="btn btn-dark ml-2"
                                            onClick={() => buscarGuiasMunicipios()}
                                            disabled={ListaGenPoblaciones.length === 0 ? "true" : ""}  
                                        >Actualizar</button>
                                       
                                    </div>
                                </div>
                                
                                <div className='form-row'>
                                
                                
                                    <div className="form-group col-2">
                                        <FormControl fullWidth>
                                        <TextField type="date"
                                            InputLabelProps={{ shrink: true, required: true }}
                                            id="fecha" label="Fecha" variant="outlined" size="small" value={fechaDepacho} 
                                            onChange={event => setfechaDepacho(event.target.value)} 
                                            defaultValue={fechaDepacho}  />
                                        </FormControl>
                                    </div>
                                    <div className="form-group col-2">
                                    <FormControl fullWidth>
                                        <InputLabel id="select_vehiculos">Vehículo</InputLabel>
                                        <Select
                                                labelId="select_vehiculos"
                                                id="select_vehiculos"
                                                value={vehiculo}
                                                label="Vehículo"
                                                onChange={event => setVehiculo(event.target.value)} 
                                                size="small"
                                            >
                                                {Listavehiculo}
                                            </Select>
                                            </FormControl>
                                    </div>
                                    <div className="form-group col-3">
                                        <FormControl fullWidth>
                                            <InputLabel id="select_conductor">Conductor</InputLabel>
                                            <Select
                                                    labelId="select_conductor"
                                                    id="select_conductor"
                                                    value={conductor}
                                                    label="Conductor"
                                                    onChange={event => setConductor(event.target.value)} 
                                                    size="small"
                                                >
                                                    {ListaConductores}
                                                </Select>
                                          </FormControl>
                                    </div>
                                    <div className="form-group col-3">
                                        <FormControl fullWidth>
                                        <InputLabel id="select_auxiliar1">Auxiliar 1</InputLabel>
                                        <Select
                                                labelId="select_auxiliar1"
                                                id="select_auxiliar1"
                                                value={auxiliar1}
                                                label="Auxiliar 1"
                                                onChange={event => setAuxiliar1(event.target.value)} 
                                                size="small"
                                            >
                                                {ListaAuxiliares}
                                            </Select>
                                            </FormControl>
                                    </div>
                                    <div className="form-group col-2">
                                        <FormControl fullWidth>
                                        <InputLabel id="select_auxiliar2">Auxiliar 2</InputLabel>
                                        <Select
                                                labelId="select_auxiliar2"
                                                id="select_auxiliar2"
                                                value={auxiliar2}
                                                label="Auxiliar 2"
                                                onChange={event => setAuxiliar2(event.target.value)} 
                                                size="small"
                                            >
                                                <MenuItem value={0}>Seleccion...</MenuItem> 
                                                {ListaAuxiliares}
                                            </Select>
                                            </FormControl>
                                    </div>        
                                    <div className="form-group col-2">
                                        <FormControl fullWidth>
                                        <InputLabel id="select_reexpedidor">Reexpedidor</InputLabel>
                                        <Select
                                                labelId="select_reexpedidor"
                                                id="select_reexpedidor"
                                                value={reexpedidor}
                                                label="Reexpedidor"
                                                onChange={event => setReexpedidor(event.target.value)} 
                                                size="small"
                                            >
                                                {ListaReexpedidor}
                                            </Select>
                                            </FormControl>
                                    </div>
                                    <div className="form-group col-4">
                                      <FormControl sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="demo-multiple-chip-label">Municipios</InputLabel>
                                        <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={DespMunicipio}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        onClick={() => setPreLoading(true)}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => {
                                                const menu = ListaGenPoblaciones.find((item) => item.municipio === value);
                                                if (menu) {
                                                    return (
                                                    <Chip key={menu.id_municipio} label={`${menu.municipio}`} />
                                                    );
                                                }
                                              
                                                return null;
                                                })}
                                            </Box>
                                              
                                        )}
                                        MenuProps={MenuProps}
                                        >
                                        {ListaGenPoblaciones.map((item) => (
                                            <MenuItem
                                            key={item.id_municipio}
                                            value={item.municipio}
                                            style={getStyles(item.nombre, personName, theme)}
                                            >
                                            {item.municipio}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>


                                   {/*  <FormControl fullWidth className='formato_poblaciones'>
                                    <InputLabel id="select_muncipios">Municipios</InputLabel>
                                            <Select
                                                labelId="select_muncipios"
                                                id="select_muncipios"
                                                value={DespMunicipio}
                                                label="Municipios"
                                                onChange={event => setDespMunicipio(event.target.value)} 
                                                size="small"
                                            >
                                                {ListaGenPoblaciones.map(item => (
                                                    <MenuItem key={item.id_municipio} value={item.id_municipio}>
                                                        {item.municipio}
                                                    </MenuItem>
                                                ))}
                                                
                                            </Select>
                                        </FormControl> */}
                                    </div>   
                                    <div className="form-group col-3">
                                            <TextField id="despachador" label="Despachador" variant="outlined"  
                                                    value={Despachador} 
                                                    onChange={event => setDespachador(event.target.value)} />

                                    </div>
                                </div>
                                <div className='form-row' >
                                     <div className='col-2'><strong>Total Guías:</strong> {ListaGuias.length}</div>   
                                     <div className='col-2'><strong>Total Unidades:</strong> {sumaUnidades}</div>   
                                     <div className='col-2'><strong>Unid. Faltantes:</strong> {sumaUnidades - UnidadesCargadas}</div>
                                     <div className='col-2'><strong>Uni. Cargadas:</strong> {UnidadesCargadas}</div>   
                                     <div className='col-2'><strong>T. Flete:</strong> $ {format_number(sumaflete)}</div>   
                                     <div className='col-2'><strong>T. Flete Entre.:</strong> $ {format_number(sumafleteEnt)}</div>   
                                     
                                        
                                     
                                </div>
                          </div>
    
                          <div className='row'>
                                <div className="col-lg-12 grid-margin stretch-card">
                                        <div className="card">
                                        <div className="card-body">
                                            
                                                            
                                            <div className="table-responsive" >
                                            <div className='mt-2'>
                                            { LoadingTbl && <LinearProgress /> }
                                            </div>
                                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                            <TableContainer sx={{ maxHeight: 340 }}  id="tablaguias">
                                                <Table size="small" aria-label="tabla de guías"  >
                                                    <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nº Guía</TableCell>
                                                        <TableCell align="right">Fechas Asig.</TableCell>
                                                        <TableCell align="center">Población</TableCell>
                                                        <TableCell align="center">Unidades</TableCell>
                                                        <TableCell align="center">Unid Fis</TableCell>
                                                        <TableCell align="left">Remitente</TableCell>
                                                        <TableCell align="left">Destinatario</TableCell>
                                                        <TableCell align="right">Flete</TableCell>
                                                        <TableCell align="center">Estado</TableCell>
                                                        <TableCell align="left">Empresa</TableCell>
                                                        <TableCell align="left">DT</TableCell>
                                                        <TableCell align="center">Op</TableCell>
                                                    </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    
                                                   { ListaGuias.map((row, index) => (
                                                        <TableRow
                                                        key={row.num_guia}
                                                        hover role="checkbox" tabIndex={-1} 
                                                        >
                                                        <TableCell component="th" scope="row" align="left">
                                                            <a href={numeral} onClick={() => BuscarDetalleGuia(row.num_guia)}>{row.num_guia}</a>
                                                        </TableCell>
                                                        <TableCell align="right">{row.fecha_asignacion}</TableCell>
                                                        <TableCell align="center">{row.mun_nombre}</TableCell>
                                                        <TableCell align="center" className={parseInt(row.unidades) !== parseInt(row.unidadesfis) ? 'color_enruta' : ''}>{row.unidades}</TableCell>
                                                        <TableCell align="center">{row.unidadesfis}</TableCell>
                                                        <TableCell align="left">{row.remitente}</TableCell>
                                                        <TableCell align="left">{row.destinatario}</TableCell>
                                                        <TableCell align="right">$ {format_number(row.flete)}</TableCell>
                                                        <TableCell align="center" className={parseInt(row.id_estado_guia) === 2 || parseInt(row.id_estado_guia) === 5 ? 'color_enruta' : ''}>{row.nombre_estado_guia}</TableCell>
                                                        <TableCell align="left">{row.nombre_empresa}</TableCell>
                                                        <TableCell align="left">
                                                            {row.detalle === 'd' && <img src='/img/camion.png' />}
                                                            </TableCell>
                                                        <TableCell align="center">
                                                            <Tooltip title="Borrar Guía">
                                                                <a href={numeral} onClick={() => delete_guia(row.num_guia)}><i class="mdi mdi-delete-forever" aria-hidden="true"></i></a>
                                                            </Tooltip>    
                                                        </TableCell>
                                                        </TableRow>
                                                    ))    
                                                   }                
                                                    </TableBody>
                                                </Table>
                                                </TableContainer>
                                            </Paper>


                                            </div>
                                           
                                        </div>
                                        </div>
                                    </div>
                            </div>  
                                
    
                        
                    </div> {/* card */}
                  </div> {/* col-md-12 */}
                </div>  {/* row */}

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Escanear Guía</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                   
                    </DialogContentText>
        

                    <TextField  id="guia" label="Guía" autoFocus fullWidth autoComplete='off'
                                                    error = {errorGuia}
                                                    helperText={msgError}
                                                    variant="standard" 
                                                    defaultValue={guia}   
                                                    value={guia}   
                                                    onChange={event => setGuia(event.target.value)} 
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter')
                                                            agregarGuiaTabla();
                                                        else{
                                                            setMsgError('') 
                                                            setErrorGuia(false)
                                                        }
                                                            //guardardetetalle();
                                                    }}
                                                    />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setopenNuevaGuia(true)} color="secondary"  style={{visibility: HabilitarNuevaGuia ? 'visible' : 'hidden' }}>Crear</Button>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    fullScreen
                    open={openImpresion}
                    onClose={handleCloseImpresion}
                    
                >
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseImpresion}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Button autoFocus color="inherit" onClick={handleCloseImpresion}>
                            Cerrar
                        </Button>
                    </Toolbar>
                    </AppBar>
                    <div>
                        <iframe src={UrlImpresion} className="inframemax">
                        </iframe> 
                    </div>
                </Dialog>

                <Dialog open={openNuevaGuia} onClose={handleCloseNuevaGuia}>
                <DialogTitle>Nueva Guía</DialogTitle>
                <DialogContent>
                
                <div className='form-row'>
                    
                    <div className="form-group col-md-6 col-sm-6">
                        <label htmlFor="exampleInputUsername1">Nº de Guía</label>
                        <input name="num_guia" value={numGuia} onChange={event => setnumGuia(event.target.value)}  type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Fecha Asignación</label>
                        <input name="fecha" value={fechaAsignacion} onChange={event => setfechaAsignacion(event.target.value)} type="date" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Remitente</label>
                        <input name="remitente" value={Remitente} onChange={event => setRemitente(event.target.value)} type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Destinatario</label>
                        <input name="destinatario" value={Destinatario} onChange={event => setDestinatario(event.target.value)} type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Unidades</label>
                        <input name="unidades" value={Unidades} onChange={event => setUnidades(event.target.value)} type="number" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6 col-sm-6 ">
                        <label htmlFor="exampleInputUsername1">Flete</label>
                        <input name="flete" value={Flete}  onChange={event => setFlete(event.target.value)} type="number" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-2 col-sm-6">
                                    <label htmlFor="estado">Dirección</label>
                                    <input name="estado" value={Direccion} type="text" className="form-control form-control-sm" disabled />
                                </div>
                    <div className="form-group col-md-2 col-sm-6">
                        <label htmlFor="estado">Producto</label>
                        <input name="estado" value={Producto} type="text" className="form-control form-control-sm" disabled />
                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                    <label htmlFor="selepoblaciones">Poblacion</label>
                         <select  className='form-control form-control-sm' data-live-search="true"  id="selepoblaciones" value={Poblacion}  onChange={event => setPoblacion(event.target.value)} >
                            {SelListaPoblaciones}
                        </select> 

                        

                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                        <label htmlFor="exampleInputUsername1">Empresa</label>
                        <FormControl fullWidth>
                                        <Select
                                                labelId="select_reexpedidor"
                                                id="select_reexpedidor"
                                                value={Empresa}
                                                label="Empresa"
                                                onChange={event => setEmpresa(event.target.value)}
                                                size="small"
                                            >
                                                {ListaEmpresas}
                                            </Select>
                                            </FormControl>
                    </div>

                </div>

                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseNuevaGuia} color="secondary">Cancelar</Button>
                <Button onClick={()=> GuardarNuevaGuia() }
                        disabled={numGuia === "" ||
                                 fechaAsignacion === "" ||
                                 Remitente === "" || 
                                 Destinatario === "" || 
                                 Unidades === "" || 
                                 Flete === "" || 
                                 Poblacion === "" || 
                                 Empresa === "" 
                                    ? "true" : ""}
                          
                >Guardar</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDetalle} onClose={handleCloseDetalle} maxWidth={'lg'}>
                    <DialogTitle>Detalle de Guía</DialogTitle>
                    <DialogContent>

                    <div className='form-row'>
                    
                        <div className="form-group col-md-2 col-sm-2">
                            <label htmlFor="exampleInputUsername1">Nº de Guía</label>
                            <input name="cedula" value={Dnum_guia} type="text" className="form-control form-control-sm" disabled />
                        </div>
                        <div className="form-group col-md-2  col-sm-2">
                            <label htmlFor="exampleInputUsername1">Fecha Asignación</label>
                            <input name="cedula" value={Dfecha_asignacion} type="text" className="form-control form-control-sm" disabled />
                        </div>
                        <div className="form-group col-md-4  col-sm-2">
                            <label htmlFor="exampleInputUsername1">Remitente</label>
                            <input name="cedula" value={Dremitente} type="text" className="form-control form-control-sm" disabled />
                        </div>
                        <div className="form-group col-md-4  col-sm-2">
                            <label htmlFor="exampleInputUsername1">Destinatario</label>
                            <input name="cedula" value={Ddestinatario} type="text" className="form-control form-control-sm" disabled />
                        </div>
                        <div className="form-group col-md-2  col-sm-2">
                            <label htmlFor="exampleInputUsername1">Unidades</label>
                            <input name="cedula" value={Dunidades} type="text" className="form-control form-control-sm" disabled />
                        </div>
                        <div className="form-group col-md-2 col-sm-6 ">
                            <label htmlFor="exampleInputUsername1">Flete</label>
                            <input name="cedula" value={`$ ${format_number(Dflete)}`} type="text" className="form-control form-control-sm" disabled />
                        </div>
                        <div className="form-group col-md-3 col-sm-6">
                            <label htmlFor="exampleInputUsername1">Población</label>
                            <input name="cedula" value={Dpoblacion} type="text" className="form-control form-control-sm" disabled />
                        </div>
                        <div className="form-group col-md-3 col-sm-6">
                            <label htmlFor="exampleInputUsername1">Empresa</label>
                            <input name="cedula" value={Dempresa} type="text" className="form-control form-control-sm" disabled />
                        </div>
                        <div className="form-group col-md-3 col-sm-6">
                            <label htmlFor="exampleInputUsername1">Dirección</label>
                            <input name="direccion" value={DDireccion} type="text" className="form-control form-control-sm" disabled />
                        </div>
                        <div className="form-group col-md-3 col-sm-6">
                            <label htmlFor="exampleInputUsername1">Producto</label>
                            <input name="producto" value={DProducto} type="text" className="form-control form-control-sm" disabled />
                        </div>                        
                        <div className="form-group col-md-2 col-sm-6">
                            <label htmlFor="estado">Estado Actual</label>
                            <input name="estado" value={Destadoguia} type="text" className="form-control form-control-sm" disabled />
                        </div>


                    </div>                                                    
                                                    
                    <div className="table-responsive">
                    <table className="table100 table-hover">
                                            <thead className='table100-head'>
                                            <tr>
                                                <th>Fecha Hora</th>
                                                <th>Obervación</th>
                                                <th>Estado</th>
                                                <th>Novedad</th>
                                                <th>SubNovedad</th>
                                                <th>Despacho</th>
                                                <th>Usuario</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {ListaHistorialGuia}
                                            </tbody>
                                        </table>
                        </div>                
                        <div className='mt-2'>
                            { LoadingTbl && <LinearProgress /> }
                        </div>
                    
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseDetalle}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
               
        </>
      )
}

export default NuevoDespachos3