import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LinearProgress from '@mui/material/LinearProgress';
import { PostData } from '../../services/PostData';
import { Tooltip } from '@mui/material';


const Empresas = () => {
    const numeral = "#";
    let DatosListaEmpresas = []
    const [IdEmpresa, setIdEmpresa] = useState(null);
    const [ListaEmpresas, setListaEmpresas] = useState([]);
    
    const [nit, setNit] = useState("");
    const [nombres, setNombres] = useState("");
    const [contacto, setContacto] = useState("");
    const [telefono, setTelefono] = useState("");
    const [correo, setCorreo] = useState("");
    const [etiqueta, setEtiqueda] = useState("");
    const [LoadingTbl, setLoadingTbl] = useState(false);  
    const [Loading, setLoading] = useState(false);  
    const MySwal = withReactContent(Swal)

  const listado_empresas = async () => {
        setLoadingTbl(true);

        const filtro = "null";

        GetData('empresas/listar/').then ((result) =>{
              
              if (result.error === false){
                const lista = result.empresas;
                DatosListaEmpresas = lista;
                
                if (!lista){                  
                  setLoadingTbl(false);
                  return 0;
                }

                let tabla = lista.map((item, index) => <tr key={index} onClick={() => llenarDatos(index)}>
                  <td className="py-1">
                      {item.nit_empresa}
                  </td>
                  <td className="py-1">
                      {item.nombre_empresa}
                  </td>
                  <td  className="py-1">
                      {item.telefonos_empresa}
                  </td>
                  <td  className="py-1">
                      {item.contacto_empresa}
                  </td>
                  <td  className="py-1">
                      {item.email_empresa}
                  </td>
                  
                  <td>
     
                      <a href={numeral} onClick={() => delete_empresa(item.id_empresa, item.nombre_empresa)}><i class="mdi mdi-delete-forever" aria-hidden="true"></i>Borrar</a>
                  </td>
                </tr>)
                setListaEmpresas(tabla);
                setLoadingTbl(false);
            }

        });
       
  }

  const llenarDatos = (index) => {
         const dato =  DatosListaEmpresas[index];
         
         setIdEmpresa(dato.id_empresa);
         setNit(dato.nit_empresa)
         setNombres(dato.nombre_empresa);
         setEtiqueda(dato.label);
         setTelefono(dato.telefonos_empresa)
         setCorreo(dato.email_empresa)
         setContacto(dato.contacto_empresa)

  }

  const limpiar = () => {
        setIdEmpresa(null);
        setNit("")
        setNombres("");
        setEtiqueda("");
        setTelefono("")
        setCorreo("");
        setContacto("");
  }
  const guardarUsuario = () => {
          let guardar = false
          
          
          if (nit && nombres && telefono &&  etiqueta && contacto )
              guardar = true;
          
          
          if (guardar === false){
              MySwal.fire({
                title: 'Guardar Persona',
                text: "Debe llenar todos los campos en el formulario",
                icon: 'error'
                })
                return 0;
          }

          let data = {
            id_empresa : IdEmpresa,
            nit,
            nombres,
            telefono,
            correo,
            etiqueta,
            contacto
          }


          setLoading(true)       

        PostData('/empresas/guardar', data).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){                
                setLoading(false) 
                MySwal.fire({
                  title: 'Guardar Empresa',
                  text: responseJSON.mensaje,
                  icon: 'success'
                  }
                )
                listado_empresas();
                limpiar();
            }else{
             // setError(true);
              setLoading(false) 
              MySwal.fire({
                title: 'Guardar Empresa',
                text: responseJSON.mensaje,
                icon: 'error'
                });
          }
        })
        
  }

  const borrar_empresa = (id_usuario) => {
    setLoadingTbl(true);

   
    GetData('empresas/delete/'+id_usuario).then ((result) =>{
          if (result.error === false){
            listado_empresas();
          }
    });
  }

  const delete_empresa = (id_empresa, nombre) => {
    MySwal.fire({
      title: 'Borrar Empresa ',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
      text: "Esta segúro de borrar la empresa: " + nombre,
      }).then((result) => {
        if (result.isConfirmed) {
             borrar_empresa(id_empresa);
        }
      })

  }

 
  useEffect(() => {
      
      listado_empresas();
  }, [])
  

  return (
    <>
     { Loading && <div className="loading">Loading&#8230;</div> }
    <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">{IdEmpresa === null ? 'CREAR EMPRESA' : 'ACTUALIZAR EMPRESA'}</h4>
                    <form className="forms-sample" autocomplete="off">
                    <div className='form-row'>

                      <div className="form-group col-3">
                        <label for="exampleInputUsername1">Nit</label>
                        <input name="nit"
                        onChange={event => setNit(event.target.value)} 
                        value={nit}
                        autocomplete="off"
                        type="text" className="form-control form-control-sm" id="nit" placeholder="Nit Empresa" required />
                      </div>
                      <div className="form-group col-6">
                        <label for="exampleInputUsername1">Nombre de la Empresa</label>
                        <input name="nombre_empresa"
                        onChange={event => setNombres(event.target.value)} 
                        value={nombres}
                        autocomplete="off"
                        type="text" className="form-control form-control-sm" id="nombre_empresa" placeholder="Nombre empresa" required />
                      </div>
                      <div className="form-group col-3">
                        <label for="etiqueta_empresa">Etiqueta</label> 
                        <input name="etiqueta_empresa"
                        onChange={event => setEtiqueda(event.target.value)} 
                        value={etiqueta}
                        autocomplete="off"
                        type="text" className="form-control form-control-sm" id="etiqueta_empresa" placeholder="Etiqueta empresa" required />
                      </div>
                      <div className="form-group col-6">
                        <label for="exampleInputUsername1">Teléfono</label>
                        <input name="telefono"
                        onChange={event => setTelefono(event.target.value)} 
                        value={telefono}
                        autocomplete="off"
                        type="text" className="form-control form-control-sm" id="telefono" placeholder="Teléfono" required />
                      </div>
               
                      <div className="form-group  col-6">
                        <label for="exampleInputEmail1">Correo Electrónico</label>
                        <input name="correo"
                        onChange={event => setCorreo(event.target.value)} 
                        value={correo}
                        autocomplete="off"
                         type="email" className="form-control form-control-sm" id="correo" placeholder="Correo electrónico" required />
                      </div>
                      <div className="form-group  col-5">
                        <label for="exampleInputEmail1">Contacto de Empresa</label>
                        <input name="email_empleado"
                        onChange={event => setContacto(event.target.value)} 
                        value={contacto}
                        autocomplete="off"
                         type="text" className="form-control form-control-sm" id="email_empleado" placeholder="Contácto de empresa" required />
                      </div>
                    </div>
                      <button type="button" className="btn btn-primary mr-2" 
                         disabled={nit === "" || nombres === ""  || telefono === "" || contacto === "" ? "true" : ""}  
                      onClick={guardarUsuario}> Guardar </button>
                      <button className="btn btn-light" onClick={limpiar}>Nuevo</button>
                    </form>

                  
                  </div>
                </div>
              </div>
      </div>
      <div className='row'>
           <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                      
                                       
                    <div className="table-responsive">
                      <table className="table100 table-hover">
                        <thead className='table100-head'>
                          <tr>
                            <th>Nit</th>
                            <th>Nombre Empresa</th>
                            <th>Teléfono</th>
                            <th>Dirección</th>
                            <th>Contacto</th>
                            <th>Opciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ListaEmpresas}
                        </tbody>
                      </table>
                    </div>
                    <div className='mt-2'>
                    { LoadingTbl && <LinearProgress /> }
                    </div>
                  </div>
                </div>
              </div>
      </div>
    </>
  )
}

export default Empresas;