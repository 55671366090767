
import { Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetData } from '../../services/GetData'
import { Usuario } from '../../services/Usuario';
import Moment from 'moment';
import 'moment/locale/es';
import { PostData } from '../../services/PostData';
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import { AG_GRID_LOCALE_ES } from '../../components/locale_es'

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const formatofecha = (fecha) => {
    const partesfecha = fecha.split("-");
    return  partesfecha[2] + "/" + partesfecha[1] + "/" + partesfecha[0]; //new Date(fecha).toLocaleDateString()
}

const ListarDespachos2 = () => {

    
    const numeral = "#";

    const {	id_tipos_usuario } = Usuario();

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    const today = new Date();
  /*   const FechaI = new Date(today.getFullYear(), today.getMonth(), 1);
    const FechaF = new Date(today.getFullYear(), today.getMonth() + 1, 0); */
    const FechaI = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const FechaF = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    const [ListadoDespachos, setListadoDespachos] = useState([])
    const [Listado, setListado] = useState([])
    const [LoadingTbl, setLoadingTbl] = useState(false) 
    const [Loading, setLoading] = useState(false) 
    const [Fecha_Inicial, setFecha_Inicial] = useState(formatDate(FechaI))
    const [Fecha_Final, setFecha_Final] = useState(formatDate(FechaF))

    const [NumDespacho, setNumDespacho] = useState("") 
    const [TotalDespachos, setTotalDespachos] = useState(0) 
    const [Columna, setColumna] = useState("") 
    const [FiltroColumna, setFiltroColumna] = useState("") 
    const navigate = useNavigate();

    const gridRef = useRef();


    const [columnDefs] = useState([
      { 
        field: 'id_despacho', 
        headerName: 'ID', 
        sortable: true, 
        filter: true, 
        checkboxSelection: true,
        headerCheckboxSelection: true, 
        width: 150,
        cellRenderer: (params) => {
          return <Link className='font-weight-bold' to={`/home/verdepachos/${params.data.id_despacho}`}>{params.data.id_despacho}</Link>
        } 
      },
      { field: 'fecha_despacho', headerName: 'Fecha', sortable: true, filter: true, width: 160 },
      { field: 'placa', headerName: 'Placa', sortable: true, filter: true, width: 180 },
      { field: 'cond', headerName: 'Conductor', sortable: true, filter: true, width: 250 },
      { field: 'aux1', headerName: 'Auxiliar 1', sortable: true, filter: true, width: 250 },
      { field: 'poblacion', headerName: 'Población', sortable: true, filter: true, width: 190 },
      { field: 'reex', headerName: 'Reexpedidor', sortable: true, filter: true, width: 180 },
      { field: 'numruta', headerName: 'En Ruta', sortable: true, filter: true, width: 120 },
      { field: 'usu', headerName: 'Usuario', sortable: true, filter: true, width: 210 }
    ]);
  
    

    useEffect(() => {
      const today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      const year = today.getFullYear();
      
      if (day < 10){
        day = '0' + day;
      }

      const fechaactual = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + day;


      
     // setFecha_Final(fechaactual)

      let fecha = new Date(fechaactual);
      fecha.setDate(fecha.getDate() - 4);
      
      const fechaFinal = fecha.getFullYear() + "-" + (fecha.getMonth() + 1) + "-" + fecha.getDate();
      //setFecha_Inicial(fechaFinal)

      

     /*  if(day<10)
         day='0'+day; //agrega cero si el menor de 10
      if(month<10)
         month='0'+month //agrega cero si el menor de 10
       */
      
      
     /*  setMes(parseInt(month));
      setAnio(year);
       */
     // listado_despachos(month, year);

    }, [])
    
      useEffect(() => {  
        listado_despachos(Fecha_Inicial, Fecha_Final);    
    }, [])  

    useEffect(() => {
        if (Columna === 0)
            setFiltroColumna("")
          
    }, [Columna])
    

    
        

    const diastrascurridas = (fecha_despacho) => {
      
      let hoy = Moment().format('YYYY-MM-DD');
      let fechad = Moment(fecha_despacho);
      const dias = fechad.diff(hoy, 'days'); 
      
      return parseInt(dias);    
    }

    const _handleKeyDown = (e) => {
      //  e.preventDefault();
        
            if (e.key === 'Enter') {          
                listado_despachos(Fecha_Inicial, Fecha_Final)
            }
        
        return 0;
    }

 
  
    const subtractTimeFromDate = (objDate, intHours) => {
        let fechahora = objDate + " " + intHours;
        let now = Moment(); //.format('MMM-DD-YYYY HH:mm:ss');
        let desp = Moment(objDate + " " + intHours)

        var duration = Moment.duration(now.diff(desp));
           
        return duration._data.hours;

    }  

    const IrA = (id_despacho, fecha_despacho, hora_despacho) => {
      const  dias = diastrascurridas(fecha_despacho)
      
      const horas = subtractTimeFromDate(fecha_despacho, hora_despacho);      

      navigate('/home/verdepachos/'+id_despacho)

      /* if (parseInt(id_tipos_usuario) === 1){  //si es administrador siempre puede editar el despacho
             navigate('/home/nuevodespachos/'+id_despacho)
      }else{
          if (id_tipos_usuario <= 3 && horas <= 3)
              navigate('/home/nuevodespachos/'+id_despacho)
          else
              navigate('/home/verdepachos/'+id_despacho)
      } */
    }
    

    const listado_despachos = async (fechainicial, fechafinal) => {
        setLoadingTbl(true);
        setLoading(true);

        const datos = {
            "fechainicial": fechainicial,
            "fechafinal": fechafinal,
            "despacho": NumDespacho
        }

        await PostData('despachos/listadodespachosfiltro/', datos).then ((result) =>{
              
              if (result.error === false){
                const lista = result.datos;
                setListado(result.datos)
             
                
                if (!lista){                  
                  setLoadingTbl(false);
                  return 0;
                }else{                  
                   
                    setTotalDespachos(lista.length);
                }

                let tabla = lista.map((item, index) => <tr key={index} onClick={()=>IrA(item.id_despacho, item.fecha_despacho, item.hora_despacho)}>
                  <td className="py-1">
                     <a href={`/home/verdepachos/${item.id_despacho}`}>{item.id_despacho}</a>
                  </td>
                  <td className="py-1">
                      {formatofecha(item.fecha_despacho)}
                  </td>
                  <td  className="py-1">
                      {item.placa}
                  </td>
                  <td  className="py-1">
                      {item.cond}
                  </td>
                  <td  className="py-1">
                      {item.aux1}
                  </td>
                  <td  className="py-1">
                      {item.poblacion}
                  </td>
                  <td>{item.reex}</td>
                  <td align='center'>{item.numruta}</td>
                  <td  className="py-1">
                      {item.usu}
                  </td>
                
                </tr>)
                setListadoDespachos(tabla);
                setLoadingTbl(false);
                setLoading(false)
            }

        });
       
  }

  const ConsultarDespachos  = () => {

        listado_despachos(Fecha_Inicial, Fecha_Final); 
  }
  const ConsultarDespachosFiltro  = () => {

        listado_despachos(Fecha_Inicial, Fecha_Final); 
  }


  
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_ES;
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      filter: true,
      resizable: true,
    };
  }, []);

  const clearFilter = useCallback(() => {
    console.log(gridRef.current.api.allColumnFilters)
    gridRef.current.api.setFilterModel(null);
  }, []);


  return (
    <>
      { Loading && <div className="loading">Loading&#8230;</div> }
    <div className='row'>
           <div className="col-lg-12 grid-margin stretch-card">
                <div className="card pl-1">
                  <div className='card-title'>Listado de Despachos</div>  
                  <div className="card-body">

                    <div className='row '>
                        <div className='col-md-2 mb-2'>
                        <FormControl fullWidth>
                            
                            <TextField id="fecha_inicial" label="Fecha Inicial" 
                                variant="outlined" 
                                size="small" 
                                type="date"            
                                              
                                value={Fecha_Inicial}                   
                                 onChange={event => setFecha_Inicial(event.target.value)}   
                                />
                          
                          </FormControl>
                        </div>  
                        <div className='col-md-2 mb-2'>
                        <FormControl fullWidth>
                            
                            <TextField id="fecha_final" label="Fecha Final" 
                                variant="outlined" 
                                size="small" 
                                type="date"                                                        
                                value={Fecha_Final}  
                                
                                 onChange={event => setFecha_Final(event.target.value)}      
                               /*  onKeyDown={_handleKeyDown} */
                                />
                           
                          </FormControl>
                        </div>  
                        <div className='col-md-2 mb-2'>
                        <FormControl fullWidth>
                            <TextField id="standard-basic" label="Nº Despacho" 
                                variant="outlined" 
                                size="small" 
                                onChange={event => setNumDespacho(event.target.value)}
                                onKeyDown={_handleKeyDown}
                                />
                          </FormControl>
                        </div> 
                        <div className='col-md-2 mb-2'>
                            <Button onClick={ConsultarDespachos} color="secondary">Consultar</Button>
                        </div> 
                       
                    </div> 
                                    
                    <div className="ag-theme-alpine" style={{height: '80vh', width: '100%'}}>
                     
                      <AgGridReact
                          ref={gridRef}
                          localeText={localeText}
                          rowData={Listado}
                          defaultColDef={defaultColDef}
                          rowSelection={'multiple'}
                          
                   /*        isRowSelectable={isRowSelectable}  */
                         /*  onSelectionChanged={onSelectionChanged} */
                          onRowSelected
                          columnDefs={columnDefs}
                        /*   onFirstDataRendered={onFirstDataRendered} */
                        >
                      </AgGridReact>
                  </div>
        
                    <div className='mt-2'>
                    { LoadingTbl && <LinearProgress /> }
                    </div>
                    <div><span className="font-12 font-weight-semibold text-muted">Total Despachos: <strong>{TotalDespachos}</strong></span></div>
                  </div>
                </div>
              </div>
      </div>
    </>
  )
}

export default ListarDespachos2