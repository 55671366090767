import { Box, Dialog, DialogActions, DialogContent, AppBar, IconButton, Button, DialogContentText, DialogTitle, Fab, FormControl, InputLabel, LinearProgress, ListItem, ListItemButton, ListItemText, MenuItem, Select, TextField, Tooltip, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { GetData } from "../../services/GetData";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Usuario } from "../../services/Usuario";
import { useParams } from "react-router-dom";

const VerLiquidacion = () => {
    const { idLiquidacion } = useParams();
    const MySwal = withReactContent(Swal)
    const [NumLiquidacion, setNumLiquidacion] = useState(0)
    const [openImpresion, setopenImpresion] = useState(false)
    const [open, setOpen] = useState(false)
    const [Loading, setLoading] = useState(false)
    const [TotalLiq, setTotalLiq] = useState(0)
    const [ListaGuias, setListaGuias] = useState([])
    const [Empleado, setEmpleado] = useState("")
    const [TipoEmpleado, setTipoEmpleado] = useState("")
    const [reexpedidor, setReexpedidor] = useState("")
    const [Persona, setPersona] = useState("")
    const [UrlImpresion, setUrlImpresion] = useState("");
    const usuario = Usuario();
    const numeral = "#";

    let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      const handleClose = () => {
        setOpen(false);
      };

    
    useEffect(() => {
        let sum = 0;
        ListaGuias.forEach(g => {
            if (parseInt(g.flete_pse) === 0)
                    sum += parseFloat(g.flete);
        });
        setTotalLiq(sum);

    }, [ListaGuias])
    
    useEffect(() => {
        setLoading(true)
        GetData('liquidacion/consultar/'+idLiquidacion).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){
            const liq = result.liquidacion[0];
            setListaGuias(result.guias)
            setTipoEmpleado(parseInt(liq.id_tipo_empleado))
            setEmpleado(liq.empleado)
            setPersona(liq.persona_entrega)
            setLoading(false)  
        }else{
               }
               setLoading(false)        
        })
    }, [])

   
    const Imprimir = () => {
        const newWindow = window.open('', '_blank');
        newWindow.location.href = `https://unirexsas.com/api_unirex/index.php/impresiones/liquidardespacho/${idLiquidacion}`;
    };

    return (
        <>
        { Loading && <div className="loading">Loading&#8230;</div> }

            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                            <h4 className="card-title">VER LIQUIDACION <span>N° {idLiquidacion}</span></h4>
                            <div className='form-row'>

                            </div>
                            <div className='form-row'>
                                    <div className="form-group col-3">
                                        <FormControl fullWidth>
                                        <InputLabel id="select_auxiliar1">Tipo Empleado</InputLabel>
                                        <Select
                                                labelId="select_auxiliar1"
                                                id="select_auxiliar1"
                                                value={TipoEmpleado}
                                                label="Auxiliar"                                                
                                                size="small"
                                                disabled
                                            >
                                                <MenuItem value={2}>Auxiliar</MenuItem>
                                                <MenuItem value={3}>Reexpedidor</MenuItem>
                                                <MenuItem value={4}>Conductor-Auxiliar</MenuItem>
                                            </Select>
                                            </FormControl>
                                    </div>
                                   
                                     <div className="form-group col-2">
                                            <TextField id="persona" label="Empleado" value={Empleado} variant="outlined" size="small" disabled />
                                    </div>
                                     <div className="form-group col-2">
                                            <TextField id="persona" label="Persona que Entrega" value={Persona}  variant="outlined" size="small" disabled />
                                    </div>
                                     <div className="form-group col-4">
                                      
                                      
                                            <button type="button" className="ml-1 btn btn-warning"  
                                            onClick={ () => Imprimir()}
                                            > Imprimir</button>
                                        
                                    </div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-12 grid-margin stretch-card">
                                <div className="form-group col-12">
                                    <div className="card">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className="form-group col-4">
                                                <h4 className="card-title">Guías Liquidadas</h4>                    
                                            </div>
                                            <div className="form-group col-3">
                                                <strong>N° Guías:</strong> {ListaGuias.length}                    
                                            </div>
                                            <div className="form-group col-5">
                                                <strong>T. Flete:</strong> <span className="textoliq">{dollarUS.format(TotalLiq)}</span>                
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                        
                                        <table className="table100 table-hover">
                                            <thead className='table100-head'>
                                            <tr key={45454}>
                                                <th>N° Guia</th>
                                                <th>Fecha</th>
                                                <th>Remitente</th>
                                                <th>Destinatario</th>
                                                <th>Municipio</th>
                                                <th>Empresa</th>
                                                <th>Flete</th>
                                            </tr>
                                            </thead>
                                            <tbody>                                           
                                                {ListaGuias.map(g => (
                                                   <tr>
                                                        <td  style={{ padding: '3px 0' }}><a href={`/home/detalleguia/${g.num_guia}`} alt='ver guia'>{g.num_guia}</a></td>
                                                        <td>{g.fecha_asignacion}</td>
                                                        <td>{g.remitente}</td>
                                                        <td>{g.destinatario}</td>
                                                        <td>{g.mun_nombre}</td>
                                                        <td>{g.nombre_empresa}</td>
                                                        <td>{dollarUS.format(g.flete)}</td>
                                                    </tr> 
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                       
                                    </div>
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                        </div>  
                </div> {/* card */}
              </div> {/* col-md-12 */}
            </div>  {/* row */}

            
        </>
    )
}
export default VerLiquidacion;