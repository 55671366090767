import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { GetData } from '../../services/GetData';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormGroup, FormLabel, LinearProgress, MenuItem, Select } from '@mui/material';
import moment from 'moment';
import { PostData } from '../../services/PostData';
import { Usuario } from '../../services/Usuario';

const DetalleGuia = () => {

  const { numguia } = useParams();

  const [ListaHistorialGuia, setListaHistorialGuia] = useState("");
  const [num_guia, setNum_guia] = useState("");
  const [fecha_asignacion, setFecha_Asignacion] = useState("");
  const [remitente, setRemitente] = useState("");
  const [destinatario, setDestinatario] = useState("");
  const [unidades, setUnidades] = useState("");
  const [flete, setFlete] = useState("");
  const [FletePSE, setMFletePSE] = useState(false)
  const [poblacion, setPoblacion] = useState("");
  const [estado, setEstado] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [direccion, setDireccion] = useState("");
  const [producto, setProducto] = useState("");
  const [fechaUpdate, setfechaUpdate] = useState("");
  const [Loading, setLoading] = useState(false);  
  const [LoadingTbl, setLoadingTbl] = useState(false);  
  const [Update, setUpdate] = useState(false);  
  const MySwal = withReactContent(Swal)
  const start = moment().add(-5, 'h');
  const usuario = Usuario();

    /********** nueva guia****/
      
    const [openNuevaGuia, setopenNuevaGuia] = useState(false)
    const [numGuia, setnumGuia] = useState("")
    const [fechaAsignacion, setfechaAsignacion] = useState("")
    const [Remitente, setMRemitente] = useState("")
    const [Destinatario, setMDestinatario] = useState("")
    const [Unidades, setMUnidades] = useState("")
    const [Flete, setMFlete] = useState("")
    const [Fisica, setFisica] = useState("")
    const [Poblacion, setMPoblacion] = useState("")
    const [Empresa, setMEmpresa] = useState("")
    const [MDireccion, setMDireccion] = useState("")
    const [MProducto, setMProducto] = useState("")
    const [Liquidacion, setLiquidacion] = useState("")
    const [SelListaPoblaciones, setSelListaPoblaciones] = useState([])
    const [ListaEmpresas, setListaEmpresas] = useState([])
    

    /********** nueva guia****/

    useEffect(() => {
        cargar_empresas();
        cargar_poblaciones();
    }, [])
    

    const cargar_empresas = () => {

        GetData('empresas/listar/').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.empresas;
          
              let listaselect = listatipos.map(item => <MenuItem value={item.id_empresa}>{item.label}</MenuItem>)
              setListaEmpresas(listaselect)
          }else{
        
        }
      })
    }
  
    const cargar_poblaciones = () => {
  
        GetData('despachos/listarpoblaciones/').then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const listatipos = responseJSON.poblaciones;
              
              let listaselect = listatipos.map(item => <option value={item.id_municipio}>{item.municipio}</option>)
  
             
              setSelListaPoblaciones(listaselect)
              
          }else{
        
        }
      })
    }
  
    const GuardarGuia = () => {
      let data = {
          id_usuario : usuario.id_usuario,
          num_guia: numGuia, 
          fecha: fechaAsignacion, 
          remitente: Remitente, 
          destinatario: Destinatario, 
          unidades: Unidades, 
          flete: Flete, 
          flete_pse: FletePSE ? '1' : '0', 
          poblacion: Poblacion, 
          direccion: MDireccion, 
          tproducto : MProducto, 
          empresa: Empresa
      }
  
      setopenNuevaGuia(false)
      setLoading(true);       
          
      PostData('/guias/updateguia', data).then ((result) =>{
          let responseJSON = result;            
          

          if (responseJSON.error === false){                                
              
              setLoading(false);
              if (responseJSON.insertado === 1){
                                 
                  setnumGuia("")
                  setfechaAsignacion("")
                  setMRemitente("")
                  setMDestinatario("")
                  setMUnidades("")
                  setMFlete("")
                  setMFletePSE(0)
                  setMPoblacion("")
                  setMEmpresa("")
                  setMDireccion("")
                  setMProducto("")
                  setUpdate(!Update)

                  MySwal.fire({
                    title: 'Actualizar Guía',
                    text: "Guía actualizada con exito",
                    icon: 'success'
                    });
              }
  
          }else{
              setLoading(false);
              MySwal.fire({
                  title: 'Guardar',
                  text: "Error al guardar la Guía",
                  icon: 'error'
                  });
        }
      })
  
  
  }

      
  const handleCloseNuevaGuia = () => {
            setopenNuevaGuia(false)
 };

 // console.log("fecha hora", start);

  const formatodespacho = (num) => {
        let numformato = "";
        if (num){
            let numero = new String(num);
            for (let i = numero.length; i<=4;i++){
                numformato += "0";
            }
            numformato += numero;
        }
        return numformato;
  }  

  const formatofecha = (fecha) => {
    return moment(fecha).format('DD/MM/YYYY hh:mm A');
  }

  const listar_historial = async () => {
    setLoadingTbl(true)
    GetData('guias/historialguia/'+numguia).then ((result) =>{
            if (result.error === false){
                let tabla = result.datos.map((item, index) => <tr key={index} className='cursor-auto'>
                  <td >                  
                        {formatofecha(item.fecha_hora)}                    
                  </td>
                  <td >
                      {item.descripcion}
                  </td>
                  <td >
                      {item.estado}
                  </td>
                  <td >
                      {item.novedad}
                  </td>
                  <td >
                      {item.subnovedad}
                  </td>
                  <td  align='center'>
                      { item.id_despacho ? 
                              <Link to={`/home/verdepachos/${item.id_despacho}`}>{formatodespacho(item.id_despacho)}</Link>
                              :
                              formatodespacho(item.id_despacho)
                       }  
                      
                  </td>
                  <td  className="py-1">
                      {item.nombre_usuario}
                  </td>
                </tr>)
                setListaHistorialGuia(tabla)
            }   
            setLoadingTbl(false)
    });
    }


  useEffect(() => {
    setLoading(true)
    GetData('guias/buscarguia/'+numguia).then ((result) =>{
        
        if (result.error === false){
            
            const datoguia = result.guia[0];
            if (datoguia){
                setNum_guia(datoguia.num_guia);
                setFecha_Asignacion(datoguia.fecha_asignacion);
                setRemitente(datoguia.remitente);
                setDestinatario(datoguia.destinatario);
                setUnidades(datoguia.unidades);
                setFlete(datoguia.flete)
                setMFletePSE(datoguia.flete_pse === '1' ? true : false )
                setPoblacion(datoguia.mun_nombre)
                setEmpresa(datoguia.nombre_empresa)
                setEstado(datoguia.nombre_estado_guia)
                setfechaUpdate(datoguia.fecha_update)
                setFisica(datoguia.verificada)
                setLiquidacion(datoguia.id_liquidacion)
                setnumGuia(datoguia.num_guia);
                setfechaAsignacion(datoguia.fecha_asignacion);
                setMRemitente(datoguia.remitente);
                setMDestinatario(datoguia.destinatario);
                setMUnidades(datoguia.unidades);
                setMFlete(datoguia.flete)
                setMPoblacion(datoguia.id_municipio)
                setMEmpresa(datoguia.id_empresa)
                setDireccion(datoguia.direccion)
                setProducto(datoguia.tproducto)
                setMDireccion(datoguia.direccion)
                setMProducto(datoguia.tproducto)
            }else{
                setNum_guia("");
                setFecha_Asignacion("");
                setRemitente("");
                setDestinatario("");
                setUnidades("");
                setFlete("")
                MySwal.fire({
                    title: 'Buscar guia',
                    text: "La guia " + numguia + " no se encontro en el sistema",
                    icon: 'error'
                    });
            }
            setLoading(false);
            listar_historial();
        }
    });
  
    
  }, [numguia, Update]);
  
  const format_number = (number) => {
     const nf = new Intl.NumberFormat();
    return nf.format(number);  
   
  }


  return (
    <>
    { Loading && <div className="loading">Loading&#8230;</div> }
     <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                        <div className='row'>
                            <div className='col-md-10'>
                                <h4 className="card-title">DETALLE DE GUIA</h4>
                            </div>    
                            <div className='col-md-2 text-right'>
                                <button type="button" class="ml-1 btn btn-danger"  
                                                onClick={ () => setopenNuevaGuia(true)} 
                                                style={{visibility: usuario.id_tipos_usuario === "1" ? 'visible' : 'hidden' }}
                                                > Modificar Guía</button>
                            </div>
                        </div>
                            <div className='form-row'>
                    
                                <div className="form-group col-md-2 col-sm-2">
                                    <label htmlFor="exampleInputUsername1">Nº de Guía</label>
                                    <input name="cedula" value={num_guia} type="text" className="form-control form-control-sm" disabled />
                                </div>
                                <div className="form-group col-md-2  col-sm-2">
                                    <label htmlFor="exampleInputUsername1">Fecha Asignación</label>
                                    <input name="cedula" value={fecha_asignacion} type="text" className="form-control form-control-sm" disabled />
                                </div>
                                <div className="form-group col-md-4  col-sm-2">
                                    <label htmlFor="exampleInputUsername1">Remitente</label>
                                    <input name="cedula" value={remitente} type="text" className="form-control form-control-sm" disabled />
                                </div>
                                <div className="form-group col-md-4  col-sm-2">
                                    <label htmlFor="exampleInputUsername1">Destinatario</label>
                                    <input name="cedula" value={destinatario} type="text" className="form-control form-control-sm" disabled />
                                </div>
                                <div className="form-group col-md-2  col-sm-2">
                                    <label htmlFor="exampleInputUsername1">Unidades</label>
                                    <input name="cedula" value={unidades} type="text" className="form-control form-control-sm" disabled />
                                </div>
                                <div className="form-group col-md-2 col-sm-4">
                                    <label htmlFor="exampleInputUsername1">Flete</label>
                                    <input name="cedula" value={`$ ${format_number(flete)}`} type="text" className="form-control form-control-sm" disabled />
                                </div>
                                <div className="form-group col-md-3 col-sm-6">
                                    <label htmlFor="exampleInputUsername1">Población</label>
                                    <input name="cedula" value={poblacion} type="text" className="form-control form-control-sm" disabled />
                                </div>
                                <div className="form-group col-md-3 col-sm-6">
                                    <label htmlFor="exampleInputUsername1">Empresa</label>
                                    <input name="cedula" value={empresa} type="text" className="form-control form-control-sm" disabled />
                                </div>
                                <div className="form-group col-md-2 col-sm-6">
                                    <label htmlFor="estado">Estado Actual</label>
                                    <input name="estado" value={estado} type="text" className="form-control form-control-sm" disabled />
                                </div>
                                <div className="form-group col-md-2 col-sm-6">
                                    <label htmlFor="estado">Dirección</label>
                                    <input name="estado" value={direccion} type="text" className="form-control form-control-sm" disabled />
                                </div>
                                <div className="form-group col-md-2 col-sm-6">
                                    <label htmlFor="estado">Producto</label>
                                    <input name="estado" value={producto} type="text" className="form-control form-control-sm" disabled />
                                </div>
                                


                            </div>
                            <div className='row'>
                                    <div className="form-group col-md-2 col-sm-6  padding-0">
                                        <span className='text-primary'> <strong >Usuario: </strong> {usuario.nombre_usuario}</span>
                                        
                                    </div>
                                    <div className="form-group col-md-2 col-sm-6  padding-0" style={{visibility: fechaUpdate ? 'visible' : 'hidden' }} >
                                            <span className='text-primary'> <strong>Fecha Modificación: </strong> {fechaUpdate}</span>
                                    </div>
                                    <div className="form-group col-md-2 col-sm-6  padding-0" style={{visibility: fechaUpdate ? 'visible' : 'hidden' }} >
                                            <span className='text-primary'> <strong>Guía fisica: </strong> {Fisica === 'S' ? (<>SI <i className="mdi mdi-check" style={{ color: '#00d284' }}></i></>) : (<>NO</>)} </span>
                                    </div>
                                    <div className="form-group col-md-2 col-sm-6  padding-0" style={{visibility: fechaUpdate ? 'visible' : 'hidden' }} >
                                        <span className='text-primary'><strong>N° Liquidación:</strong></span> <a href={`/home/verliquidacion/${Liquidacion}`}>{Liquidacion} </a> 
                                    </div>
                            </div>
                      </div>

                      <div className='row'>
                            <div className="col-lg-12 grid-margin stretch-card">
                                    <div className="card">
                                    <div className="card-body">
                                        
                                                        
                                        <div className="table-responsive">
                                        <table className="table100 table-hover">
                                            <thead className='table100-head'>
                                            <tr>
                                                <th>Fecha Hora</th>
                                                <th>Obervación</th>
                                                <th>Estado</th>
                                                <th>Novedad</th>
                                                <th>SubNovedad</th>
                                                <th>Despacho</th>
                                                <th>Usuario</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {ListaHistorialGuia}
                                            </tbody>
                                        </table>
                                        </div>
                                        <div className='mt-2'>
                                        { LoadingTbl && <LinearProgress /> }
                                        </div>
                                    </div>
                                    </div>
                                </div>
                        </div>  
                            

                    
                </div> {/* card */}
              </div> {/* col-md-12 */}
            </div>  {/* row */}


            {/* Modificar Guia */}      

          <Dialog open={openNuevaGuia} onClose={handleCloseNuevaGuia}>
                <DialogTitle>Modificar Guía</DialogTitle>
                <DialogContent>
                
                <div className='form-row'>
                    
                    <div className="form-group col-md-6 col-sm-6">
                        <label htmlFor="exampleInputUsername1">Nº de Guía</label>
                        <input name="num_guia" value={numGuia} type="text" className="form-control form-control-sm" disabled />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Fecha Asignación</label>
                        <input name="fecha" value={fechaAsignacion} onChange={event => setfechaAsignacion(event.target.value)} type="date" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Remitente</label>
                        <input name="remitente" value={Remitente} onChange={event => setMRemitente(event.target.value)} type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Destinatario</label>
                        <input name="destinatario" value={Destinatario} onChange={event => setMDestinatario(event.target.value)} type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Unidades</label>
                        <input name="unidades" value={Unidades} onChange={event => setMUnidades(event.target.value)} type="number" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Dirección</label>
                        <input name="destinatario" value={MDireccion} onChange={event => setMDireccion(event.target.value)} type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-3  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Producto</label>
                        <input name="unidades" value={MProducto} onChange={event => setMProducto(event.target.value)} type="text" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-5 col-sm-3 ">
                        <label htmlFor="exampleInputUsername1">Flete</label>
                        <input name="flete" value={Flete}  onChange={event => setMFlete(event.target.value)} type="number" className="form-control form-control-sm"  />
                    </div>
                    <div className="form-group col-md-3 col-sm-2">
                        <label htmlFor="exampleInputUsername1">Flete PSE</label>
                        <input class="form-check-input" type="checkbox" id="gridCheck1" style={{marginTop: '30px'}}  checked={FletePSE}
                            onChange={(event) => setMFletePSE(event.target.checked)}></input>
                        
                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                    <label htmlFor="selepoblaciones">Poblacion</label>
                         <select  className='form-control form-control-sm' data-live-search="true"  id="selepoblaciones" value={Poblacion}  onChange={event => setMPoblacion(event.target.value)} >
                            {SelListaPoblaciones}
                        </select> 

                        

                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                        <label htmlFor="exampleInputUsername1">Empresa</label>
                        <FormControl fullWidth>
                                        <Select
                                                labelId="select_reexpedidor"
                                                id="select_reexpedidor"
                                                value={Empresa}
                                                label="Empresa"
                                                onChange={event => setMEmpresa(event.target.value)}
                                                size="small"
                                            >
                                                {ListaEmpresas}
                                            </Select>
                                            </FormControl>
                    </div>

                </div>

                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseNuevaGuia} color="secondary">Cancelar</Button>
                <Button onClick={()=> GuardarGuia() }
                        disabled={numGuia === "" ||
                                 fechaAsignacion === "" ||
                                 Remitente === "" || 
                                 Destinatario === "" || 
                                 Unidades === "" || 
                                 Flete === "" || 
                                 Poblacion === "" || 
                                 Empresa === "" 
                                    ? "true" : ""}
                          
                >Guardar</Button>
                </DialogActions>
            </Dialog>
    </>
  )
}

export default DetalleGuia