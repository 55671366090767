
import { AppBar, Button, Dialog, FormControl, IconButton, LinearProgress, TextField, Toolbar, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom'
import { GetData } from '../../services/GetData'
import { Usuario } from '../../services/Usuario';
import Moment from 'moment';
import 'moment/locale/es';

const formatofecha = (fecha) => {
    const partesfecha = fecha.split("-");
    return  partesfecha[2] + "/" + partesfecha[1] + "/" + partesfecha[0]; //new Date(fecha).toLocaleDateString()
}

const ListarLiquidaciones = () => {
    
    const numeral = "#";

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    const today = new Date();

    /* const FechaI = new Date(today.getFullYear(), today.getMonth(), 1);
    const FechaF = new Date(today.getFullYear(), today.getMonth() + 1, 0); */
    const FechaI = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const FechaF = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    
    const [Fecha_Inicial, setFecha_Inicial] = useState(formatDate(FechaI))
    const [Fecha_Final, setFecha_Final] = useState(formatDate(FechaF))

    const [ListadoLiq, setListadoLiq] = useState([])
    const [Loading, setLoading] = useState(false) 

    const [TotalLiquidaciones, setTotalLiquidaciones] = useState(0) 
    const navigate = useNavigate();
    const [openImpresion, setopenImpresion] = useState(false)
    const [UrlImpresion, setUrlImpresion] = useState("");

    const handleCloseImpresion = () => {
      setopenImpresion(false);
  };

   
     useEffect(() => {  
        listado_despachos(Fecha_Inicial, Fecha_Final);    
    }, [Fecha_Inicial, Fecha_Final]) 
       

    const listado_despachos = async (fechainicial, fechafinal) => {
        setLoading(true);
        await GetData('liquidacion/'+fechainicial+'/'+fechafinal).then ((result) =>{             
              if (result.error === false){
                const lista = result.datos;                
                setListadoLiq(lista)
                setLoading(false)
                let sumalq = 0;
                lista.forEach(liq => {
                    sumalq+= parseFloat(liq.total_liquidacion)
                });
                setTotalLiquidaciones(sumalq);
            }
        });
       
  }

  const ConsultarLiquidaciones  = () => {
        listado_despachos(Fecha_Inicial, Fecha_Final); 
  }

  const Imprimir = (id) => {
      setUrlImpresion(`https://unirexsas.com/api_unirex/index.php/impresiones/liquidardespacho/`+id)  
      setopenImpresion(true)
  }

  const IrA = (id) => {    
      navigate('/home/verliquidacion/'+id)
  }

  const exportarExcel = (id) => {
        const newWindow = window.open('', '_blank');
        newWindow.location.href = `https://unirexsas.com/api_unirex/index.php/impresiones/exportxlsliquidacion/${id}`;    
  }

  return (
    <>
      { Loading && <div className="loading">Loading&#8230;</div> }
    <div className='row'>
           <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className='card-title'>Listado de Liquidaciones</div>  
                  <div className="card-body">

                    <div className='row'>
                        <div className='col-md-2 mb-2'>
                        <FormControl fullWidth>
                            
                            <TextField id="fecha_inicial" label="Fecha Inicial" 
                                variant="outlined" 
                                size="small" 
                                type="date"                                                          
                                value={Fecha_Inicial}                   
                                 onChange={event => setFecha_Inicial(event.target.value)}   
                                />
                          
                          </FormControl>
                        </div>  
                        <div className='col-md-2 mb-2'>
                        <FormControl fullWidth>
                            
                            <TextField id="fecha_final" label="Fecha Final" 
                                variant="outlined" 
                                size="small" 
                                type="date"                                                        
                                value={Fecha_Final}                                  
                                 onChange={event => setFecha_Final(event.target.value)}      
                               /*  onKeyDown={_handleKeyDown} */
                                />
                           
                          </FormControl>
                        </div>  
                        
                        <div className='col-md-2 mb-2'>
                            <Button onClick={ConsultarLiquidaciones} color="secondary">Consultar</Button>
                        </div>
                    </div> 
                                       
                    <div className="table-responsive">
                      <table className="table100 table-hover">
                        <thead className='table100-head'>
                          <tr>
                            <th>Nº Liquidacion</th>
                            <th>Fecha</th>
                            <th>Empleado</th>
                            <th>Persona Entrega</th>
                            <th>Total</th>
                            <th>Usuario</th>
                            <th></th>                                                      
                          </tr>
                        </thead>
                        <tbody>
                          {ListadoLiq.map((item, index) => (
                            <tr key={index} >
                                <td className="py-1">
                                    {item.id_liquidacionflete}
                                </td>
                                <td className="py-1">
                                    {formatofecha(item.fecha_liquidacion)}
                                </td>
                                <td  className="py-1">
                                    {item.empleado}
                                </td>
                                <td  className="py-1">
                                    {item.persona_entrega}
                                </td>
                                <td  className="py-1">
                                    {dollarUS.format(item.total_liquidacion)}
                                </td>
                                <td  className="py-1">
                                    {item.usuario}
                                </td>
                                <td  className="py-1">
                                          <Tooltip title="Ver Liquidacion">
                                            <a href={numeral} onClick={() => IrA(item.id_liquidacionflete)}>
                                               <i class="mdi mdi-eye" aria-hidden="true"></i>
                                            </a>
                                          </Tooltip>

                                           <Tooltip title="Ver PDF">
                                            <a href={numeral} onClick={() => Imprimir(item.id_liquidacionflete)}>
                                               <i class="mdi mdi-printer ml-2" aria-hidden="true"></i>
                                            </a>
                                            </Tooltip>
                                           
                                           <Tooltip title="Exportar Excel">
                                            <a href={numeral} onClick={() => exportarExcel(item.id_liquidacionflete)}>
                                               <i class="mdi mdi-file-excel ml-2" aria-hidden="true"></i>
                                            </a>
                                            </Tooltip>
                                           
                                </td>
                            </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className='mt-2'>
                    { Loading && <LinearProgress /> }
                    </div>
                    <div><span className="font-12 font-weight-semibold text-muted">Total Liquidaciones: <strong>{dollarUS.format(TotalLiquidaciones)}</strong></span></div>
                  </div>
                </div>
              </div>
      </div>

      <Dialog
                    fullScreen
                    open={openImpresion}
                    onClose={handleCloseImpresion}
                    
                >
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseImpresion}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Button autoFocus color="inherit" onClick={handleCloseImpresion}>
                            Cerrar
                        </Button>
                    </Toolbar>
                    </AppBar>
                    <div>
                        <iframe src={UrlImpresion} className="inframemax" style={{marginTop:'60px'}}>
                        </iframe> 
                    </div>
                </Dialog>
    </>
  )
}

export default ListarLiquidaciones