import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_REQUEST, LOGIN_FAILURE, SET_PERIOD_STATUS } from './actions';

const initialState = {
    isLoggedIn: false,
    user: null,
    error: null,
    isLoading: false,
    isPeriodOpen: false
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isLoggedIn: false
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload
            };
        case LOGOUT_REQUEST:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                user: null,
                error: action.payload,
                isLoggedIn: false
            };
        case SET_PERIOD_STATUS: // Agregar un nuevo case para actualizar el estado de isPeriodOpen
            return {
                ...state,
                isPeriodOpen: action.payload
            };            
        default:
            return state;
    }
};

export default authReducer;
