import {URL_SERVICIOS}  from './UrlWebServis';
import { Usuario } from './Usuario';


export function GetData(type){  
    const usuario = Usuario();
    const token = usuario.token;
    return new Promise((resolve, reject) => {        
        
        fetch(URL_SERVICIOS+type+'/'+token)  
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
           reject(error);
        })
    })    
}