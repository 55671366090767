import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LinearProgress from '@mui/material/LinearProgress';
import { PostData } from '../../services/PostData';
import moment from 'moment';

const Vehiculos = () => {
    const numeral = "#";
 //   moment.locale('es');
    let DatosListaVehiculos = []
    const [IdVehiculo, setIdVehiculo] = useState(null);
    const [ListaVehiculos, setListaVehiculos] = useState([]);
    
    const [placa, setPlaca] = useState("");
    const [carroceria, setCarroceria] = useState("");
    const [propietario, setPropietario] = useState("");
    const [tipo_combustible, setTipoCombustible] = useState("");
    const [telefono_propietario, setTelefonoPro] = useState("");
    const [modelo, setModelo] = useState("");
    const [capacidad, setCapacidad] = useState("");
    const [fecha_venc_soat, setFechaVenciSoat] = useState("");
    const [fecha_venc_tecno, setFechaVenciTecno] = useState("");
    const [LoadingTbl, setLoadingTbl] = useState(false);  
    const [Loading, setLoading] = useState(false);  
    const MySwal = withReactContent(Swal)

    const dias_faltantes = (fecha) => {
          let now = moment();
          var fechac = moment(fecha);
          var diasfaltantes = now.diff(fechac, 'days');
          
          if (diasfaltantes < 0)
              return <span className="badge text-bg-success">{diasfaltantes}</span>
          else
              return <span className="badge text-bg-danger">{diasfaltantes}</span>

    }
    
    const formato_fecha = (fecha) => {
        return moment(fecha).format("DD-MMM-YYYY");
    }

  useEffect(() => {
      
      listado_vehiculos();
  }, [])
  
  const listado_vehiculos = async () => {
        setLoadingTbl(true);

        

       await GetData('vehiculos/listar/').then ((result) =>{              
              if (result.error === false){
                const listado = result.vehiculos;
                DatosListaVehiculos = listado;
                let tabla = listado.map((item, index) => <tr key={index}  onClick={() => llenarDatos(index)}>
                  <td className="py-1">
                      {item.placa}
                  </td>
                  <td  className="py-1">
                      {item.propietario}
                  </td>
                  <td  className="py-1">
                      {item.telefono_propietario}
                  </td>                  
                  <td  className="py-1">
                      {item.tipo_combustible}
                  </td>
                  <td  className="py-1">
                      {item.modelo}
                  </td>

                  <td  className="py-1">
                      {formato_fecha(item.fecha_venc_soat)} {dias_faltantes(item.fecha_venc_soat)}
                  </td>
                  <td  className="py-1">
                      {formato_fecha(item.fecha_venc_tecno)} {dias_faltantes(item.fecha_venc_tecno)}
                  </td>
                  <td>{item.fecha_creado}</td>
                  <td>
       
                      <a href={numeral} onClick={() => delete_vehiculo(item.id_vehiculo, item.placa)}><i className="mdi mdi-delete-forever" aria-hidden="true"></i></a>
                  </td>
                </tr>)
                setListaVehiculos(tabla);
                setLoadingTbl(false);
                return true;
            }

        });
       
  }

  const llenarDatos = (index) => {
         const datos =  DatosListaVehiculos[index];
         console.log(datos)
         setIdVehiculo(datos.id_vehiculo);
         setPlaca(datos.placa)
         setCarroceria(datos.carroceria);
         setPropietario(datos.propietario)
         setTelefonoPro(datos.telefono_propietario)
         setTipoCombustible(datos.tipo_combustible)
         setModelo(datos.modelo)
         setCapacidad(datos.capacidad)
         setFechaVenciSoat(datos.fecha_venc_soat)
         setFechaVenciTecno(datos.fecha_venc_tecno) 
  }

  const limpiar = () => {
        setIdVehiculo(null)
        setPlaca("")
        setCarroceria("")
        setPropietario("")
        setTelefonoPro("")
        setTipoCombustible("")
        setModelo("")
        setCapacidad("")
        setFechaVenciSoat("")
        setFechaVenciTecno("")
  }
  const guardarUsuario = () => {
          let guardar = false
          
          
          if (placa && carroceria && propietario && telefono_propietario && tipo_combustible && modelo && capacidad && fecha_venc_soat && fecha_venc_tecno )
                  guardar = true;
          
          
          if (guardar === false){
              MySwal.fire({
                title: 'Guardar Persona',
                text: "Debe llenar todos los campos en el formulario",
                icon: 'error'
                })
                return 0;
          }

          let data = {
            id_vehiculo : IdVehiculo,
            placa,
            carroceria,
            propietario,
            telefono_propietario,
            tipo_combustible,
            modelo,
            capacidad,
            fecha_venc_soat,
            fecha_venc_tecno
          }

          setLoading(true)       

        PostData('/vehiculos/guardar', data).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){                
                setLoading(false) 
                MySwal.fire({
                  title: 'Guardar Vehículo',
                  text: responseJSON.mensaje,
                  icon: 'success'
                  }
                )
                listado_vehiculos();
                limpiar();
            }else{
             // setError(true);
              setLoading(false) 
              MySwal.fire({
                title: 'Guardar Vehículo',
                text: responseJSON.mensaje,
                icon: 'error'
                });
          }
        })
        
  }

  const borrar_vehiculo = (id_vehiculo) => {
    setLoadingTbl(true);

   
    GetData('vehiculos/delete/'+id_vehiculo).then ((result) =>{
          if (result.error === false){
              listado_vehiculos();
          }
    });
  }

  const delete_vehiculo = (id_vehiculo, nombre) => {
    MySwal.fire({
      title: 'Borrar Vehículo ',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Si',
      denyButtonText: `No`,
      text: "Esta segúro de borrar el vehículo de Placa: " + nombre,
      }).then((result) => {
        if (result.isConfirmed) {
          borrar_vehiculo(id_vehiculo);
        }
      })

  }

 


  return (
    <>
     { Loading && <div className="loading">Loading&#8230;</div> }
    <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">{IdVehiculo === null ? 'CREAR VEHICULO' : 'ACTUALIZAR VEHICULO'}</h4>
                    

                    <form className="forms-sample" autoComplete="off">
                    <div className='form-row'>
                     

                      <div className="form-group col-md-6">
                        <label htmlFor="exampleInputUsername1">Placa</label>
                        <input name="cedula"
                        onChange={event => setPlaca(event.target.value)} 
                        value={placa}
                        autoComplete="off"
                        type="text" className="form-control form-control-sm" id="cedula" placeholder="Placa" required />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="exampleInputUsername1">Propietario</label>
                        <input name="nombres_apellidos"
                        onChange={event => setPropietario(event.target.value)} 
                        value={propietario}
                        autoComplete="off"
                        type="text" className="form-control form-control-sm" id="exampleInputUsername1" placeholder="Propietario" required />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="exampleInputUsername1">Teléfono</label>
                        <input name="telefono"
                        onChange={event => setTelefonoPro(event.target.value)} 
                        value={telefono_propietario}
                        autoComplete="off"
                        type="text" className="form-control form-control-sm" id="exampleInputUsername1" placeholder="Teléfono Propietario" required />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="exampleInputUsername1">Carroceria</label>
                        <input name="direccion"
                        onChange={event => setCarroceria(event.target.value)} 
                        value={carroceria}
                        autoComplete="off"
                        type="text" className="form-control form-control-sm" id="exampleInputUsername1" placeholder="Carrocería" required />
                      </div>
                      <div className="form-group  col-6">
                        <label htmlFor="exampleInputEmail1">Tipo Combustible</label>
                        <input name="email_empleado"
                        onChange={event => setTipoCombustible(event.target.value)} 
                        value={tipo_combustible}
                        autoComplete="off"
                         type="email" className="form-control form-control-sm" id="email_empleado" placeholder="Tipo combustible" required />
                      </div>
                      <div className="form-group  col-md-6">
                        <label htmlFor="exampleInputEmail1">Modelo</label>
                        <input name="email_empleado"
                        onChange={event => setModelo(event.target.value)} 
                        value={modelo}
                        autoComplete="off"
                         type="text" className="form-control form-control-sm" id="email_empleado" placeholder="Modelo del Vehículo" required />
                      </div>
                      
                      <div className="form-group  col-md-4">
                        <label htmlFor="exampleInputEmail1">Capacidad</label>
                        <input name="licencia"
                        onChange={event => setCapacidad(event.target.value)} 
                        value={capacidad}
                        autoComplete="off"
                         type="text" className="form-control form-control-sm" id="licencia" placeholder="Capacidad" required />
                      </div>
                      <div className="form-group  col-md-4">
                        <label htmlFor="exampleInputEmail1">Fecha Venc. SOAT</label>
                        <input name="fvlicencia"
                        onChange={event => setFechaVenciSoat(event.target.value)} 
                        value={fecha_venc_soat}
                        autoComplete="off"
                         type="date" className="form-control form-control-sm" id="fvlicencia" placeholder="Fecha Vencimiento SOAT" required />
                      </div>
                      <div className="form-group  col-md-4">
                        <label htmlFor="exampleInputEmail1">Fecha Venb. Tecnomecanida</label>
                        <input name="fvlicencia"
                        onChange={event => setFechaVenciTecno(event.target.value)} 
                        value={fecha_venc_tecno}
                        autoComplete="off"
                         type="date" className="form-control form-control-sm" id="fvlicencia" placeholder="Fecha Vencimiento Tecnomecanica" required />
                      </div>
                  
                      </div>
                      <button type="button" className="btn btn-primary mr-2" 
                         disabled={placa === "" || carroceria === ""  || propietario === ""  || telefono_propietario === "" || tipo_combustible === "" 
                            || modelo === "" || capacidad === "" || fecha_venc_soat === "" || fecha_venc_tecno === ""
                                    ? true : false}  
                         
                      onClick={guardarUsuario}> Guardar </button>
                      <button className="btn btn-light" onClick={limpiar}>Nuevo</button>
                    </form>

                  </div>
                </div>
              </div>
      </div>
      <div className='row'>
           <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      
                      <table className="table100 table-hover">
                        <thead className='table100-head'>
                          <tr>
                            <th>Placa</th>
                            <th>Propietario</th>
                            <th>Telefono</th>
                            <th>Combustible</th>
                            <th>Modelo</th>
                            <th>Fecha Ven. Soat</th>
                            <th>Fecha Ven. Teno.</th>
                            <th>Fecha Registro</th>
                            <th>Opciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ListaVehiculos}
                        </tbody>
                      </table>
                    </div>
                    <div className='mt-2'>
                    { LoadingTbl && <LinearProgress /> }
                    </div>
                  </div>
                </div>
              </div>
      </div>
    </>
  )
}

export default Vehiculos;