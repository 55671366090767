import { Button, Dialog, DialogActions, FormControl, DialogContent, DialogTitle, MenuItem, Select, Tooltip, FormLabel, FormGroup, Checkbox } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { GetData } from '../services/GetData'
import { Usuario } from '../services/Usuario'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ModificarFleteGuia = ({DatosGuia, open, setOpenModFlete, agregarGuiaNoExistente}) => {

     /********** nueva guia****/
     const MySwal = withReactContent(Swal)
     const [openNuevaGuia, setopenNuevaGuia] = useState(false)
     const [numGuia, setnumGuia] = useState("")
     const [fechaAsignacion, setfechaAsignacion] = useState("")
     const [Remitente, setMRemitente] = useState("")
     const [Flete, setMFlete] = useState("")
     const [FletePSE, setMFletePSE] = useState(0)
     const [Loading, setLoading] = useState(false)
     const [Update, setUpdate] = useState(false);  
     const usuario = Usuario();
     const numeral = "#";     
  
     useEffect(() => {      
        setopenNuevaGuia(open);
        datos_guia()
    }, [open]);

     const GuardarGuia = () => {
        let data = {
            id_usuario : usuario.id_usuario,
            num_guia: numGuia, 
            flete: Flete, 
        }
    
        setopenNuevaGuia(false)
        setLoading(true);       
            
        PostData('/guias/updatefleteguia', data).then ((result) =>{
            let responseJSON = result;            
            
  
            if (responseJSON.error === false){                                
                
                setLoading(false);
                if (responseJSON.insertado === 1){
                                   
                    setnumGuia("")
                    setfechaAsignacion("")
                    setMRemitente("")
                    setMFlete("")
                    setMFletePSE(0)
                    setUpdate(!Update)
                    if (Flete > 0)
                        agregarGuiaNoExistente(numGuia,Flete,Remitente,FletePSE);

                    MySwal.fire({
                      title: 'Actualizar Guía',
                      text: "Guía actualizada con exito",
                      icon: 'success'
                      });

                      
                }
    
            }else{
                setLoading(false);
                MySwal.fire({
                    title: 'Guardar',
                    text: "Error al guardar la Guía",
                    icon: 'error'
                    });
          }
        })
    
    
    }
  
        
    const handleCloseNuevaGuia = () => {
              setopenNuevaGuia(false)
              setOpenModFlete(false)
   };

   const datos_guia = () => {
                setnumGuia(DatosGuia.num_guia);
                setfechaAsignacion(DatosGuia.fecha_asignacion);
                setMRemitente(DatosGuia.remitente);
                setMFlete(DatosGuia.flete) 
   }

  return (
    <>      

             <Dialog open={openNuevaGuia} onClose={handleCloseNuevaGuia}>
                <DialogTitle>Modificar Flete Guía</DialogTitle>
                <DialogContent>
                
                <div className='form-row'>
                    
                    <div className="form-group col-md-6 col-sm-6">
                        <label htmlFor="exampleInputUsername1">Nº de Guía</label>
                        <input name="num_guia" value={numGuia} type="text" className="form-control form-control-sm" disabled />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Fecha Asignación</label>
                        <input name="fecha" value={fechaAsignacion} onChange={event => setfechaAsignacion(event.target.value)} type="date" className="form-control form-control-sm"  disabled />
                    </div>
                    <div className="form-group col-md-6  col-sm-2">
                        <label htmlFor="exampleInputUsername1">Remitente</label>
                        <input name="remitente" value={Remitente} onChange={event => setMRemitente(event.target.value)} type="text" className="form-control form-control-sm" disabled />
                    </div>                    

                    <div className="form-group col-md-6 col-sm-6 ">
                        <label htmlFor="exampleInputUsername1">Flete</label>
                        <input name="flete" value={Flete}  onChange={event => setMFlete(event.target.value)} type="number" className="form-control form-control-sm"  />
                    </div>

                    <div className="form-group col-md-6 col-sm-2">
                        <FormLabel component="legend">Flete PSE</FormLabel>
                        <FormGroup>
                        <Checkbox
                            checked={FletePSE}
                            onChange={(event) => setMFletePSE(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            label="Flete PSE"
                            />
                           
                           </FormGroup>
                    </div>

                </div>

                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseNuevaGuia} color="secondary">Cancelar</Button>
                <Button onClick={()=> GuardarGuia() }
                        disabled={numGuia === "" ||
                                 fechaAsignacion === "" ||
                                 Remitente === "" || 
                                 Flete === ""
                                    ? "true" : ""}
                          
                >Guardar</Button>
                </DialogActions>
            </Dialog>
    </>
  )
}

export default ModificarFleteGuia