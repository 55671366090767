import Home from "./pages/home/home";
import Vehiculos from "./pages/home/vehiculos";
import Usuarios from "./pages/home/usuarios";
import Empleados from "./pages/home/empleados";
import Empresas from "./pages/home/empresas";
//import { RutasPrivadas } from "./services/RutasPrivadas";
//import Rutas from "./pages/home/Rutas";
//import Guias from "./pages/home/Guias";
import Coleccion from "./pages/home/coleccion";
import NuevoDespachos from "./pages/home/nuevodespachos";
import ImportarGuias from "./pages/home/importarguias";
import VerDespacho from "./pages/home/verdespacho";
import RutasHoy from "./pages/home/rutashoy";
import ListarDespachos from "./pages/home/listadespachos";
import ConsultarGuia2 from "./pages/home/consultarguia2";
import DetalleGuia from "./pages/home/detalleguia";
import GuiaFisica from "./pages/home/guiafisica";

import LiquidacionGuias from "./pages/home/liquidacionguias";
import ListarLiquidaciones from "./pages/home/listaliquidaciones";
import VerLiquidacion from "./pages/home/verliquidacion";
import TiposUsuario from "./pages/home/tiposusuario";
import NuevoDespachos2 from "./pages/home/nuevodespachos2";
import NuevoDespachos3 from "./pages/home/nuevodespachos3";
import ConsultarGuiaEntreg from "./pages/home/consultarguiaentreg";
import ListarDespachos2 from "./pages/home/listadespachos2";


export const rutasUsuario = [
    {
      title: 'Inicio',
      path:"/home",
      indice: 0,
      element: <Home />
    }, //0        
    {
        title: 'Tipos Usuarios',
        path:"tiposusuario",
        indice: 1,
        element: <TiposUsuario />
      },//1
    {
        title: 'Usuarios',
        path:"usuarios",
        indice: 2,
        element: <Usuarios />
      },//1
    {
        title: 'Vehículos',
        path:"vehiculos",
        indice: 3,
        element: <Vehiculos />
      },
    { //2
        title: 'Empleados',
        path:"empleados",
        indice: 4,
        element: <Empleados />
      },
    { //3
        title: 'Empresas',
        path:"empresas",
        indice: 5,
        element: <Empresas />
      },
    { //4
      title: 'Consultar guías',
      path:"consultarguias",
      indice: 6,
      element: <ConsultarGuia2 />
    }, //5
    { //4
      title: 'Consultar guías2',
      path:"consultarguias/:idEstado",
      indice: 6,
      element: <ConsultarGuia2 />
    }, //5
    {
      title: 'Colección',
      path:"coleccion",
      indice: 7,
      element: <Coleccion />
    }, //6
    {
      title: 'Rutas hoy',
      path:"rutashoy",
      indice: 8,
      element: <RutasHoy />
    }, //7
    {
      title: 'Nuevo Despacho',
      path:"nuevodespachos",
      indice: 9,
      element: <NuevoDespachos2 />
    }, //8
    {
      title: 'Editar Despacho',
      path:"nuevodespachos/:idDespacho",
      indice: 9,
      element: <NuevoDespachos2 />
    }, //8
    {
      title: 'Ver Despacho',
      path:"verdepachos/:idDespacho",
      indice: 9,
      element: <VerDespacho />
    }, //8
    {
      title: 'Despachos',
      path:"listadodespachos",
      indice: 10,
      element: <ListarDespachos2 />
    }, // 9
    {
      title: 'Importar Guias',
      path:"importarguias",
      indice: 11,
      element: <ImportarGuias />
    },    
    { //11
        title: 'Detalle Guías',
        path:"detalleguia/:numguia",
        indice: 6,
        element: <DetalleGuia />
    },
    { //12
        title: 'Guía Física',
        path:"verificarguias",
        indice: 12,
        element: <GuiaFisica />
    },
    { //13
        title: 'Liquidación Guías',
        path:"liquidacionguias",
        indice: 13,
        element: <LiquidacionGuias />
    },
    { //13
        title: 'Listado de Liquidación',
        path:"listadoliquidaciones",
        indice: 14,
        element: <ListarLiquidaciones />
    },
    { //13
        title: 'Ver Liquidacion',
        path:"verliquidacion/:idLiquidacion",
        indice: 14,
        element: <VerLiquidacion />
    }, //14
    {
      title: 'Despacho Físico',
      path:"nuevodespachos3",
      indice: 15,
      element: <NuevoDespachos3 />
    },
    { //16
      title: 'Ver Despacho 3',
      path:"nuevodespachos3/:idDespacho",
      indice: 15,
      element: <NuevoDespachos3 />
    }, //8
    { //16
      title: 'Consultar Guia',
      path:"consultarguiasentre/:idEstado",
      indice: 15,
      element: <ConsultarGuiaEntreg />
    }, //8
  ];

  