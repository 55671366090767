import { Chip } from '@mui/material';

const ChipPermisosUsuarios = ({ListaMenus, acceso}) => {
    if (!acceso) {
      return null;
    }
  
    return (
      <>
        {acceso.map((value) => {
          const parsedValue = parseInt(value, 10);
          if (!isNaN(parsedValue)) {
            const menu = ListaMenus.find((item) => parseInt(item.id_menu) === parsedValue);
            const nombreMenu = menu?.nombre || "";
            if (nombreMenu.trim() !== "") {
              return (
                <Chip key={`${menu?.id_menu}-${value}`} label={nombreMenu} />
              );
            }
          }
          return null; // Manejar el caso en que no se pueda convertir a número
        })}
      </>
    );
  }
  
export default ChipPermisosUsuarios