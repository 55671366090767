// third-party
import { combineReducers } from 'redux';
import authReducer from './reducers';

// project import
import menu from './menu';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ 
    auth: authReducer,
    menu 
});

export default reducers;
