import React from 'react'
import StatusGuias from './partials/status_guias'

const Home = () => {
  
  return (
    <>
       <StatusGuias />
       
    </>
  )
}

export default Home