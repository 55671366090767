import React from 'react'

const Footer = () => {
  return (
    <>
     {/*  partial:partials/_footer.html  */}
          <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © innovaciondigital.com 2022</span>
           {/*    <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Free <a href="https://www.bootstrapdash.com/" target="_blank">Bootstrap dashboard templates</a> from Bootstrapdash.com</span> */}
            </div>

            <div>
              {/* <span className="text-muted d-block text-center text-sm-left d-sm-inline-block"> Distributed By: <a href="https://themewagon.com/" target="_blank">Themewagon</a></span> */}
            </div>
          </footer>
    </>
  )
}

export default Footer