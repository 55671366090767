import React, {  useEffect, useState, useMemo, useRef, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GetData } from '../../services/GetData';
import { Link, useParams } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import { PostData } from '../../services/PostData';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import * as XLSX from "xlsx";
import { Usuario } from '../../services/Usuario';
import { AG_GRID_LOCALE_ES } from '../../components/locale_es'



const ConsultarGuiaEntreg = () => {

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const today = new Date();
  const FechaI = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const FechaF = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  
  const [ListaGuias, setListaGuias] = useState([]);
  const [ListaDatos, setListaDatos] = useState([]);
  const [ListaEliminados, setListaEliminados] = useState([]);
  const [ListadoGuias, setListadoGuias] = useState("");
  const [Loading, setLoading] = useState(false);  
  const [openMultiple, setopenMultiple] = useState(false);  
  const [Fecha_Inicial, setFecha_Inicial] = useState(formatDate(FechaI))
  const [Fecha_Final, setFecha_Final] = useState(formatDate(FechaF))
  
  const MySwal = withReactContent(Swal)
  const usuario = Usuario();
  
  const { idEstado } = useParams();
  const gridRef = useRef();
  /* const [rowData] = useState([
    {make: "Toyota", model: "Celica", price: 35000},
    {make: "Ford", model: "Mondeo", price: 32000},
    {make: "Porsche", model: "Boxster", price: 72000}
    ]); */

    const tipo_guia = (tipo) => {
        
        switch (tipo){
            case 'EN BODEGA':
                return   <span className='color_bodega'>{tipo}</span> 
            default:
                return tipo;    
        }
    }

    const constValueGetter = (params) => {
      return tipo_guia(params.data.nombre_estado_guia);
    };
    
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
  
    const currencyFormatter = (params) => {
      return dollarUS.format(params.value);
    };
  

    const [columnDefs] = useState([
        { field: 'num_guia', headerName: 'Num. Guía', sortable: true, filter: true, checkboxSelection: true,
        headerCheckboxSelection: true, cellRenderer: (params) => {
          return <Link className='font-weight-bold' to={`/home/detalleguia/${params.data.num_guia}`}>{params.data.num_guia} {params.data.verificada === "S" && <i className="mdi mdi-check" style={{ color: '#00d284' }}></i>}</Link>
        } },
        { field: 'fecha_asignacion', headerName: 'Fecha Asig', sortable: true, filter: true, width:120},
        { field: 'ultimoestado', headerName: 'Fecha Estado', sortable: true, filter: true, width:170},
        { field: 'remitente', headerName: 'Remitente', sortable: true, filter: true },
        { field: 'destinatario', headerName: 'Destinatario', sortable: true, filter: true },
        { field: 'mun_nombre', headerName: 'Municipio', sortable: true, filter: true },
        { field: 'nombre_empresa', headerName: 'Empresa', sortable: true, filter: true, width:160 },
        { field: 'nombre_estado_guia', headerName: 'Estado', sortable: true, filter: true, width:190, cellClassRules: {
              'color_bodega': params => params.value === 'EN BODEGA',
              'color_enruta': params => params.value === 'EN RUTA',
              'color_novedadcli': params => params.value === 'EN NOVEDAD',
              'color_solucion': params => params.value === 'ENTREGADA',
              'color_reexpedicion': params => params.value === 'EN REEXPEDICION',
              'color_solucion': params => params.value === 'CON SOLUCION',
              'color_devolucion': params => params.value === 'EN DEVOLUCION',
          } 
        },
        { field: 'unidades', headerName: 'Unidades', sortable: true, filter: true, width:110},
        { field: 'flete', headerName: 'Flete', sortable: true, filter: true, width:110, valueFormatter: currencyFormatter },
        { field: 'direccion', headerName: 'Dirección', sortable: true, filter: true, width:110},
        { field: 'tproducto', headerName: 'Producto', sortable: true, filter: true, width:110}
       
    ])

   const listar_guias = () => {
        setLoading(true)
        setListaGuias("")

        let filtro = "null";
        if (idEstado){
            filtro = idEstado;
        }

        GetData('guias/listadoentre/'+filtro+'/'+Fecha_Inicial+'/'+Fecha_Final).then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const lista = responseJSON.datos;
              let datos = [];
              let municipio = "";
              lista.forEach((guia, index) => {
              
                municipio = guia.mun_nombre;
                
                datos = [...datos, {
                  num_guia: guia.num_guia,
                  fecha_asignacion: guia.fecha_asignacion,
                  ultimoestado: guia.fecha_update,   
                  remitente: guia.remitente,
                  destinatario: guia.destinatario,
                  mun_nombre: municipio,
                  nombre_empresa: guia.label,
                  nombre_estado_guia: guia.nombre_estado_guia,                                
                  unidades: guia.unidades,                  
                  flete: guia.flete,   
                  direccion: guia.direccion,   
                  tproducto: guia.tproducto,   
                  verificada: guia.verificada               
              }]
           
              });        
              
              setListaGuias(datos)
              setLoading(false)
          }else{
        
        }
      })
    }

    const ConsultarMultiplesGuias = () => {
      


      const data = {
        listado : ListadoGuias,
      }

      setopenMultiple(false);
      setLoading(true)
        PostData('guias/listado/',data).then ((result) =>{
          let responseJSON = result;
          
    
          if (responseJSON.error === false){
              const lista = responseJSON.datos;
              let datos = [];
              let municipio = "";
              lista.forEach((guia, index) => {
              
                municipio = guia.mun_nombre;
                
                datos = [...datos, {
                  id: index,
                  num_guia: guia.num_guia,
                  fecha_asignacion: guia.fecha_asignacion,
                  ultimoestado: guia.fecha_update,                  
                  remitente: guia.remitente,
                  destinatario: guia.destinatario,
                  mun_nombre: municipio,
                  nombre_empresa: guia.label,
                  nombre_estado_guia: guia.nombre_estado_guia,
                  unidades: guia.unidades,   
                  flete: guia.flete,       
                  direccion: guia.direccion,   
                  tproducto: guia.tproducto,   
                  verificada: guia.verificada        
              }]
           
              });        
              
              setListaGuias(datos)
              setLoading(false)
              
          }else{
        
        }
      })
    }

    const handleClose = () => {
      setopenMultiple(false);
    };

    const LimpiarLista = () => {
      setListadoGuias("")      
    } 

    
    const CerrarLimpiarLista = () => {
      setListadoGuias("")
      setopenMultiple(false);
    }  

    useEffect(() => {
      listar_guias();
    }, [])
    
    const defaultColDef = useMemo(() => {
      return {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true,
      };
    }, []);

    const clearFilter = useCallback(() => {
      console.log(gridRef.current.api.allColumnFilters)
      gridRef.current.api.setFilterModel(null);
    }, []);

   /*  const isRowSelectable = useMemo(() => {
      return (params) => {
        console.log(params.data.num_guia);
        return true;
      };
    }, []); */

    const AbrirBusquedaMultiple = () => {
      setopenMultiple(true)
    }

    const btmExportar = () => {
      let rowData = [];
        gridRef.current.api.forEachNodeAfterFilter(node => {
              rowData.push(node.data);
        });

        let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(rowData);
  
        XLSX.utils.book_append_sheet(wb, ws, "Listado");
       // const max_width = rowData.reduce((w, r) => Math.max(w, r.name.length), 10);
       // ws["!cols"] = [ { wch: max_width } ];
        XLSX.utils.sheet_add_aoa(ws, [["Ind","Num. Guia", "Fecha", "Ult. Estado", "Remitente", "Destinatario", "Municipio", "Empresa", "Estado", "Unidades", "Flete", "Direccion", "Producto" ]], { origin: "A1" });

        const wscols = [
          { wch: 18 },
          { wch: 15 },
          { wch: 15 },
          { wch: 40 },
          { wch: 40 },
          { wch: 20 },
          { wch: 20 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 }
      ];

        ws["!cols"] = wscols;
        
        XLSX.writeFile(wb, "ListadoGuiasExportadas.xlsx")

//        setListaDatos(rowData)

        

     /*  window.open(URL_SERVICIOS + '/impresiones/exportarexcelguias/' + ColumnaFiltro + '/' + ValorFiltro, '_blank').focus();   */
    }

 /*    const onFirstDataRendered = useCallback((params) => {
      gridRef.current.api.forEachNode((node) =>
       console.log(node.data.num_guia)
        node.setSelected(!!node.data && node.data.year === 2012)
      );
    }, []); */


    const onSelectionChanged = (e) => {
      setListaEliminados([]);
      let Listaguias = [];
      const datos = gridRef.current.api.getSelectedRows();
      datos.forEach(guia => {
          Listaguias = [...Listaguias, guia.num_guia];        
      })
      setListaEliminados(Listaguias);

      console.log(Listaguias)
    }

    const proc_eliminarGuias = () => {
        
      return new Promise((resolve, reject) => {     
        ListaEliminados.forEach(numguia => {
        GetData('guias/eliminarguia/'+numguia).then ((result) =>{
              
        })
      })
      resolve();
    })
  
}  

    const EliminarGuias = () => {
        
      MySwal.fire({
        title: 'Borrar Guías',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Si',
        denyButtonText: `No`,
        text: "Esta segúro de borrar las guías: " + ListaEliminados.toString(),
        }).then((result) => {
          if (result.isConfirmed) {

              proc_eliminarGuias().then(() => {
                    setListaEliminados([]);
                    listar_guias();
                    MySwal.fire({
                      title: 'Elimiar ',
                      text: "Guias Eliminadas",
                      icon: 'success'
                      }
                    )
  
              });

          }
        })
  
    }      

    const localeText = useMemo(() => {
      return AG_GRID_LOCALE_ES;
    }, []);

    return (
        <>
          

         <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                  <div className='row '>
                        <div className='col-md-2 mb-2'>
                        <FormControl fullWidth>
                            
                            <TextField id="fecha_inicial" label="Fecha Inicial" 
                                variant="outlined" 
                                size="small" 
                                type="date"            
                                              
                                value={Fecha_Inicial}                   
                                 onChange={event => setFecha_Inicial(event.target.value)}   
                                />
                          
                          </FormControl>
                        </div>  
                        <div className='col-md-2 mb-2'>
                        <FormControl fullWidth>
                            
                            <TextField id="fecha_final" label="Fecha Final" 
                                variant="outlined" 
                                size="small" 
                                type="date"                                                        
                                value={Fecha_Final}  
                                
                                 onChange={event => setFecha_Final(event.target.value)}      
                               /*  onKeyDown={_handleKeyDown} */
                                />
                           
                          </FormControl>
                        </div>  
                        
                        <div className='col-md-2 mb-2'>
                            <Button onClick={listar_guias} color="secondary">Consultar</Button>
                        </div>
                    </div> 
                    <div className='row '>
                 
                  <button type="button" className="btn btn-primary  mb-2 ml-2"
                                            onClick={() => AbrirBusquedaMultiple()}                                           
                                        >Buscar Multiples guias</button>      

                  <button type="button" className="btn btn-success ml-2  mb-2"
                                            
                                            onClick={() => btmExportar()}                                           
                                        >Exportar</button>  

                  <button className="btn btn-success ml-2  mb-2" onClick={clearFilter}>Limpiar Filtros</button>                                        

                  <button type="button" className="btn btn-primary  mb-2 ml-2"
                                             style={{visibility: ListadoGuias !== "" ? 'visible' : 'hidden' }}
                                            onClick={() => listar_guias()}                                           
                                        >Listar Todo</button>                                           

                     </div>

                  <div className="ag-theme-alpine" style={{height: '80vh', width: '100%'}}>
                     
                      <AgGridReact
                          ref={gridRef}
                          localeText={localeText}
                          rowData={ListaGuias}
                          defaultColDef={defaultColDef}
                          rowSelection={'multiple'}
                          
                   /*        isRowSelectable={isRowSelectable}  */
                          onSelectionChanged={onSelectionChanged}
                          onRowSelected
                          columnDefs={columnDefs}
                        /*   onFirstDataRendered={onFirstDataRendered} */
                        >
                      </AgGridReact>
                  </div>
                </div>
             </div>
          </div>
        </div>

        <Dialog open={openMultiple} onClose={handleClose}>
            <DialogTitle>Consultar Multiples Guías</DialogTitle>
            <DialogContent>              
             <TextField
                id="outlined-multiline-flexible"
                label="Listado de Guías"
                className='mt-2'
                multiline
                maxRows={5}
                value={ListadoGuias}
                onChange={event => setListadoGuias(event.target.value)} 
                
                fullWidth
              />

            </DialogContent>
            <DialogActions>
              <Button onClick={() => CerrarLimpiarLista() }>Cancelar</Button>
              <Button onClick={() => LimpiarLista() }>Limpiar</Button>
              <Button onClick={() => ConsultarMultiplesGuias() } disabled={ ListadoGuias !== "" ? false : true } >Consultar</Button>
            </DialogActions>
          </Dialog>
          
            
         </>
    )
}

export default ConsultarGuiaEntreg;