// action - account reducer
export const LOGIN_REQUEST = '@auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@auth/LOGIN_FAILURE';
export const LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST';
export const SET_PERIOD_STATUS = '@auth/SET_PERIOD_STATUS';

export const loginRequest = () => ({
    type: LOGIN_REQUEST
});

export const loginSuccess = (userData) => ({
    type: LOGIN_SUCCESS,
    payload: userData
});

export const logoutRequest = () => ({
    type: LOGOUT_REQUEST
});

export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error
});

export const setPeriodStatus = (status) => ({
    type: SET_PERIOD_STATUS,
    payload: status
});
