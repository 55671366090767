import { Box, TextField, Fab, FormControl, InputLabel, LinearProgress, FormHelperText, Tooltip,  } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Save from '@mui/icons-material/Save';
import { PostData } from '../../services/PostData';
import { Usuario } from '../../services/Usuario';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const GuiaFisica = () => {

    const [Loading, setLoading] = useState(false) 
    const [NumGuias, setNumguias] = useState(0)
    const [ListaGuias, setListaGuias] = useState("")
    const MySwal = withReactContent(Swal)
    const numeral = "#";
    const usuario = Usuario();
    
    useEffect(() => {
        const guiasArray = ListaGuias.split('\n');
        const numGuias = guiasArray.filter(guia => guia.trim() !== '').length;
        setNumguias(numGuias);
    }, [ListaGuias]);
    
    

    const GuardarTodo = () => {

        
       
        const Mensaje = ""
        MySwal.fire({
            title: 'Esta seguro de enviar las guías?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Si',
            denyButtonText: `No`,
            text: Mensaje
            }).then((result) => {
              if (result.isConfirmed) {
                GuardarEstadosGuias();
              }
            })
    }

    const GuardarEstadosGuias = () => {

        setLoading(true);


        if (ListaGuias.length > 0){
            let data = {
                id_usuario : usuario.id_usuario,             
                guias : ListaGuias
            }
            PostData('/guias/verificarfisico', data).then ((result) =>{
                let responseJSON = result;
                if (responseJSON.error === false){                   
                    
                    MySwal.fire({
                        title: 'Verificar guías',
                        text: "Guías verificadas con exito",
                        icon: 'success'
                        });

                        setListaGuias("")      
                        setLoading(false);
                }else{
                    MySwal.fire({
                        title: 'Error al Verificar',
                        text: responseJSON.mensaje,
                        icon: 'error'
                        });
                        setLoading(false);
                }

            });

            

        }else{
          
        }


    }

  return (
    <>
        { Loading && <div className="loading">Loading&#8230;</div> }
        <Tooltip title="Enviar guías a Verificar">
        <Box sx={{ '& > :not(style)': { m: 1 } }} className="btnflotante_s" >
                    <Fab color="primary" aria-label="add" onClick={GuardarTodo} disabled={NumGuias===0} >
                        <Save />
                    </Fab>
                </Box>
        </Tooltip>
     <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                            <h4 className="card-title">VERIFICAR GUÍA FISICA</h4>

                            <div className='form-row'>
                    
                            <div className="form-group col-md-12 col-sm-12">
                                    <div>
                                        <strong>N° Guías:</strong> {NumGuias}
                                    </div>
                                    <FormControl fullWidth>
                                    <FormHelperText>
                                            Pegue aquí el listado de guías que desea marcar como existentes.
                                        </FormHelperText><br />
                                                 <TextField
                                                id="listadoguias"
                                                label="Pegues las guias a Verificar"
                                                multiline
                                                minRows={15} 
                                                value={ListaGuias}
                                                onChange={event => setListaGuias(event.target.value)} 
                                                sx={{ width: '100%', height: '100%' }} 
                                                />
                                            </FormControl>
                                       
                            </div>
                           
                            
                      </div>

                      
                        </div>  
                            

                    
                </div> {/* card */}
              </div> {/* col-md-12 */}
            </div>  {/* row */}
    

    </>
  )
}

export default GuiaFisica